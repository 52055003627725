import {Injectable} from '@angular/core';
import {CurrencyData} from "@kwot/data-models";
import {allowedCurrency, currenciesWithSymbol} from "../../data-models/src";

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  getCurrencyCodeAndSymbolAndAmount(currencyData: CurrencyData, input: number) {
    let currencySymbol = '$';
    let currencyCode = 'USD';
    let amount = Number(Number(input).toFixed(2))
    if (allowedCurrency.includes(currencyData.location.currencyCode) && currencyData.rates.hasOwnProperty(currencyData.location.currencyCode)) {
      currencySymbol = currencyData.location.currencySymbol;
      currencyCode = currencyData.location.currencyCode;
      amount = amount * currencyData.rates[currencyData.location.currencyCode]['value'];
    }
    return {currencySymbol, currencyCode, amount};
  }

  getCurrencyCodeAndSymbolAndAmountWithRates(amount: number, from: string, to: string, fromRate: number, toRate: number) {
    let currency: { symbol: string; name: string } = currenciesWithSymbol.find(t => t.name === to);
    if (from === to) {
      if (!currency) {
        currency = {symbol: '$', name: 'USD'};
      }
      return {currencySymbol: currency.symbol, currencyCode: currency.name, amount};
    }
    amount = Number(Number(amount).toFixed(2));
    // convert from currency to USD
    const fromAmountToUsd = amount / fromRate;
    // Convert USD to to currency
    let convertedAmount: any = fromAmountToUsd * toRate
    if (convertedAmount) {
      convertedAmount = convertedAmount.toFixed(2)
    }
    return {currencySymbol: currency.symbol, currencyCode: currency.name, amount: convertedAmount};
  }

  getCurrencyCodeAndSymbolAndAmountWithConvert(amount: number, from: string, currencyData: CurrencyData) {
    if (from === currencyData.location.currencyCode) {
      return {
        currencySymbol: currencyData.location.currencySymbol,
        currencyCode: currencyData.location.currencyCode,
        amount
      };
    }
    amount = Number(Number(amount).toFixed(2));
    if (!allowedCurrency.includes(currencyData.location.currencyCode)) {
      currencyData = {
        ...currencyData,
        location: {
          ...currencyData.location,
          currencyCode: 'USD',
          currencySymbol: '$'
        }
      }
    }

    // convert from currency to USD
    let defaultRate = 1;
    if (currencyData.rates.hasOwnProperty(from)) {
      defaultRate = currencyData.rates[from]['value'];
    }
    const fromAmountToUsd = amount / defaultRate;

    // Convert USD to to currency
    defaultRate = 1;
    if (currencyData.rates.hasOwnProperty(currencyData.location.currencyCode)) {
      defaultRate = currencyData.rates[currencyData.location.currencyCode]['value'];
    }
    let convertedAmount: any = fromAmountToUsd * defaultRate

    if (convertedAmount) {
      convertedAmount = convertedAmount.toFixed(2)
    }

    return {
      currencySymbol: currencyData.location.currencySymbol,
      currencyCode: currencyData.location.currencyCode,
      amount: convertedAmount
    };
  }

  getCurrencyCodeAndSymbolAndAmountWithRate(currencyData: CurrencyData) {
    let rate = 1;
    let currencyCode = 'USD';
    if (allowedCurrency.includes(currencyData.location.currencyCode)) {
      currencyCode = currencyData.location.currencyCode;
      if (currencyData.rates.hasOwnProperty(currencyData.location.currencyCode)) {
        rate = currencyData.rates[currencyData.location.currencyCode]['value'];
      }
    }
    return {rate, currencyCode};
  }

  getCurrencyCodeAndSymbolAndAmountWithRateFrom(currencyData: CurrencyData, from: string) {
    let rate = 1;
    let currencyCode = 'USD';
    if (allowedCurrency.includes(from)) {
      currencyCode = from;
      if (currencyData.rates.hasOwnProperty(from)) {
        rate = currencyData.rates[from]['value'];
      }
    }
    return {rate, currencyCode};
  }

  getCurrencyCodeAndSymbolAndAmountWithRateFromConverted(currencyData: CurrencyData, from: string, amount: number) {
    let rate = 1;
    let currencyCode = 'USD';
    if (allowedCurrency.includes(from)) {
      currencyCode = from;
      if (currencyData.rates.hasOwnProperty(from)) {
        rate = currencyData.rates[from]['value'];
      }
    }
    amount = Number(Number(amount).toFixed(2)) / rate;
    return {rate, currencyCode, amount};
  }

  getFromCurrencyCodeAndSymbol(input: number, from: string = 'USD') {
    let currency: { symbol: string; name: string } = currenciesWithSymbol.find(t => t.name === from);
    if (!currency) {
      currency = {symbol: '$', name: 'USD'};
    }
    return {currencySymbol: currency.symbol, currencyCode: currency.name, amount: Number(input)};
  }

  getCartItems(cartItems: any) {
    return ((cartItems && cartItems.items) || []).map((t: any) => {
      return {
        ...t.productId,
        proposalId: t.proposalId,
        _id: t.type === 'digital' ? t.giftId._id : t.productId._id,
        quantity: t.quantity,
        type: t.type === 'digital' ? 'digital' : null,
        price: t.type !== 'digital' ? (t.productId.isB2B === true) ? (Number(t.selectedPrice) / Number(t.quantity)) : Number(t.productId.price) : (t.productId.isB2B === true) ? (Number(t.selectedPrice) / Number(t.quantity)) : t.selectedOptions.price,
        totalPrice: t.type !== 'digital' ? (t.productId.isB2B === true) ? Number(t.selectedPrice) : Number(t.productId.price) : (t.productId.isB2B === true) ? (Number(t.selectedPrice) / Number(t.quantity)) : t.selectedOptions.price,
        updatedPrice: t.variantId ? Number(t.variantId.price) : null,
        vendorId: t.type !== 'digital' ? t.productId.vendorId : null,
        title: t.type === 'digital' ? t.selectedOptions.title : '',
        productName: t.type !== 'digital' ? t.productId.productName : '',
        design: t.type === 'digital' ? t.selectedOptions.design : '',
        coverImage: t.type !== 'digital' ? t.productId.coverImage : '',
        categoryId: t.type !== 'digital' ? t.productId.categoryId : '',
        qtyAvailable: t.type !== 'digital' ? t.productId.qtyAvailable: 0,
        ...(t.variantId && t.variantId.qtyAvailable ? {
          qtyAvailable: t.variantId.qtyAvailable
        }: {}),
        platformFee: t.type !== 'digital' ? t.productId.platformFee: 0,
        ...(t.variantId && t.variantId.platformFee ? {
          platformFee: t.variantId.platformFee
        }: {}),
        currency: t.type !== 'digital' ? t.productId.currency : '',
        selectedOptions: t.selectedOptions,
        shippingDetails: t.shippingDetails,
        affiliationId: t.affiliationId,
        sponsoredId: t.sponsoredId,
        variantId: t.variantId ? t.variantId._id: null,
        shippingCost: (t.productId.isB2B === true) ? ((t?.shippingCost || t.proposalId?.shippingCost) || 0) : (t.shippingCost ? t.shippingCost : 0),
        paymentTerms: t.paymentTerms ? t.paymentTerms : null,
        takeCustomMeasurements: t.takeCustomMeasurements,
        rfqPrice: t.selectedPrice,
      };
    })
  }
}
