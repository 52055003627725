import {Inject, Pipe, PipeTransform} from '@angular/core';
import {APP_CONFIG, UtilService} from "@kwot/app-config";
import {CurrencyPipe} from "@angular/common";
import {Observable} from "rxjs";
import {CurrencyData} from "@kwot/data-models";
import {select, Store} from "@ngrx/store";
import {BuyerState, getCurrencyData} from "@kwot/buyer-store";

@Pipe({
  name: 'currencyConvert'
})
export class CurrencyConvertPipe implements PipeTransform {
  defaultConvertParams: any = {
    platformFee: 0,
    onlyPrice: false,
    type: '',
    item: null
  };
  currencyData: CurrencyData = null;

  constructor(
    private currencyPipe: CurrencyPipe,
    @Inject(APP_CONFIG) private appConfig: any,
    private buyerStore: Store<BuyerState>,
    private utilService: UtilService
  ) {
    if (this.appConfig.type === 'user') {
      this.buyerStore.pipe(select(getCurrencyData))
        .subscribe(currencyData => {
          if (currencyData) {
            this.currencyData = currencyData;
            localStorage.removeItem('stopCode');
          }
        })
    }
  }

  transform(input: any, convertParameters: any = {}): Observable<any> {
    convertParameters = {...this.defaultConvertParams, ...convertParameters};
    return new Observable(observable => {
      this.setData(input, convertParameters, observable);
    })
  }

  setData(input: any, convertParameters: any, observable: any) {
    let finalAmount = Number(input);
    if (convertParameters.platformFee > 0) {
      let platformFee = (finalAmount * convertParameters.platformFee) / 100
      finalAmount = finalAmount + platformFee;
    }
    if (this.appConfig.type === 'user') {
      if (this.currencyData) {
        let currencyData = this.getCurrencyData(convertParameters, finalAmount);
        let convertedAmount = currencyData.amount;
        if (!convertParameters.onlyPrice) {
          observable.next(this.currencyPipe.transform(convertedAmount, currencyData.currencyCode, currencyData.currencySymbol));
        } else {
          observable.next(convertedAmount);
        }
      } else {
        setTimeout(() => {
          this.setData(input, convertParameters, observable);
        }, 1000);
      }
    } else {
      let currencyData = this.getCurrencyData(convertParameters, finalAmount);
      let convertedAmount = currencyData.amount;
      if (!convertParameters.onlyPrice) {
        observable.next(this.currencyPipe.transform(convertedAmount, currencyData.currencyCode, currencyData.currencySymbol));
      } else {
        observable.next(finalAmount);
      }
    }
  }

  getCurrencyData(convertParameters: any, finalAmount: any) {
    let currencyData: any = {currencySymbol: '$', currencyCode: 'USD', amount: finalAmount};
    if (this.appConfig.type === 'user') {
      currencyData = this.utilService.getCurrencyCodeAndSymbolAndAmount(this.currencyData, finalAmount);
    }
    if (convertParameters.type) {
      if (convertParameters.type === 'product' || convertParameters.type === 'cart') {
        if (this.appConfig.type === 'user') {
          currencyData = this.utilService.getCurrencyCodeAndSymbolAndAmountWithConvert(finalAmount, convertParameters.item.currency || 'USD', this.currencyData);
        } else {
          currencyData = this.utilService.getFromCurrencyCodeAndSymbol(finalAmount, convertParameters.item.currency || 'USD');
        }
      } else if (convertParameters.type === 'order') {
        currencyData = this.utilService.getCurrencyCodeAndSymbolAndAmountWithRates(finalAmount, convertParameters.item?.currencyData?.from?.currency, convertParameters.item?.currencyData?.to?.currency, convertParameters.item?.currencyData?.from?.rate, convertParameters.item?.currencyData?.to?.rate);
      } else if (convertParameters.type === 'order_original') {
        currencyData = this.utilService.getCurrencyCodeAndSymbolAndAmountWithRates(finalAmount, convertParameters.item?.currencyData?.from?.currency, convertParameters.item?.currencyData?.from?.currency, convertParameters.item?.currencyData?.from?.rate, convertParameters.item?.currencyData?.from?.rate);
      } else if (convertParameters.type === 'order_static') {
        currencyData = this.utilService.getCurrencyCodeAndSymbolAndAmountWithRates(finalAmount, 'USD', convertParameters.item?.currencyData?.to?.currency || 'USD', 1, convertParameters.item?.currencyData?.to?.rate || 1);
      } else if (convertParameters.type === 'wallet') {
        let cData = convertParameters.item?.currencyData || {
          from: {currency: 'USD', rate: 1},
          to: {currency: 'USD', rate: 1}
        };
        currencyData = this.utilService.getCurrencyCodeAndSymbolAndAmountWithRates(finalAmount, cData.from.currency, cData.to.currency, cData.from.rate, cData.to.rate);
        if (this.appConfig.type === 'admin') {
          currencyData = this.utilService.getFromCurrencyCodeAndSymbol(finalAmount, convertParameters.item?.currency);
        }
      } else if (convertParameters.type === 'finance') {
        currencyData = this.utilService.getFromCurrencyCodeAndSymbol(finalAmount, convertParameters.item?.currency);
      }
    }
    return currencyData;
  }
}
