import {EventManager} from '@angular/platform-browser';
import {ElementRef, EventEmitter, Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class WindowService {

  public onResize$ = new EventEmitter<{ width: number; height: number; }>();
  public scrollToElement$ = new EventEmitter<{ element: ElementRef }>();

  constructor(eventManager: EventManager) {
    eventManager.addGlobalEventListener('window', 'resize',
      (e: { target: { innerWidth: any; innerHeight: any; }; }) => this.onResize$.emit({
        width: e.target.innerWidth,
        height: e.target.innerHeight
      }));
  }

  getScreenSizeOnResize() {
    this.onResize$.emit({
      width: window.innerWidth,
      height: window.innerHeight
    });
  }

  getScreenSize() {
    return window.innerWidth;
  }

}
