<div class="modal-body">
  <div class="d-flex align-items-center justify-content-center mb-3">
    <h4 class="fw-bold fs-18 mb-0" *ngIf="title">{{title}}</h4>
    <span id="close">
    <img class="img-fluid close-icon cursor-pointer d-md-block d-none" src="/assets/images/icons/Cross.svg" alt=""
         (click)="bsModalRef.hide()">
  </span>
  </div>
  <div class="action--form">
    <div class="form-details {{customClass}}">
      <img class="img-fluid confirm-image" *ngIf="showImage" [src]="image">
      <h2 class="header-title text-center fw-bold" *ngIf="headerTitle">{{headerTitle}}</h2>
      <h6 class="fw-medium subtitle text-center mb-4" *ngIf="headerSubTitle">{{headerSubTitle}}</h6>
      <h6 class="fw-medium subtitle text-center mb-4" *ngIf="headerNote">{{headerNote}}</h6>
      <ng-container *ngIf="products">
        <div class="row">
          <div class="col-xl-4 col-sm-6 mb-3" *ngFor="let product of products">
            <div class="product-details position-relative">
              <img
                [lazyLoad]="product.type === 'physical' ? product.productId.coverImage: product.selectedOptions.design"
                class="img-fluid"
                [style]="{width: '100%', height: '200px'}"
                defaultImage="assets/images/loaders/skeleton_loader.gif" alt=""/>
              <div class="details mt-3 text-start">
                <div class="price-details">
                  <p class="fs-18 mb-0">{{product.type === 'physical' ? product.productId.productName: product.selectedOptions.title}}</p>
                  <kwot-currency [price]="product.type === 'physical' ? product.productId: product.selectedOptions" tag="p" class="fs-18 fw-bold mb-0" type="product"
                                 field="price"
                                 [platformFee]="false"></kwot-currency>
                </div>
                <div class="price-details" *ngIf="product.type === 'physical'">
                  <p class="fs-18 mb-0">{{product.productId.weight}} {{ 'kg' | translate }}</p>
                  <p class="fs-18 fw-bold mb-0">
                    {{ product?.productId?.dimension?.length }} {{ 'l' | translate }} x {{ product?.productId?.dimension?.width }} {{ 'w' | translate }}
                    x {{ product?.productId?.dimension?.height }} {{ 'h' | translate }} ({{product?.productId?.dimension?.metrics}})
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="vendorDetail">
        <div class="vendor-currency">
          <div class="d-flex">
            <i class="fa-solid fa-xmark" *ngIf="vendorDetail?.walletWithdraw"></i>
            <i class="fa-sharp fa-solid fa-square-check" *ngIf="!vendorDetail?.walletWithdraw"></i>
            <p>
              1. {{vendorDetail?.walletWithdraw ? ('withdraw_all_money_wallet' | translate) : ('your_wallet_looks_good' | translate)}}</p>
          </div>
          <div class="d-flex">
            <i class="fa-solid fa-xmark" *ngIf="vendorDetail?.sellerOrder"></i>
            <i class="fa-sharp fa-solid fa-square-check" *ngIf="!vendorDetail?.sellerOrder"></i>
            <p>
              2. {{vendorDetail?.sellerOrder ? ('wait_your_orders_completed' | translate) : ('all_orders_are_good' | translate)}}</p>
          </div>
          <div class="d-flex">
            <i class="fa-solid fa-xmark" *ngIf="vendorDetail?.payoutPending"></i>
            <i class="fa-sharp fa-solid fa-square-check" *ngIf="!vendorDetail?.payoutPending"></i>
            <p>
              3. {{vendorDetail?.payoutPending ? ('wait_payouts_completed' | translate) : ('your_payouts_looks_good' | translate)}}</p>
          </div>
        </div>
        <div class="d-flex items-center justify-content-center">
          <div *ngIf="!vendorDetail?.walletWithdraw && !vendorDetail?.sellerOrder && !vendorDetail?.payoutPending">
            <button class="btn-confirm me-3" (click)="submitForm()">{{confirmText}}</button>
          </div>
          <button class="btn-cancel"
                  (click)="!emitOnClose ? bsModalRef.hide() : submitForm(false)">{{closeText}}</button>
        </div>
      </ng-container>
      <ng-container *ngIf="showButtons">
        <div class="d-flex items-center justify-content-center">
          <button class="btn-confirm me-3" (click)="submitForm()">{{confirmText}}</button>
          <button class="btn-cancel"
                  (click)="!emitOnClose ? bsModalRef.hide() : submitForm(false)">{{closeText}}</button>
        </div>
      </ng-container>
    </div>
  </div>
</div>
