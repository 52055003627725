import {APP_INITIALIZER, Injector, NgModule} from '@angular/core';
import {CommonModule, CurrencyPipe, LOCATION_INITIALIZED} from '@angular/common';
import {
  MissingTranslationHandler, MissingTranslationHandlerParams,
  TranslateLoader,
  TranslateModule,
  TranslateService,
  TranslateStore
} from "@ngx-translate/core";
import {HttpClient} from "@angular/common/http";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {SlugifyPipe} from './pipes/slugify.pipe';
import {LazyLoadImageModule} from "ng-lazyload-image";
import {CustomModalComponent} from './components/custom-modal/custom-modal.component';
import {ColorPickerDirective} from "./directives/colorPicker.directive";
import {NumberOnlyDirective} from "./directives/numberOnly.directive";
import {NoDecimalDirective} from "./directives/noDecimal.directive";
import {PascalCasePipe} from "./pipes/pascalCase.pipe";
import {MapForSelectPipe} from "./pipes/mapForSelect.pipe";
import {DateFormatPipe} from "./pipes/date-format.pipe";
import {ReplacePipe} from "./pipes/replace.pipe";
import {InputRefDirective} from "./directives/inputRef.directive";
import {CurrencyConvertPipe} from "./pipes/currencyConvert.pipe";
import {FormTouchedDirective} from "./directives/formTouched.directive";
import {ToastComponent} from "@kwot/app-config";
import {ToastrModule} from "ngx-toastr";
import {NativeElementInjectorDirective} from "./directives/nativeElement.directive";
import {ChatComponent} from "./components/chat/chat.component";
import {CurrencyComponent} from "./components/currency/currency.component";
import {MinuteSecondsPipe} from "./pipes/minuteSeconds.pipe";
import {ProductPriceComponent} from "./components/product-price/product-price.component";
import {LoginModalComponent} from "./components/login-modal/login-modal.component";
import {RouterModule} from "@angular/router";
import {RfqModalComponent} from "./components/rfq-modal/rfq-modal.component";
import {SelectModule} from "@kwot/select";
import {ProductReviewsComponent} from "./components/product-reviews/product-reviews.component";
import {AddProductReviewComponent} from "./components/add-product-review/add-product-review.component";
import {SelectedVariationComponent} from "./components/selected-variation/selected-variation.component";
import {InfiniteScrollModule} from "ngx-infinite-scroll";
import {BsModalService} from "ngx-bootstrap/modal";

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function appInitializerFactory(translateService: TranslateService, injector: Injector) {
  return async () => {
    await injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
    translateService.addLangs(['en', 'fr']);
    translateService.setDefaultLang('en');
    try {
      await translateService.use('en').toPromise();
    } catch (err) {
      console.log(err);
    }
  };
}

export class MyMissingTranslationHandler implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams) {
    return `**MISSING** ${params.key}`;
  }
}

@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: MyMissingTranslationHandler,
      }
    }),
    LazyLoadImageModule,
    ToastrModule.forRoot({
      closeButton: true,
      maxOpened: 2,
      autoDismiss: true,
      preventDuplicates: true,
      toastComponent: ToastComponent,
      toastClass: 'ngx-toastr custom-toast',
    }),
    SelectModule,
    InfiniteScrollModule
  ],
  declarations: [
    ToastComponent,
    SlugifyPipe,
    CustomModalComponent,
    ChatComponent,
    CurrencyComponent,
    ColorPickerDirective,
    NumberOnlyDirective,
    NoDecimalDirective,
    PascalCasePipe,
    MapForSelectPipe,
    DateFormatPipe,
    ReplacePipe,
    InputRefDirective,
    CurrencyConvertPipe,
    FormTouchedDirective,
    NativeElementInjectorDirective,
    MinuteSecondsPipe, ProductPriceComponent,
    LoginModalComponent,
    RfqModalComponent,
    ProductReviewsComponent,
    AddProductReviewComponent,
    SelectedVariationComponent
  ],
  exports: [
    TranslateModule,
    SlugifyPipe,
    LazyLoadImageModule,
    CustomModalComponent,
    ColorPickerDirective,
    NumberOnlyDirective,
    NoDecimalDirective,
    PascalCasePipe,
    MapForSelectPipe,
    DateFormatPipe,
    ReplacePipe,
    InputRefDirective,
    CurrencyConvertPipe,
    FormTouchedDirective,
    NativeElementInjectorDirective,
    ChatComponent,
    CurrencyComponent,
    MinuteSecondsPipe, ProductPriceComponent,
    LoginModalComponent,
    RfqModalComponent,
    MinuteSecondsPipe,
    ProductReviewsComponent,
    SelectedVariationComponent
  ],
  providers: [
    TranslateStore,
    {
      provide: APP_INITIALIZER,
      deps: [TranslateService, Injector],
      multi: true,
      useFactory: appInitializerFactory,
    },
    CurrencyPipe,
    CurrencyConvertPipe,
    BsModalService
  ]
})

export class SharedModule {
  constructor(translateService: TranslateService) {
    translateService.addLangs(['en', 'fr']);
    translateService.setDefaultLang(localStorage.getItem('language') || 'en');
  }
}
