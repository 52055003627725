import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {PascalCasePipe} from "../../pipes/pascalCase.pipe";

@Component({
  selector: 'kwot-selected-variation',
  templateUrl: './selected-variation.component.html',
  styleUrls: ['./selected-variation.component.scss'],
  providers: [PascalCasePipe]
})
export class SelectedVariationComponent implements OnInit, OnChanges {

  @Input() product?: any;
  variations: any[] = [];
  fields = [
    {name: 'primaryColour', value: 'Primary Colour'},
    {name: 'primaryMaterial', value: 'Primary Material'},
    {name: 'secondaryColour', value: 'Secondary Colour'},
    {name: 'secondaryMaterial', value: 'Secondary Material'}
  ];

  constructor(
    private pascalCase: PascalCasePipe
  ) {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.product && this.product?.selectedOptions) {
      let variationsList = Object.keys(this.product.categoryId || {});
      let fields = ['pattern', 'primaryColour', 'primaryMaterial', 'secondaryColour', 'secondaryMaterial', 'size', 'weight', 'dimension'];
      variationsList = variationsList.filter((key: string) => fields.includes(key));
      variationsList.forEach((t: any) => {
        if (this.product.categoryId[t] && this.product.categoryId[t].isSelected && this.product.hasOwnProperty(t)) {
          let values;
          if (t === 'primaryColour' || t === 'primaryMaterial' || t === 'secondaryColour' || t === 'secondaryMaterial') {
            if (this.product?.selectedOptions[t]) {
              let d = this.product[t].find((d: any) => d._id === this.product?.selectedOptions[t]);
              values = {name: d.name, ...((t === 'primaryColour' || t === 'secondaryColour') ? {hex: d.hex} : {image: d.image})};
            }
          } else {
            if (Array.isArray(this.product[t])) {
              values = {name: this.product[t].find((d: any) => d === (this.product?.selectedOptions[t] || null))}
            } else {
              if (t === 'weight') {
                values = {name: this.product?.selectedOptions[t] || this.product.weight}
              } else if (t === 'dimension') {
                values = {name: this.product?.selectedOptions[t] || this.product.dimension}
              } else {
                values = {name: this.product?.selectedOptions[t] || null}
              }
            }
          }
          if (values) {
            this.variations.push({
              name: this.pascalCase.transform(this.fields.find((d: any) => d.name === t)?.value || t),
              value: values
            })
          }
        }
      })

      this.product?.categoryId.dynamicKey.forEach((dyk: any) => {
        if (dyk.isSelected && dyk.isVariant && dyk.key) {
          if (this.product.selectedOptions[dyk.key]) {
            this.variations.push({
              name: this.pascalCase.transform(dyk.key),
              value: {name: this.product.selectedOptions[dyk.key]}
            })
          }
        }
      })
    }
  }
}
