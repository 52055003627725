export const measurementsList: any[] = [
  {
    name: 'Across Shoulders',
    id: 'across_shoulders'
  },
  {
    name: 'Arm Length',
    id: 'arm_length'
  },
  {
    name: 'Arm Length Suit',
    id: 'arm_length_suit'
  },
  {
    name: 'Arm Length Calculated',
    id: 'arm_length_calculated'
  },
  {
    name: 'Back Width',
    id: 'back_width'
  },
  {
    name: 'Biceps',
    id: 'biceps_circ'
  },
  {
    name: 'Body Length',
    id: 'body_length'
  },
  {
    name: 'Chest Circumference',
    id: 'chest_circ'
  },
  {
    name: 'Front rise',
    id: 'front_rise'
  },
  {
    name: 'Height',
    id: 'height'
  },
  {
    name: 'Hips',
    id: 'hips_circ'
  },
  {
    name: 'Waist Circumference',
    id: 'waist_circ'
  },
  {
    name: 'Inseam Length',
    id: 'inseam_length'
  },
  {
    name: 'Inseam Length to Ankle',
    id: 'inseam_length_ankle'
  },
  {
    name: 'Knee Circumference',
    id: 'knee_circ'
  },
  {
    name: 'Low Waist Circumference pants',
    id: 'low_waist_circ_pants'
  },
  {
    name: 'Neck Circumference',
    id: 'neck_circ'
  },
  {
    name: 'Outseam Length',
    id: 'outseam_length'
  },
  {
    name: 'Thigh Circumference',
    id: 'thigh_circ'
  },
  {
    name: 'Under Bust',
    id: 'under_bust'
  },
  {
    name: 'Top Bust',
    id: 'top_bust'
  },
  {
    name: 'Wrist',
    id: 'wrist'
  },
//across_shoulders: 46.7
// arm_length: 56.5
// arm_length_calculated: 79.9
// arm_length_suit: 62
// back_width: 43.2
// biceps_circ: 38.3
// body_length: 75.6
// chest_circ: 117.8
// front_rise: 22.9
// height: 172
// hips_circ: 113.5
// inseam: 72.4
// inseam_length: 72.4
// inseam_length_ankle: 66.4
// knee_circ: 43.8
// low_waist_circ_pants: 107.4
// low_waist_pants_circ: 107.4
// low_waist_pants_circ_men: 107.4
// neck_circ: 39.1
// outseam_length: 95.3
// thigh_circ: 70.3
// waist_circ: 116.3
];
