import {
  Store,
  Categories,
  PaginatedProducts,
  PaginatedSellers,
  User,
  CurrencyData,
  Product,
  GiftCard, WalletDetails, PayoutDetails, Negotiation
} from '@kwot/data-models';
import {Action, createReducer, on} from '@ngrx/store';
import {LocalstorageService} from '@kwot/app-config';
import {
  GetBuyerDetails,
  GetProductByIdSuccess,
  GetCategoriesSuccess,
  GetHomeProductsSuccess,
  GetAddressByIdSuccess,
  GetCategoriesError,
  GetCategoryFilterSuccess,
  RemoveProductFavouriteSuccess,
  GetVendorDetailSuccess,
  ResetBuyerState,
  GetRecentlyViewProduct,
  CreateWishListSuccess,
  DeleteWishListSuccess,
  GetWishListSuccess,
  AddProductWishListSuccess,
  GetNotifications,
  GetNotificationsSuccess,
  GetNotificationsError,
  ReadNotification,
  ReadNotificationSuccess,
  ReadNotificationError,
  GetProductReviews,
  GetProductReviewsSuccess,
  GetProductReviewsError,
  PostProductReview,
  PostProductReviewSuccess,
  PostProductReviewError,
  RemoveProductWishListSuccess,
  GetProducts,
  GetProductsSuccess,
  GetProductsError,
  CheckGuestOrUpdate,
  CheckGuestOrUpdateSuccess,
  CheckGuestOrUpdateError,
  GetSellersList,
  GetSellersListSuccess,
  GetSellersListError,
  GuestBuyerInfo,
  GuestBuyerInfoSuccess,
  GuestBuyerInfoError,
  CancelOrder,
  CancelOrderError,
  CancelOrderSuccess,
  GetPolicyDetail,
  GetPolicyDetailError,
  GetPolicyDetailSuccess,
  AcceptPolicyDetail,
  AcceptPolicyDetailSuccess,
  AcceptPolicyDetailError,
  ContactNow,
  ContactNowSuccess,
  ContactNowError,
  GetCategoriesResponseSuccess,
  GetBannerImagesSuccess,
  ResetPaymentState,
  AddOrderPaymentDetails,
  AddOrderPaymentDetailsSuccess,
  AddOrderPaymentDetailsError,
  UpdateAddressSuccess,
  UpdateAddressError,
  DeleteAddressSuccess,
  AddAddresSuccess,
  AddAddresError,
  UpdateProfileInfo,
  UpdateProfileInfoError,
  UpdateProfileInfoSuccess,
  ProfileInfo,
  ProfileInfoError,
  ProfileInfoSuccess,
  GetAllAddressSuccess,
  GetCurrencyData,
  GetCurrencyDataError,
  GetCurrencyDataSuccess,
  GetWishlistProducts,
  GetWishlistProductsSuccess,
  GetWishlistProductsError,
  GetAllGiftCards,
  GetAllGiftCardsSuccess,
  GetAllGiftCardsError,
  CheckGiftCardValidity,
  CheckGiftCardValiditySuccess,
  CheckGiftCardValidityError,
  ValidateGiftPromoCode,
  ValidateGiftPromoCodeSuccess,
  ValidateGiftPromoCodeError,
  CheckAssociateStoreIdAvailability,
  CheckAssociateStoreIdAvailabilitySuccess,
  CheckAssociateStoreIdAvailabilityError,
  AddAffiliateSuccess,
  GetAffiliate,
  GetAffiliatesSuccess,
  GetAffiliateError,
  AddAffiliateError,
  AddAffiliate,
  getAffiliateDashboard,
  getAffiliateDashboardSuccess,
  getAffiliateDashboardError,
  AddAffiliateProduct,
  AddAffiliateProductSuccess,
  AddAffiliateProductError,
  GetAffiliateProducts,
  GetAffiliateProductsSuccess,
  GetAffiliateProductsError,
  EditAffiliate,
  EditAffiliateSuccess,
  EditAffiliateError,
  GetAffiliateWalletBalance,
  GetAffiliateWalletBalanceSuccess,
  GetAffiliateWalletBalanceError,
  GetAffiliateWalletTransaction,
  GetAffiliateWalletTransactionSuccess,
  GetAffiliateWalletTransactionError,
  AddPayoutData,
  AddPayoutDataError,
  AddPayoutDataSuccess,
  AddScheduleData,
  AddScheduleDataError,
  AddScheduleDataSuccess,
  GetPayoutDetail,
  GetPayoutDetailError,
  GetPayoutDetailSuccess,
  SearchAffiliateProducts,
  SearchAffiliateProductsSuccess,
  SearchAffiliateProductsError,
  GetSponsoredItems,
  AddToCartItems,
  AddToCartItemsSuccess,
  AddToCartItemsError,
  GetCart,
  GetCartSuccess,
  GetCartError,
  ValidateOrderSuccess,
  ValidateOrderError,
  GetSponsoredItemsSuccess,
  GetSponsoredItemsError,
  EditWishListSuccess,
  GetSearchProductsSuccess,
  UpdateSellerFavorite,
  StartNegotiation,
  StartNegotiationSuccess,
  StartNegotiationError,
  GetRfqDynamicKey,
  GetRfqDynamicKeySuccess,
  GetRfqDynamicKeyError,
  GetTempOrder,
  GetTempOrderSuccess,
  GetTempOrderError,
  AddTempOrder,
  AddTempOrderSuccess,
  AddTempOrderError,
  GetIntent,
  GetIntentSuccess,
  GetIntentError,
  CheckChatUser,
  CheckChatUserSuccess,
  CheckChatUserError,
  GetActivePromoCodes,
  GetActivePromoCodesSuccess, GetActivePromoCodesError, ContactToSeller, ContactToSellerSuccess, ContactToSellerError
} from './buyer.actions';

export interface BuyerState {
  success: string;
  error: string;
  buyer: Store;
  categories: Categories[];
  product: any;
  cart: any;
  homePageProduct: any;
  categoryResponse: any;
  bannerImage: any;
  address: any;
  categoryFilter: any;
  favourite: any;
  products: any;
  vendorDetail: any
  recentlyView: any;
  wishList: any;
  notifications: any;
  reviews: any;
  paginatedProducts: PaginatedProducts;
  guestUser: any;
  chatUser: any;
  vendors: PaginatedSellers;
  guestInfo: any;
  cancelOrder: any;
  policy: any,
  orderPlaced: any
  paymentSuccess: any
  userData: User | Store | null;
  currencyData: CurrencyData;
  updatedProducts: Product[];
  giftCards: GiftCard[];
  giftCardValidity: any;
  discountDetails: any;
  isAssociateStoreIdAvailable: string;
  affiliate: any;
  affiliateDashboard: any;
  affiliateProducts: any;
  wallet: WalletDetails | null,
  walletTransaction: any,
  payout: PayoutDetails[],
  searchAffiliateProducts: any[],
  sponsored: any[],
  searchData: any;
  sellerFavourite: any[],
  negotiation: Negotiation[],
  rfqDynamicField: any[]
  tempOrder: any,
  paymentIntent: any,
  promoCodes: any[]
}

const localStorage = new LocalstorageService();

const initBuyerState: BuyerState = {
  error: '',
  success: '',
  buyer: null,
  categories: [],
  product: null,
  cart: null,
  homePageProduct: null,
  categoryResponse: null,
  bannerImage: null,
  address: [],
  categoryFilter: null,
  products: [],
  favourite: [],
  vendorDetail: [],
  recentlyView: localStorage.updateRecentlyViewProduct('get') || [],
  wishList: null,
  notifications: null,
  reviews: null,
  paginatedProducts: null,
  guestUser: localStorage.updateGuestUserKey('get') || null,
  chatUser: localStorage.updateUserKey('get') || localStorage.updateGuestUserKey('get') || localStorage.updateChatUserKey('get') || null,
  vendors: null,
  guestInfo: null,
  cancelOrder: null,
  policy: null,
  orderPlaced: null,
  paymentSuccess: null,
  userData: null,
  currencyData: localStorage.updateSelectedCurrency('get') || null,
  updatedProducts: [],
  giftCards: [],
  giftCardValidity: null,
  discountDetails: null,
  isAssociateStoreIdAvailable: '',
  affiliate: null,
  affiliateDashboard: null,
  affiliateProducts: null,
  wallet: null, walletTransaction: null,
  payout: [],
  searchAffiliateProducts: [],
  sponsored: [],
  searchData: null,
  sellerFavourite: [],
  negotiation: null,
  tempOrder: null,
  paymentIntent: null,
  rfqDynamicField: [],
  promoCodes: []
};

const buyerReducer = createReducer(
  initBuyerState,
  on(GetBuyerDetails, (state) => ({
    ...state,
    buyer: null,
    error: '',
    success: '',
  })),

  on(GetCategoriesSuccess, (state, payload) => ({
    ...state,
    buyer: null,
    error: '',
    success: '',
    categories: payload.categories,
  })),

  on(GetCategoriesError, (state, payload) => {
    return {
      ...state,
      buyer: null,
      success: '',
      error: payload.error,
    };
  }),

  on(GetProductByIdSuccess, (state, payload) => ({
    ...state,
    buyer: null,
    error: '',
    success: '',
    product: payload.product,
  })),

  on(GetHomeProductsSuccess, (state, payload) => ({
    ...state,
    homePageProduct: payload.products,
    error: '',
    success: '',
  })),

  on(GetCategoriesResponseSuccess, (state, payload) => ({
    ...state,
    categoryResponse: payload.categoryResponse,
    error: '',
    success: '',
  })),

  on(GetBannerImagesSuccess, (state, payload) => ({
    ...state,
    bannerImage: payload.bannerImage,
    error: '',
    success: '',
  })),

  on(GetAddressByIdSuccess, (state, payload) => {
    return {
      ...state,
      address: [payload.address],
      error: '',
      success: '',
    };
  }),
  on(GetCategoryFilterSuccess, (state, {categoryFilter}) => {
    return {
      ...state,
      categoryFilter,
      error: '',
      success: '',
    };
  }),

  on(RemoveProductFavouriteSuccess, (state, payload) => {
    let favouriteList = state.favourite.filter(
      (ele: any) => ele.productId._id !== payload.removeItem
    );
    return {
      ...state,
      favourite: favouriteList,
      error: '',
      success: '',
    };
  }),

  on(GetVendorDetailSuccess, (state, payload) => {
    return {
      ...state,
      vendorDetail: payload.vendorDetail,
      error: '',
      success: '',
    };
  }),

  on(ResetBuyerState, (state, {params}) => {
    return {
      ...state,
      ...params
    };
  }),

  on(GetRecentlyViewProduct, (state, payload) => {
    const isUnique = state.recentlyView.some(
      (ele: any) => ele.id === payload.product.id
    );
    let newProduct: any[] = state.recentlyView;
    if (!isUnique) {
      newProduct = [...newProduct, payload.product];
      localStorage.updateRecentlyViewProduct('store', newProduct);
    }
    return {
      ...state,
      recentlyView: newProduct,
      error: '',
      success: '',
    };
  }),

  on(CreateWishListSuccess, (state, payload) => {
    return {
      ...state,
      wishList: [...state.wishList, payload.wishList],
      success: '',
    };
  }),

  on(EditWishListSuccess, (state, payload) => {
    return {
      ...state,
      success: '---IGNORE---',
    };
  }),

  on(GetWishListSuccess, (state, payload) => {
    return {
      ...state,
      wishList: payload.wishList,
      success: '',
    };
  }),

  on(DeleteWishListSuccess, (state, payload) => {
    return {
      ...state,
      success: '---IGNORE---',
    };
  }),

  on(GetNotifications, (state) => {
    return {
      ...state,
      notifications: null,
      success: '',
      error: '',
    };
  }),

  on(GetNotificationsSuccess, (state, {notifications}) => {
    return {
      ...state,
      notifications,
      success: '',
      error: '',
    };
  }),

  on(GetNotificationsError, (state, {error}) => {
    return {
      ...state,
      notifications: null,
      success: '',
      error,
    };
  }),

  on(ReadNotification, (state) => {
    return {
      ...state,
      success: '',
      error: '',
    };
  }),

  on(ReadNotificationSuccess, (state) => {
    return {
      ...state,
      success: '---IGNORE---',
      error: '',
    };
  }),

  on(ReadNotificationError, (state, {error}) => {
    return {
      ...state,
      success: '',
      error,
    };
  }),

  on(GetProductReviews, (state) => {
    return {
      ...state,
      reviews: null,
      success: '',
      error: '',
    };
  }),

  on(GetProductReviewsSuccess, (state, {reviews}) => {
    return {
      ...state,
      reviews,
      success: '',
      error: '',
    };
  }),

  on(GetProductReviewsError, (state, {error}) => {
    return {
      ...state,
      reviews: null,
      success: '',
      error,
    };
  }),

  on(PostProductReview, (state) => {
    return {
      ...state,
      success: '',
      error: '',
    };
  }),

  on(PostProductReviewSuccess, (state) => {
    return {
      ...state,
      success: '---IGNORE---',
      error: '',
    };
  }),

  on(PostProductReviewError, (state, {error}) => {
    return {
      ...state,
      success: '',
      error,
    };
  }),

  on(GetAllAddressSuccess, (state, payload) => {
    return {
      ...state,
      address: payload.address,
      error: '',
      success: '',
    };
  }),

  on(GetProducts, (state) => {
    return {
      ...state,
      paginatedProducts: null,
      success: '',
      error: '',
    };
  }),

  on(GetProductsSuccess, (state, {paginatedProducts}) => {
    return {
      ...state,
      paginatedProducts,
      success: '',
      error: '',
    };
  }),

  on(GetProductsError, (state, {error}) => {
    return {
      ...state,
      paginatedProducts: null,
      success: '',
      error,
    };
  }),

  on(RemoveProductWishListSuccess, (state, {success}) => {
    let wishlist = [...state.wishList];
    let updatedWishlist = wishlist.findIndex(t => t._id === success._id);
    if (updatedWishlist !== -1) {
      wishlist[updatedWishlist] = {
        ...wishlist[updatedWishlist],
        ...success
      }
    } else {
      wishlist.push(success);
    }
    return {
      ...state,
      wishList: wishlist,
      success: '',
      error: '',
    };
  }),

  on(AddProductWishListSuccess, (state, {success}) => {
    let wishlist = [...state.wishList];
    let updatedWishlist = wishlist.findIndex(t => t._id === success._id);
    if (updatedWishlist !== -1) {
      wishlist[updatedWishlist] = {
        ...wishlist[updatedWishlist],
        ...success
      }
    } else {
      wishlist.push(success);
    }
    return {
      ...state,
      wishList: wishlist,
      success: '',
      error: '',
    };
  }),

  on(CheckGuestOrUpdate, (state) => {
    return {
      ...state,
      error: '',
      success: ''
    };
  }),
  on(CheckGuestOrUpdateSuccess, (state, {guestUser}) => {
    return {
      ...state,
      guestUser,
      error: '',
      success: ''
    };
  }),
  on(CheckGuestOrUpdateError, (state, {error}) => {
    return {
      ...state,
      error,
      success: ''
    };
  }),

  on(CheckChatUser, (state) => {
    return {
      ...state,
      error: '',
      success: ''
    };
  }),
  on(CheckChatUserSuccess, (state, {chatUser}) => {
    return {
      ...state,
      chatUser,
      error: '',
      success: ''
    };
  }),
  on(CheckChatUserError, (state, {error}) => {
    return {
      ...state,
      error,
      success: ''
    };
  }),

  on(GuestBuyerInfo, (state) => {
    return {
      ...state,
      guestInfo: null,
      error: '',
      success: ''
    };
  }),
  on(GuestBuyerInfoSuccess, (state, {guestInfo}) => {
    return {
      ...state,
      guestInfo,
      error: '',
      success: ''
    };
  }),
  on(GuestBuyerInfoError, (state, {error}) => {
    return {
      ...state,
      guestInfo: null,
      error,
      success: ''
    };
  }),

  on(CancelOrder, (state) => {
    return {
      ...state,
      error: '',
      success: ''
    };
  }),

  on(CancelOrderSuccess, (state, {cancelOrder}) => {
    return {
      ...state,
      cancelOrder,
      error: '',
      success: ''
    };
  }),
  on(CancelOrderError, (state, {error}) => {
    return {
      ...state,
      error,
      success: ''
    };
  }),

  on(GetSellersList, (state) => {
    return {...state, error: '', success: ''}
  }),
  on(GetSellersListSuccess, (state, {vendors}) => {
    return {...state, vendors, error: '', success: ''}
  }),
  on(GetSellersListError, (state, {error}) => {
    return {...state, error, success: ''}
  }),

  on(GetPolicyDetail, (state) => ({
    ...state,
    policy: null,
    error: '',
    success: ''
  })),
  on(GetPolicyDetailSuccess, (state, {policy}) => ({
    ...state,
    policy,
    error: '',
    success: ''
  })),
  on(GetPolicyDetailError, (state, {error}) => ({
    ...state,
    error,
    success: ''
  })),

  on(AcceptPolicyDetail, (state) => ({
    ...state,
    policy: null,
    error: '',
    success: ''
  })),
  on(AcceptPolicyDetailSuccess, (state) => ({
    ...state,
    error: '',
    success: ''
  })),
  on(AcceptPolicyDetailError, (state, {error}) => ({
    ...state,
    error,
    success: ''
  })),

  on(ContactNow, (state) => ({
    ...state,
    error: '',
    success: ''
  })),
  on(ContactNowSuccess, (state) => ({
    ...state,
    error: '',
    success: '---IGNORE---'
  })),
  on(ContactNowError, (state, {error}) => ({
    ...state,
    error,
    success: ''
  })),
  on(AddAddresSuccess, (state, payload) => {
    let address = state.address.filter((item: any) => item._id === payload.address.id);
    if (address && address.length > 0) {
      address = [...(state.address ? state.address : [])]
    } else {
      address = [...(state.address ? state.address : []), payload.address]
    }
    return {
      ...state,
      address: address,
      error: '',
      success: 'address added',
    };
  }),

  on(AddAddresError, (state, {error}) => {
    return {
      ...state,
      address: null,
      error,
      success: '',
    };
  }),

  on(UpdateAddressSuccess, (state, payload) => {
    let address, addresses;
    let addressExist = state.address.filter((item: any) => item._id === payload.address.id);
    if (addressExist && addressExist.length > 0) {
      addresses = state.address.map((item: any) => ({
        ...item,
        ...(item._id === payload.address.id ? {isExist: true} : {isExist: false})
      }));
      address = [...addresses]
    } else {
      addresses = state.address.filter((item: any) => item._id !== payload.address.id);
      address = [...addresses, payload.address]
    }
    return {
      ...state,
      address: address,
      error: '',
      success: 'address update',
    };
  }),

  on(UpdateAddressError, (state, {error}) => {
    return {
      ...state,
     address: null,
      error,
      success: '',
    };
  }),

  on(DeleteAddressSuccess, (state, payload) => {
    let address = state.address.filter((item: any) => item._id !== payload.id);
    return {
      ...state,
      address: [...address],
      error: '',
      success: '',
    };
  }),

  on(UpdateProfileInfo, (state) => {
    return {
      ...state,
      error: '',
      success: ''
    };
  }),
  on(UpdateProfileInfoSuccess, (state, {profile}) => {
    return {
      ...state,
      profile,
      error: '',
      success: ''
    };
  }),
  on(UpdateProfileInfoError, (state, {error}) => {
    return {
      ...state,
      error,
      success: ''
    };
  }),

  on(ProfileInfo, (state) => ({...state, userData: null, error: '', success: ''})),
  on(ProfileInfoSuccess, (state, {userData}) => ({...state, userData, error: '', success: ''})),
  on(ProfileInfoError, (state, {error}) => ({...state, userData: null, error, success: ''})),

  on(ResetPaymentState, (state, {params}) => {
    return {
      ...state,
      ...params
    };
  }),

  on(ValidateOrderSuccess, (state, {orderPlaced}) => {
    return {
      ...state,
      error: '',
      success: '',
      orderPlaced,
      ...(orderPlaced.hasOwnProperty('price') ? {
        ...(orderPlaced.price <= 0 ? {cart: null} : {})
      } : {})
    };
  }),

  on(ValidateOrderError, (state, payload) => {
    return {
      ...state,
      error: payload.error,
      success: '',
      orderPlaced: null
    };
  }),

  on(AddOrderPaymentDetails, (state) => {
    return {
      ...state,
      error: '',
      success: '',
      paymentSuccess: null
    };
  }),

  on(AddOrderPaymentDetailsSuccess, (state, {paymentSuccess}) => {
    return {
      ...state,
      paymentSuccess,
      cart: null,
      success: '',
      error: ''
    };
  }),

  on(AddOrderPaymentDetailsError, (state, {error}) => {
    return {
      ...state,
      error,
      success: '',
      paymentSuccess: null
    };
  }),
  on(GetCurrencyData, (state) => ({...state, error: '', success: ''})),
  on(GetCurrencyDataSuccess, (state, {currencyData}) => ({...state, error: '', success: '---IGNORE---', currencyData})),
  on(GetCurrencyDataError, (state, {error}) => ({...state, error, success: ''})),
  on(GetWishlistProducts, (state) => ({...state, updatedProducts: [], error: '', success: ''})),
  on(GetWishlistProductsSuccess, (state, {updatedProducts}) => ({...state, error: '', success: '', updatedProducts})),
  on(GetWishlistProductsError, (state, {error}) => ({...state, updatedProducts: [], error, success: ''})),
  on(GetAllGiftCards, (state) => ({...state, giftCards: [], error: '', success: ''})),
  on(GetAllGiftCardsSuccess, (state, {giftCards}) => ({...state, giftCards, error: '', success: ''})),
  on(GetAllGiftCardsError, (state, {error}) => ({...state, giftCards: [], error, success: ''})),
  on(CheckGiftCardValidity, (state) => ({...state, giftCardValidity: null, error: '', success: ''})),
  on(CheckGiftCardValiditySuccess, (state, {giftCardValidity}) => ({
    ...state,
    giftCardValidity,
    error: '',
    success: ''
  })),
  on(CheckGiftCardValidityError, (state, {error}) => ({...state, giftCardValidity: null, error, success: ''})),
  on(ValidateGiftPromoCode, (state) => ({...state, discountDetails: null, error: '', success: ''})),
  on(ValidateGiftPromoCodeSuccess, (state, {discountDetails}) => ({
    ...state,
    discountDetails,
    error: '',
    success: '---DISCOUNT---'
  })),
  on(ValidateGiftPromoCodeError, (state, {error}) => ({...state, discountDetails: null, error, success: ''})),
  on(CheckAssociateStoreIdAvailability, (state) => ({
    ...state,
    isAssociateStoreIdAvailable: '',
    error: '',
    success: ''
  })),
  on(CheckAssociateStoreIdAvailabilitySuccess, (state, {isAssociateStoreIdAvailable}) => ({
    ...state,
    isAssociateStoreIdAvailable,
    error: '',
    success: ''
  })),
  on(CheckAssociateStoreIdAvailabilityError, (state, {error, isAssociateStoreIdAvailable}) => ({
    ...state,
    isAssociateStoreIdAvailable,
    error,
    success: ''
  })),
  on(AddAffiliate, (state) => {
    return {
      ...state,
      affiliate: null,
      success: '',
      error: '',
    };
  }),

  on(AddAffiliateSuccess, (state, {success}) => {
    return {
      ...state,
      success: 'Add successfully',
      error: '',
    };
  }),
  on(AddAffiliateError, (state, {error}) => {
    return {
      ...state,
      affiliate: null,
      success: '',
      error,
    };
  }),
  on(GetAffiliate, (state) => {
    return {
      ...state,
      affiliate: null,
      success: '',
      error: '',
    };
  }),

  on(GetAffiliatesSuccess, (state, {affiliate}) => {
    return {
      ...state,
      affiliate,
      success: '',
      error: '',
    };
  }),

  on(GetAffiliateError, (state, {error}) => {
    return {
      ...state,
      affiliate: null,
      success: '',
      error,
    };
  }),

  on(getAffiliateDashboard, (state) => {
    return {
      ...state,
      affiliateDashboard: null,
      success: '',
      error: '',
    };
  }),

  on(getAffiliateDashboardSuccess, (state, {affiliateDashboard}) => {
    return {
      ...state,
      affiliateDashboard,
      success: '',
      error: '',
    };
  }),

  on(getAffiliateDashboardError, (state, {error}) => {
    return {
      ...state,
      affiliateDashboard: null,
      success: '',
      error,
    };
  }),

  on(AddAffiliateProduct, (state) => {
    return {
      ...state,
      affiliateProducts: null,
      success: '',
      error: '',
    };
  }),

  on(AddAffiliateProductSuccess, (state, {success}) => {
    return {
      ...state,
      success: 'Add successfully',
      error: '',
    };
  }),
  on(AddAffiliateProductError, (state, {error}) => {
    return {
      ...state,
      affiliate: null,
      success: '',
      error,
    };
  }),

  on(GetAffiliateProducts, (state) => {
    return {
      ...state,
      affiliateProducts: null,
      success: '',
      error: '',
    };
  }),

  on(GetAffiliateProductsSuccess, (state, {affiliateProducts}) => {
    return {
      ...state,
      affiliateProducts,
      success: '',
      error: '',
    };
  }),

  on(GetAffiliateProductsError, (state, {error}) => {
    return {
      ...state,
      affiliateProducts: null,
      success: '',
      error,
    };
  }),

  on(EditAffiliate, (state) => {
    return {
      ...state,
      affiliate: null,
      success: '',
      error: '',
    };
  }),

  on(EditAffiliateSuccess, (state, {success}) => {
    return {
      ...state,
      success: 'Edit successfully',
      error: '',
    };
  }),
  on(EditAffiliateError, (state, {error}) => {
    return {
      ...state,
      affiliate: null,
      success: '',
      error,
    };
  }),

  on(GetAffiliateWalletBalance, (state) => ({
    ...state,
    wallet: null,
    error: '',
    success: ''
  })),
  on(GetAffiliateWalletBalanceSuccess, (state, {wallet}) => ({
    ...state,
    wallet,
    error: '',
    success: ''
  })),
  on(GetAffiliateWalletBalanceError, (state, {error}) => ({
    ...state,
    user: null,
    error,
    success: ''
  })),

  on(GetAffiliateWalletTransaction, (state) => ({
    ...state,
    walletTransaction: null,
    error: '',
    success: ''
  })),
  on(GetAffiliateWalletTransactionSuccess, (state, {walletTransaction}) => ({
    ...state,
    walletTransaction,
    error: '',
    success: ''
  })),
  on(GetAffiliateWalletTransactionError, (state, {error}) => ({
    ...state,
    user: null,
    error,
    success: ''
  })),
  on(AddScheduleData, (state) => ({
    ...state,
    wallet: null,
    error: '',
    success: ''
  })),
  on(AddScheduleDataSuccess, (state, {wallet}) => ({
    ...state,
    wallet,
    error: '',
    success: ''
  })),
  on(AddScheduleDataError, (state, {error}) => ({
    ...state,
    error,
    success: ''
  })),

  on(AddPayoutData, (state) => ({
    ...state,
    payout: null,
    error: '',
    success: ''
  })),
  on(AddPayoutDataSuccess, (state, {payout}) => ({
    ...state,
    payout,
    error: '',
    success: ''
  })),
  on(AddPayoutDataError, (state, {error}) => ({
    ...state,
    error,
    success: ''
  })),

  on(GetPayoutDetail, (state) => ({
    ...state,
    payout: null,
    error: '',
    success: ''
  })),
  on(GetPayoutDetailSuccess, (state, {payout}) => ({
    ...state,
    payout,
    error: '',
    success: ''
  })),
  on(GetPayoutDetailError, (state, {error}) => ({
    ...state,
    error,
    success: ''
  })),

  on(SearchAffiliateProducts, (state) => ({
    ...state,
    searchAffiliateProducts: [],
    error: '',
    success: ''
  })),
  on(SearchAffiliateProductsSuccess, (state, {affiliateProducts}) => ({
    ...state,
    searchAffiliateProducts: affiliateProducts,
    error: '',
    success: ''
  })),
  on(SearchAffiliateProductsError, (state, {error}) => ({
    ...state,
    searchAffiliateProducts: [],
    error,
    success: ''
  })),

  on(GetSponsoredItems, (state) => ({
    ...state,
    sponsored: [],
    error: '',
    success: ''
  })),
  on(GetSponsoredItemsSuccess, (state, {sponsored}) => ({
    ...state,
    sponsored,
    error: '',
    success: ''
  })),
  on(GetSponsoredItemsError, (state, {error}) => ({
    ...state,
    sponsored: [],
    error,
    success: ''
  })),

  on(AddToCartItems, (state) => ({
    ...state,
    error: '',
    success: '',
    cart: null
  })),
  on(AddToCartItemsSuccess, (state, {cart}) => {
    return {
      ...state,
      cart: cart,
    };
  }),
  on(AddToCartItemsError, (state, {error}) => ({
    ...state,
    error,
    success: '',
    cart: null
  })),

  on(GetCart, (state) => ({
    ...state,
    error: '',
    success: '',
    cart: null
  })),
  on(GetCartSuccess, (state, {cart}) => {
    return {
      ...state,
      cart: cart,
    };
  }),
  on(GetCartError, (state, {error}) => ({
    ...state,
    error,
    success: '',
    cart: null
  })),
  on(GetSearchProductsSuccess, (state, payload) => {
    return {
      ...state,
      searchData: payload.searchData.data,
      error: '',
      success: '',
    };
  }),

  on(UpdateSellerFavorite, (state, {sellerFavourite}) => {
    return {
      ...state,
      sellerFavourite: sellerFavourite,
      success: '',
      error: '',
    };
  }),

  on(StartNegotiation, (state) => ({
    ...state,
    negotiation: null,
    error: '',
    success: ''
  })),
  on(StartNegotiationSuccess, (state, {negotiation}) => ({
    ...state,
    negotiation,
    error: '',
    success: '---IGNORE---'
  })),
  on(StartNegotiationError, (state, {error}) => ({
    ...state,
    error,
    success: ''
  })),

  on(GetRfqDynamicKey, (state) => ({
    ...state,
    rfqDynamicField: null,
    error: '',
    success: ''
  })),
  on(GetRfqDynamicKeySuccess, (state, {rfqDynamicField}) => ({
    ...state,
    rfqDynamicField,
    error: '',
    success: ''
  })),
  on(GetRfqDynamicKeyError, (state, {error}) => ({
    ...state,
    error,
    success: ''
  })),

  on(GetTempOrder, (state) => ({
    ...state,
    tempOrder: null,
    error: '',
    success: ''
  })),
  on(GetTempOrderSuccess, (state, {tempOrder}) => ({
    ...state,
    tempOrder,
    error: '',
    success: ''
  })),
  on(GetTempOrderError, (state, {error}) => ({
    ...state,
    error,
    success: ''
  })),

  on(AddTempOrder, (state) => ({
    ...state,
    tempOrder: null,
    error: '',
    success: ''
  })),
  on(AddTempOrderSuccess, (state, {tempOrder}) => ({
    ...state,
    tempOrder,
    error: '',
    success: ''
  })),
  on(AddTempOrderError, (state, {error}) => ({
    ...state,
    error,
    success: '',
    tempOrder: null
  })),

  on(GetIntent, (state) => ({
    ...state,
    paymentIntent: null,
    error: '',
    success: ''
  })),
  on(GetIntentSuccess, (state, {paymentIntent}) => ({
    ...state,
    paymentIntent,
    error: '',
    success: ''
  })),
  on(GetIntentError, (state, {error}) => ({
    ...state,
    error,
    success: ''
  })),

  on(GetActivePromoCodes, (state) => {
    return {
      ...state,
      promoCodes: null,
      success: '',
      error: '',
    };
  }),

  on(GetActivePromoCodesSuccess, (state, {promoCodes}) => {
    return {
      ...state,
      promoCodes,
      success: '',
      error: '',
    };
  }),

  on(GetActivePromoCodesError, (state, {error}) => {
    return {
      ...state,
      promoCodes: null,
      success: '',
      error,
    };
  }),

  on(ContactToSeller, (state) => ({
    ...state,
    error: '',
    success: ''
  })),
  on(ContactToSellerSuccess, (state) => ({
    ...state,
    error: '',
    success: '---IGNORE---'
  })),
  on(ContactToSellerError, (state, {error}) => ({
    ...state,
    error,
    success: ''
  })),
);

export function reducer(state = initBuyerState, action: Action) {
  return buyerReducer(state, action);
}
