<ng-container *ngIf="tag === 'p'">
  <p [class]="class">
    <ng-container [ngTemplateOutlet]="type === 'cart' ? priceCartData: priceData"></ng-container>
  </p>
</ng-container>
<ng-container *ngIf="tag === 'span'">
  <span [class]="class"><ng-container
    [ngTemplateOutlet]="type === 'cart' ? priceCartData: priceData"></ng-container></span>
</ng-container>
<ng-container *ngIf="tag === 'h6'">
  <h6 [class]="class">
    <ng-container [ngTemplateOutlet]="type === 'cart' ? priceCartData: priceData"></ng-container>
  </h6>
</ng-container>
<ng-container *ngIf="tag === 'h5'">
  <h5 [class]="class">
    <ng-container [ngTemplateOutlet]="type === 'cart' ? priceCartData: priceData"></ng-container>
  </h5>
</ng-container>
<ng-container *ngIf="tag === 'h4'">
  <h4 [class]="class">
    <ng-container [ngTemplateOutlet]="type === 'cart' ? priceCartData: priceData"></ng-container>
  </h4>
</ng-container>
<ng-container *ngIf="tag === 'h2'">
  <h2 [class]="class">
    <ng-container [ngTemplateOutlet]="type === 'cart' ? priceCartData: priceData"></ng-container>
  </h2>
</ng-container>
<ng-container *ngIf="tag === 'h1'">
  <h1 [class]="class">
    <ng-container [ngTemplateOutlet]="type === 'cart' ? priceCartData: priceData"></ng-container>
  </h1>
</ng-container>
<ng-container *ngIf="tag === 'div'">
  <div [class]="class">
    <ng-container [ngTemplateOutlet]="type === 'cart' ? priceCartData: priceData"></ng-container>
  </div>
</ng-container>
<ng-container *ngIf="!tag && !isB2B">
  <ng-container [ngTemplateOutlet]="type === 'cart' ? priceCartData: priceData"></ng-container>
</ng-container>
<ng-container *ngIf="isB2B && !tag">
  <ng-container [ngTemplateOutlet]="type === 'cart' ? priceCartData: priceData"></ng-container>
</ng-container>

<ng-template #priceData>
  {{((hasField ? (price && price[field] || 0) : price) || 0) | currencyConvert:{
  platformFee: platformFee ? (hasField ? price?.platformFee : item?.platformFee) : 0,
  type: type,
  item: hasField ? price : item
} | async}}
</ng-template>

<ng-template #priceCartData>
  {{((hasField ? (price.updatedPrice || price.price) : price) || 0) | currencyConvert:{
  platformFee: platformFee ? (hasField ? price?.platformFee : item?.platformFee) : 0,
  type: type,
  item: hasField ? price : item
} | async}}
</ng-template>


