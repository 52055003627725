export const BusinessType = [
  {name: 'Limited Partnership (LP)', id: 'Limited Partnership (LP)'},
  {name: 'Public Limited Company (PLC)', id: 'Public Limited Company (PLC)'},
  {name: 'Private Limited Company', id: 'Private Limited Company'},
  {name: 'Unlimited Partnership', id: 'Unlimited Partnership'},
  {name: 'Statutory Corporation', id: 'Statutory Corporation'},
  {name: 'Holding Company', id: 'Holding Company'},
  {name: 'Subsidiary Company', id: 'Subsidiary Company'},
  {name: 'Sole Proprietorship', id: 'Sole Proprietorship'},
  {name: 'Limited Liability Company (LLC)', id: 'Limited Liability Company (LLC)'},
  {name: 'Non-Profit', id: 'Non-Profit'}
]

export const Certifications = [
  {name: 'ISO 9001', id: 'ISO 9001'},
  {name: 'EU GDPR', id: 'EU GDPR'},
  {name: 'ISO 27001', id: 'ISO 27001'},
  {name: 'HALAL Certification', id: 'HALAL Certification'},
  {name: 'PCI DSS', id: 'PCI DSS'},
  {name: 'ISO 22000', id: 'ISO 22000'},
  {name: 'Pytocenetray / Quarantine Certificate', id: 'Pytocenetray / Quarantine Certificate'},
  {name: 'Other', id: 'Other'}
]
