<ng-container *ngIf="discountPrice">
  <kwot-currency
    [price]="discountPrice" [item]="(hasField ? price :item)"
    [tag]="tag" [class]="class + ' fw-bold'"
    [type]="type" [isB2B]="isB2B"
    [hasField]="false"
    [platformFee]="platformFee">
  </kwot-currency>

  <kwot-currency
    [price]="price" [item]="item"
    [tag]="tag" [class]="class + ' text-decoration-line-through neutral_60 m-0'"
    [ngClass]="{'text-decoration-line-through' : discountPrice}"
    [field]="field" [type]="type" [isB2B]="isB2B"
    [hasField]="hasField"
    [platformFee]="platformFee">
  </kwot-currency>
  <span class="price mx-1" *ngIf="showDiscount && !shoWishlist">
    {{-(hasField ? price : item)?.discountProduct?.discountValue}}{{(hasField ? price : item)?.discountProduct?.discountType === 'percentage' ? '%' : currencySymbol}}
  </span>

  <span class="wishlist-price d-inline-block" *ngIf="showDiscount && shoWishlist">
    ({{-(hasField ? price : item)?.discountProduct?.discountValue || -(hasField ? price : item)?.productDiscount?.discountValue}}
    {{(hasField ? price : item)?.discountProduct?.discountType || (hasField ? price : item)?.productDiscount?.discountType === 'percentage' ? '%' : currencySymbol}}
    )
  </span>
</ng-container>
<ng-container *ngIf="!discountPrice">
  <kwot-currency
    [price]="price" [item]="item"
    [tag]="tag" [class]="class"
    [field]="field" [type]="type" [isB2B]="isB2B"
    [hasField]="hasField"
    [platformFee]="platformFee">
  </kwot-currency>
</ng-container>
