import {Inject, Injectable} from "@angular/core";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {APP_CONFIG} from "@kwot/app-config";

@Injectable({
  providedIn: 'root'
})
export class BuyerService {
  ApiUrl: string;

  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private environment: any
  ) {
    this.ApiUrl = `${this.environment.apiUrl}/${this.environment.type}`;
  }

  getAllCategories(params: any) {
    return this.http.get(`${this.ApiUrl}/getAllCategory`, {params});
  }

  getAllHomePageProduct() {
    return this.http.get(`${this.ApiUrl}/homePage`);
  }

  getHomeCategoryResponse() {
    return this.http.get(`${this.ApiUrl}/getCategoryResponse`);
  }

  getBannerImages() {
    return this.http.get(`${this.ApiUrl}/bannerImages`);
  }

  getProductById(productName: string, associateStoreId: string) {
    return this.http.get(`${this.ApiUrl}/getProductById`, {params: {productName, ...(associateStoreId ? {associateStoreId} : {})}});
  }

  sendNotificationForCartItem(params: any) {
    return this.http.post(`${this.ApiUrl}/sendNotificationForCartItem`, {params});
  }

  searchProduct(searchKeyword: any) {
    return this.http.get(`${this.ApiUrl}/homeSearch?search=${searchKeyword}`);
  }

  getAllAddress() {
    return this.http.get(`${this.ApiUrl}/getAllAddress`);
  }

  getAddressById(id: string) {
    return this.http.get(`${this.ApiUrl}/getAddressById`, {params: {id}});
  }

  getCategoryById(id: string) {
    return this.http.get(`${this.ApiUrl}/getCategoryFilter`, {params: {id}});
  }

  getVendorDetails(params: any) {
    return this.http.get(`${this.ApiUrl}/getProductByVendor`, {params});
  }

  createWishList(name: string) {
    return this.http.post(`${this.ApiUrl}/addWishlist`, {name: name});
  }

  getWishList(name?: string) {
    return this.http.get(`${this.ApiUrl}/getWishlist`);
  }

  addProductToWishlist(body: any) {
    return this.http.put(`${this.ApiUrl}/addProductToWishlist`, body);
  }

  editWhistList(body: any) {
    return this.http.put(`${this.ApiUrl}/editWishlist`, body);
  }

  removeProductFromWishlist(body: any) {
    return this.http.put(`${this.ApiUrl}/removeProductFromWishlist`, body);
  }

  deleteWishlist(id: string) {
    return this.http.delete(`${this.ApiUrl}/deleteWishlist`, {params: {id}});
  }

  getWishlistById(id: string) {
    return this.http.get(`${this.ApiUrl}/getWishlistById`, {params: {id}});
  }

  getNotifications(params: any) {
    return this.http.get(`${this.ApiUrl}/getAllNotification`, {params});
  }

  readNotification(id: string) {
    return this.http.get(`${this.ApiUrl}/readNotification`, {params: {id}});
  }

  getProductReviews(params: any) {
    if (params.isSeller) {
      return this.http.get(`${this.ApiUrl}/getSellerReview`, {params});
    } else {
      return this.http.get(`${this.ApiUrl}/getProductReview`, {params});
    }
  }

  postProductReview(body: any) {
    if (body.isSeller) {
      return this.http.post(`${this.ApiUrl}/addVendorReview`, body);
    } else {
      return this.http.post(`${this.ApiUrl}/addReview`, body);
    }
  }

  getProducts(params: any) {
    return this.http.get(`${this.ApiUrl}/getProducts`, {params});
  }

  contactUs(params: any) {
    return this.http.post(`${this.ApiUrl}/addContactUsDetails`, params);
  }

  checkGuestAndUpdate(body: any) {
    return this.http.post(`${this.ApiUrl}/checkGuestAndUpdate`, body);
  }

  getVendorsList(params: any) {
    return this.http.get(`${this.ApiUrl}/getVendors`, {params});
  }

  getGuestUserDetail(body: any) {
    return this.http.post(`${this.ApiUrl}/getOrder`, body);
  }

  cancelOrder(body: any) {
    return this.http.post(`${this.ApiUrl}/cancelOrder`, body);
  }

  getPolicyData(params: any) {
    return this.http.get(`${this.ApiUrl}/getPolicyData`, {params});
  }

  updatePolicyData(params: any) {
    return this.http.get(`${this.ApiUrl}/updatePolicyData`, {params});
  }

  contactNow(params: any) {
    return this.http.get(`${this.ApiUrl}/contactNow`, {params});
  }

  // orderPlace(params: any) {
  //   return this.http.post(`${this.ApiUrl}/placeOrder`, params);
  // }

  orderPayment(paymentDetails: string) {
    return this.http.post(`${this.ApiUrl}/orderPayment`, paymentDetails);
  }

  getProfileInfo() {
    return this.http.get(`${this.ApiUrl}/getProfile`);
  }

  updateProfileInfo(body: any) {
    return this.http.put(`${this.ApiUrl}/editProfile`, body);
  }

  editAddress(params: any) {
    return this.http.put(`${this.ApiUrl}/editAddress`, params);
  }

  deleteAddress(id: string) {
    return this.http.delete(`${this.ApiUrl}/deleteAddress`, {params: {id}});
  }

  addAddress(params: any) {
    return this.http.post(`${this.ApiUrl}/addAddress`, params);
  }

  updateWishlistProduct(params: any) {
    return this.http.get(`${this.ApiUrl}/updateWishlistProduct`, {
      params,
      headers: new HttpHeaders().set('Skip-Log', 'true')
    });
  }

  getAllGiftCards() {
    return this.http.get(`${this.ApiUrl}/getAllGiftCards`);
  }

  checkGiftCardValidity(giftCode: string) {
    return this.http.get(`${this.ApiUrl}/checkGiftCardValidity`, {params: {giftCode}});
  }

  validateDiscountDetails(code: string, type: string) {
    return this.http.get(`${this.ApiUrl}/validateDiscountDetails`, {params: {code, type}});
  }

  addAffiliate(body: any) {
    return this.http.post(`${this.ApiUrl}/addAffiliate`, body);
  }

  checkAssociateStoreId(associateStoreId: any) {
    return this.http.get(`${this.ApiUrl}/checkAssociateStoreId`, {params: {associateStoreId: associateStoreId}});
  }

  getAllAffiliate() {
    return this.http.get(`${this.ApiUrl}/getAllAffiliate`);
  }

  getAffiliateDashboard(params: any) {
    return this.http.get(`${this.ApiUrl}/getAffiliateDashboard`, {params});
  }

  addAffiliateProducts(body: any) {
    return this.http.post(`${this.ApiUrl}/addAffiliateProduct`, body);
  }

  getAffiliateProducts() {
    return this.http.get(`${this.ApiUrl}/getAffiliateProducts`);
  }

  editAffiliate(body: any) {
    return this.http.put(`${this.ApiUrl}/editAffiliate`, body);
  }

  getUserWalletBalance() {
    return this.http.get(`${this.ApiUrl}/getAffiliateWallet`);
  }

  getWalletTransaction(params: any) {
    return this.http.get(`${this.ApiUrl}/getAffiliateWalletTransaction`, {params});
  }

  addWalletAmount(body: any) {
    return this.http.post(`${this.ApiUrl}/addAffiliateWalletAmount`, body);
  }

  addPayoutData(body: any) {
    return this.http.post(`${this.ApiUrl}/addAffiliatePayoutData`, body);
  }

  getPayoutDetail() {
    return this.http.get(`${this.ApiUrl}/getAffiliatePayout`);
  }

  searchAffiliateProducts(params: any) {
    return this.http.get(`${this.ApiUrl}/searchAffiliateProducts`, {params});
  }

  createAdsTransaction(body: any) {
    return this.http.post(`${this.ApiUrl}/createAdsTransaction`, body, {
      headers: new HttpHeaders().set('Skip-auth', 'true')
    });
  }

  getSponsoredItems(body: any) {
    return this.http.post(`${this.environment.apiUrl}/public/getSponsored`, body, {
      headers: new HttpHeaders().set('Skip-auth', 'true')
    });
  }

  addToCart(body: any) {
    return this.http.post(`${this.ApiUrl}/updateCart`, body);
  }

  getCart() {
    return this.http.get(`${this.ApiUrl}/getCart`);
  }

  validateOrder(params: any) {
    return this.http.post(`${this.ApiUrl}/validateOrder`, params);
  }

  updateSellerFavourite(body: any) {
    return this.http.put(`${this.ApiUrl}/updateSellerFavourite`, body);
  }

  startNegotiation(body: any) {
    return this.http.post(`${this.ApiUrl}/addNegotiation`, body);
  }

  getRfqDynamicKey(item: any) {
    return this.http.get(`${this.ApiUrl}/getRfqDynamicKey?item=${item}`);
  }

  addTempOrder(body: any) {
    return this.http.post(`${this.ApiUrl}/addTempData`, body);
  }

  getTempOrder(orderId = '') {
    return this.http.get(`${this.ApiUrl}/getTempData?orderId=${orderId}`);
  }

  getPaymentIntent(intentId: any) {
    return this.http.get(`${this.ApiUrl}/getPaymentIntent?intentId=${intentId}`);
  }

  checkChatUser(body: any) {
    return this.http.post(`${this.ApiUrl}/checkChatUser`, body);
  }

  getActivePromoCodes(params: any){
    return this.http.get(`${this.ApiUrl}/getActivePromoCodes`, {params});
  }

  contactToVendor(params: any) {
    return this.http.post(`${this.ApiUrl}/sendMailToVendor`, {params});
  }
}
