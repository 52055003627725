<div class="selected-variation row">
  <ng-container *ngFor="let variation of variations">
    <h6 class="fs-16 fw-700">{{variation.name}}:
      <span class="list-p-w">
        <ng-container *ngIf="variation.value.hex">
        <svg xmlns="http://www.w3.org/2000/svg" width="42" height="48" viewBox="0 0 42 48" fill="none">
          <path
            d="M42 27C42 40.2548 31 48 21 48C11 48 0 40.2548 0 27C0 15.5 13 0 21 0C27.5 0 42 14.5 42 27Z"
            [ngStyle]="{ fill: variation.value.hex }"/>
        </svg>
      </ng-container>
      <ng-container *ngIf="variation.value.image">
        <img [lazyLoad]="variation.value.image" defaultImage="assets/images/loaders/skeleton_loader.gif"/>
      </ng-container>
      </span>
      <strong *ngIf="variation.name === 'Dimension'">
        {{ variation.value.name?.length }} {{ 'l' | translate }} x {{ variation.value.name?.width }} {{ 'w' | translate }}
        x {{ variation.value.name?.height }} {{ 'h' | translate }} ({{variation.value.name?.metrics}})
        </strong>
      <strong *ngIf="variation.name === 'Weight'">{{variation.value.name}} {{ 'kg' | translate }}</strong>
      <strong *ngIf="variation.name !== 'Dimension' && variation.name !== 'Weight'">{{variation.value.name}}</strong>
    </h6>
  </ng-container>
</div>
