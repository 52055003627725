export const countryCodes = [
  {
    "93": "af"
  },
  {
    "355": "al"
  },
  {
    "213": "dz"
  },
  {
    "684": "as"
  },
  {
    "376": "ad"
  },
  {
    "244": "ao"
  },
  {
    "264": "ai"
  },
  {
    "268": "ag"
  },
  {
    "54": "ar"
  },
  {
    "374": "am"
  },
  {
    "297": "aw"
  },
  {
    "0": "au"
  },
  {
    "43": "at"
  },
  {
    "994": "az"
  },
  {
    "242": "bs"
  },
  {
    "973": "bh"
  },
  {
    "880": "bd"
  },
  {
    "246": "bb"
  },
  {
    "375": "by"
  },
  {
    "32": "be"
  },
  {
    "501": "bz"
  },
  {
    "229": "bj"
  },
  {
    "441": "bm"
  },
  {
    "975": "bt"
  },
  {
    "591": "bo"
  },
  {
    "387": "ba"
  },
  {
    "267": "bw"
  },
  {
    "55": "br"
  },
  {
    "246": "io"
  },
  {
    "284": "vg"
  },
  {
    "673": "bn"
  },
  {
    "359": "bg"
  },
  {
    "226": "bf"
  },
  {
    "257": "bi"
  },
  {
    "855": "kh"
  },
  {
    "237": "cm"
  },
  {
    "204,226,236,249,250,289,306,343,365,387,403,416,418,431,437,438,450,506,514,519,548,579,581,587,604,613,639,647,672,705,709,742,778,780,782,807,819,825,867,873,902,905": "ca"
  },
  {
    "238": "cv"
  },
  {
    "1": "bq"
  },
  {
    "345": "ky"
  },
  {
    "236": "cf"
  },
  {
    "235": "td"
  },
  {
    "56": "cl"
  },
  {
    "86": "cn"
  },
  {
    "2": "cx"
  },
  {
    "1": "cc"
  },
  {
    "57": "co"
  },
  {
    "269": "km"
  },
  {
    "243": "cd"
  },
  {
    "242": "cg"
  },
  {
    "682": "ck"
  },
  {
    "506": "cr"
  },
  {
    "225": "ci"
  },
  {
    "385": "hr"
  },
  {
    "53": "cu"
  },
  {
    "0": "cw"
  },
  {
    "357": "cy"
  },
  {
    "420": "cz"
  },
  {
    "45": "dk"
  },
  {
    "253": "dj"
  },
  {
    "1767": "dm"
  },
  {
    "809,829,849": "do"
  },
  {
    "593": "ec"
  },
  {
    "20": "eg"
  },
  {
    "503": "sv"
  },
  {
    "240": "gq"
  },
  {
    "291": "er"
  },
  {
    "372": "ee"
  },
  {
    "251": "et"
  },
  {
    "500": "fk"
  },
  {
    "298": "fo"
  },
  {
    "679": "fj"
  },
  {
    "0": "fi"
  },
  {
    "33": "fr"
  },
  {
    "594": "gf"
  },
  {
    "689": "pf"
  },
  {
    "241": "ga"
  },
  {
    "220": "gm"
  },
  {
    "995": "ge"
  },
  {
    "49": "de"
  },
  {
    "233": "gh"
  },
  {
    "350": "gi"
  },
  {
    "30": "gr"
  },
  {
    "299": "gl"
  },
  {
    "1473": "gd"
  },
  {
    "0": "gp"
  },
  {
    "671": "gu"
  },
  {
    "502": "gt"
  },
  {
    "1481": "gg"
  },
  {
    "224": "gn"
  },
  {
    "245": "gw"
  },
  {
    "592": "gy"
  },
  {
    "509": "ht"
  },
  {
    "504": "hn"
  },
  {
    "852": "hk"
  },
  {
    "36": "hu"
  },
  {
    "354": "is"
  },
  {
    "91": "in"
  },
  {
    "62": "id"
  },
  {
    "98": "ir"
  },
  {
    "964": "iq"
  },
  {
    "353": "ie"
  },
  {
    "1624": "im"
  },
  {
    "972": "il"
  },
  {
    "0": "it"
  },
  {
    "876": "jm"
  },
  {
    "81": "jp"
  },
  {
    "1534": "je"
  },
  {
    "962": "jo"
  },
  {
    "1": "kz"
  },
  {
    "254": "ke"
  },
  {
    "686": "ki"
  },
  {
    "383": "xk"
  },
  {
    "965": "kw"
  },
  {
    "996": "kg"
  },
  {
    "856": "la"
  },
  {
    "371": "lv"
  },
  {
    "961": "lb"
  },
  {
    "266": "ls"
  },
  {
    "231": "lr"
  },
  {
    "218": "ly"
  },
  {
    "423": "li"
  },
  {
    "370": "lt"
  },
  {
    "352": "lu"
  },
  {
    "853": "mo"
  },
  {
    "389": "mk"
  },
  {
    "261": "mg"
  },
  {
    "265": "mw"
  },
  {
    "60": "my"
  },
  {
    "960": "mv"
  },
  {
    "223": "ml"
  },
  {
    "356": "mt"
  },
  {
    "692": "mh"
  },
  {
    "596": "mq"
  },
  {
    "222": "mr"
  },
  {
    "230": "mu"
  },
  {
    "1": "yt"
  },
  {
    "52": "mx"
  },
  {
    "691": "fm"
  },
  {
    "373": "md"
  },
  {
    "377": "mc"
  },
  {
    "976": "mn"
  },
  {
    "382": "me"
  },
  {
    "664": "ms"
  },
  {
    "0": "ma"
  },
  {
    "258": "mz"
  },
  {
    "95": "mm"
  },
  {
    "264": "na"
  },
  {
    "674": "nr"
  },
  {
    "977": "np"
  },
  {
    "31": "nl"
  },
  {
    "687": "nc"
  },
  {
    "64": "nz"
  },
  {
    "505": "ni"
  },
  {
    "227": "ne"
  },
  {
    "234": "ng"
  },
  {
    "683": "nu"
  },
  {
    "672": "nf"
  },
  {
    "850": "kp"
  },
  {
    "1670": "mp"
  },
  {
    "0": "no"
  },
  {
    "968": "om"
  },
  {
    "92": "pk"
  },
  {
    "680": "pw"
  },
  {
    "970": "ps"
  },
  {
    "507": "pa"
  },
  {
    "675": "pg"
  },
  {
    "595": "py"
  },
  {
    "51": "pe"
  },
  {
    "63": "ph"
  },
  {
    "48": "pl"
  },
  {
    "351": "pt"
  },
  {
    "787,939": "pr"
  },
  {
    "974": "qa"
  },
  {
    "0": "re"
  },
  {
    "40": "ro"
  },
  {
    "0": "ru"
  },
  {
    "250": "rw"
  },
  {
    "1": "bl"
  },
  {
    "290": "sh"
  },
  {
    "1869": "kn"
  },
  {
    "758": "lc"
  },
  {
    "2": "mf"
  },
  {
    "508": "pm"
  },
  {
    "784": "vc"
  },
  {
    "685": "ws"
  },
  {
    "378": "sm"
  },
  {
    "239": "st"
  },
  {
    "966": "sa"
  },
  {
    "221": "sn"
  },
  {
    "381": "rs"
  },
  {
    "248": "sc"
  },
  {
    "232": "sl"
  },
  {
    "65": "sg"
  },
  {
    "721": "sx"
  },
  {
    "421": "sk"
  },
  {
    "386": "si"
  },
  {
    "677": "sb"
  },
  {
    "252": "so"
  },
  {
    "27": "za"
  },
  {
    "82": "kr"
  },
  {
    "211": "ss"
  },
  {
    "34": "es"
  },
  {
    "94": "lk"
  },
  {
    "249": "sd"
  },
  {
    "597": "sr"
  },
  {
    "1": "sj"
  },
  {
    "268": "sz"
  },
  {
    "46": "se"
  },
  {
    "41": "ch"
  },
  {
    "963": "sy"
  },
  {
    "886": "tw"
  },
  {
    "992": "tj"
  },
  {
    "255": "tz"
  },
  {
    "66": "th"
  },
  {
    "670": "tl"
  },
  {
    "228": "tg"
  },
  {
    "690": "tk"
  },
  {
    "676": "to"
  },
  {
    "868": "tt"
  },
  {
    "216": "tn"
  },
  {
    "90": "tr"
  },
  {
    "993": "tm"
  },
  {
    "1649": "tc"
  },
  {
    "688": "tv"
  },
  {
    "340": "vi"
  },
  {
    "256": "ug"
  },
  {
    "380": "ua"
  },
  {
    "971": "ae"
  },
  {
    "0": "gb"
  },
  {
    "0": "us"
  },
  {
    "598": "uy"
  },
  {
    "998": "uz"
  },
  {
    "678": "vu"
  },
  {
    "1": "va"
  },
  {
    "58": "ve"
  },
  {
    "84": "vn"
  },
  {
    "681": "wf"
  },
  {
    "1": "eh"
  },
  {
    "967": "ye"
  },
  {
    "260": "zm"
  },
  {
    "263": "zw"
  },
  {
    "1": "ax"
  }
];

export var CountryISO: any;
(function (CountryISO) {
  CountryISO["Afghanistan"] = "af";
  CountryISO["Albania"] = "al";
  CountryISO["Algeria"] = "dz";
  CountryISO["AmericanSamoa"] = "as";
  CountryISO["Andorra"] = "ad";
  CountryISO["Angola"] = "ao";
  CountryISO["Anguilla"] = "ai";
  CountryISO["AntiguaAndBarbuda"] = "ag";
  CountryISO["Argentina"] = "ar";
  CountryISO["Armenia"] = "am";
  CountryISO["Aruba"] = "aw";
  CountryISO["Australia"] = "au";
  CountryISO["Austria"] = "at";
  CountryISO["Azerbaijan"] = "az";
  CountryISO["Bahamas"] = "bs";
  CountryISO["Bahrain"] = "bh";
  CountryISO["Bangladesh"] = "bd";
  CountryISO["Barbados"] = "bb";
  CountryISO["Belarus"] = "by";
  CountryISO["Belgium"] = "be";
  CountryISO["Belize"] = "bz";
  CountryISO["Benin"] = "bj";
  CountryISO["Bermuda"] = "bm";
  CountryISO["Bhutan"] = "bt";
  CountryISO["Bolivia"] = "bo";
  CountryISO["BosniaAndHerzegovina"] = "ba";
  CountryISO["Botswana"] = "bw";
  CountryISO["Brazil"] = "br";
  CountryISO["BritishIndianOceanTerritory"] = "io";
  CountryISO["BritishVirginIslands"] = "vg";
  CountryISO["Brunei"] = "bn";
  CountryISO["Bulgaria"] = "bg";
  CountryISO["BurkinaFaso"] = "bf";
  CountryISO["Burundi"] = "bi";
  CountryISO["Cambodia"] = "kh";
  CountryISO["Cameroon"] = "cm";
  CountryISO["Canada"] = "ca";
  CountryISO["CapeVerde"] = "cv";
  CountryISO["CaribbeanNetherlands"] = "bq";
  CountryISO["CaymanIslands"] = "ky";
  CountryISO["CentralAfricanRepublic"] = "cf";
  CountryISO["Chad"] = "td";
  CountryISO["Chile"] = "cl";
  CountryISO["China"] = "cn";
  CountryISO["ChristmasIsland"] = "cx";
  CountryISO["Cocos"] = "cc";
  CountryISO["Colombia"] = "co";
  CountryISO["Comoros"] = "km";
  CountryISO["CongoDRCJamhuriYaKidemokrasiaYaKongo"] = "cd";
  CountryISO["CongoRepublicCongoBrazzaville"] = "cg";
  CountryISO["CookIslands"] = "ck";
  CountryISO["CostaRica"] = "cr";
  CountryISO["C\u00F4teDIvoire"] = "ci";
  CountryISO["Croatia"] = "hr";
  CountryISO["Cuba"] = "cu";
  CountryISO["Cura\u00E7ao"] = "cw";
  CountryISO["Cyprus"] = "cy";
  CountryISO["CzechRepublic"] = "cz";
  CountryISO["Denmark"] = "dk";
  CountryISO["Djibouti"] = "dj";
  CountryISO["Dominica"] = "dm";
  CountryISO["DominicanRepublic"] = "do";
  CountryISO["Ecuador"] = "ec";
  CountryISO["Egypt"] = "eg";
  CountryISO["ElSalvador"] = "sv";
  CountryISO["EquatorialGuinea"] = "gq";
  CountryISO["Eritrea"] = "er";
  CountryISO["Estonia"] = "ee";
  CountryISO["Ethiopia"] = "et";
  CountryISO["FalklandIslands"] = "fk";
  CountryISO["FaroeIslands"] = "fo";
  CountryISO["Fiji"] = "fj";
  CountryISO["Finland"] = "fi";
  CountryISO["France"] = "fr";
  CountryISO["FrenchGuiana"] = "gf";
  CountryISO["FrenchPolynesia"] = "pf";
  CountryISO["Gabon"] = "ga";
  CountryISO["Gambia"] = "gm";
  CountryISO["Georgia"] = "ge";
  CountryISO["Germany"] = "de";
  CountryISO["Ghana"] = "gh";
  CountryISO["Gibraltar"] = "gi";
  CountryISO["Greece"] = "gr";
  CountryISO["Greenland"] = "gl";
  CountryISO["Grenada"] = "gd";
  CountryISO["Guadeloupe"] = "gp";
  CountryISO["Guam"] = "gu";
  CountryISO["Guatemala"] = "gt";
  CountryISO["Guernsey"] = "gg";
  CountryISO["Guinea"] = "gn";
  CountryISO["GuineaBissau"] = "gw";
  CountryISO["Guyana"] = "gy";
  CountryISO["Haiti"] = "ht";
  CountryISO["Honduras"] = "hn";
  CountryISO["HongKong"] = "hk";
  CountryISO["Hungary"] = "hu";
  CountryISO["Iceland"] = "is";
  CountryISO["India"] = "in";
  CountryISO["Indonesia"] = "id";
  CountryISO["Iran"] = "ir";
  CountryISO["Iraq"] = "iq";
  CountryISO["Ireland"] = "ie";
  CountryISO["IsleOfMan"] = "im";
  CountryISO["Israel"] = "il";
  CountryISO["Italy"] = "it";
  CountryISO["Jamaica"] = "jm";
  CountryISO["Japan"] = "jp";
  CountryISO["Jersey"] = "je";
  CountryISO["Jordan"] = "jo";
  CountryISO["Kazakhstan"] = "kz";
  CountryISO["Kenya"] = "ke";
  CountryISO["Kiribati"] = "ki";
  CountryISO["Kosovo"] = "xk";
  CountryISO["Kuwait"] = "kw";
  CountryISO["Kyrgyzstan"] = "kg";
  CountryISO["Laos"] = "la";
  CountryISO["Latvia"] = "lv";
  CountryISO["Lebanon"] = "lb";
  CountryISO["Lesotho"] = "ls";
  CountryISO["Liberia"] = "lr";
  CountryISO["Libya"] = "ly";
  CountryISO["Liechtenstein"] = "li";
  CountryISO["Lithuania"] = "lt";
  CountryISO["Luxembourg"] = "lu";
  CountryISO["Macau"] = "mo";
  CountryISO["Macedonia"] = "mk";
  CountryISO["Madagascar"] = "mg";
  CountryISO["Malawi"] = "mw";
  CountryISO["Malaysia"] = "my";
  CountryISO["Maldives"] = "mv";
  CountryISO["Mali"] = "ml";
  CountryISO["Malta"] = "mt";
  CountryISO["MarshallIslands"] = "mh";
  CountryISO["Martinique"] = "mq";
  CountryISO["Mauritania"] = "mr";
  CountryISO["Mauritius"] = "mu";
  CountryISO["Mayotte"] = "yt";
  CountryISO["Mexico"] = "mx";
  CountryISO["Micronesia"] = "fm";
  CountryISO["Moldova"] = "md";
  CountryISO["Monaco"] = "mc";
  CountryISO["Mongolia"] = "mn";
  CountryISO["Montenegro"] = "me";
  CountryISO["Montserrat"] = "ms";
  CountryISO["Morocco"] = "ma";
  CountryISO["Mozambique"] = "mz";
  CountryISO["Myanmar"] = "mm";
  CountryISO["Namibia"] = "na";
  CountryISO["Nauru"] = "nr";
  CountryISO["Nepal"] = "np";
  CountryISO["Netherlands"] = "nl";
  CountryISO["NewCaledonia"] = "nc";
  CountryISO["NewZealand"] = "nz";
  CountryISO["Nicaragua"] = "ni";
  CountryISO["Niger"] = "ne";
  CountryISO["Nigeria"] = "ng";
  CountryISO["Niue"] = "nu";
  CountryISO["NorfolkIsland"] = "nf";
  CountryISO["NorthKorea"] = "kp";
  CountryISO["NorthernMarianaIslands"] = "mp";
  CountryISO["Norway"] = "no";
  CountryISO["Oman"] = "om";
  CountryISO["Pakistan"] = "pk";
  CountryISO["Palau"] = "pw";
  CountryISO["Palestine"] = "ps";
  CountryISO["Panama"] = "pa";
  CountryISO["PapuaNewGuinea"] = "pg";
  CountryISO["Paraguay"] = "py";
  CountryISO["Peru"] = "pe";
  CountryISO["Philippines"] = "ph";
  CountryISO["Poland"] = "pl";
  CountryISO["Portugal"] = "pt";
  CountryISO["PuertoRico"] = "pr";
  CountryISO["Qatar"] = "qa";
  CountryISO["R\u00E9union"] = "re";
  CountryISO["Romania"] = "ro";
  CountryISO["Russia"] = "ru";
  CountryISO["Rwanda"] = "rw";
  CountryISO["SaintBarth\u00E9lemy"] = "bl";
  CountryISO["SaintHelena"] = "sh";
  CountryISO["SaintKittsAndNevis"] = "kn";
  CountryISO["SaintLucia"] = "lc";
  CountryISO["SaintMartin"] = "mf";
  CountryISO["SaintPierreAndMiquelon"] = "pm";
  CountryISO["SaintVincentAndTheGrenadines"] = "vc";
  CountryISO["Samoa"] = "ws";
  CountryISO["SanMarino"] = "sm";
  CountryISO["S\u00E3oTom\u00E9AndPr\u00EDncipe"] = "st";
  CountryISO["SaudiArabia"] = "sa";
  CountryISO["Senegal"] = "sn";
  CountryISO["Serbia"] = "rs";
  CountryISO["Seychelles"] = "sc";
  CountryISO["SierraLeone"] = "sl";
  CountryISO["Singapore"] = "sg";
  CountryISO["SintMaarten"] = "sx";
  CountryISO["Slovakia"] = "sk";
  CountryISO["Slovenia"] = "si";
  CountryISO["SolomonIslands"] = "sb";
  CountryISO["Somalia"] = "so";
  CountryISO["SouthAfrica"] = "za";
  CountryISO["SouthKorea"] = "kr";
  CountryISO["SouthSudan"] = "ss";
  CountryISO["Spain"] = "es";
  CountryISO["SriLanka"] = "lk";
  CountryISO["Sudan"] = "sd";
  CountryISO["Suriname"] = "sr";
  CountryISO["SvalbardAndJanMayen"] = "sj";
  CountryISO["Swaziland"] = "sz";
  CountryISO["Sweden"] = "se";
  CountryISO["Switzerland"] = "ch";
  CountryISO["Syria"] = "sy";
  CountryISO["Taiwan"] = "tw";
  CountryISO["Tajikistan"] = "tj";
  CountryISO["Tanzania"] = "tz";
  CountryISO["Thailand"] = "th";
  CountryISO["TimorLeste"] = "tl";
  CountryISO["Togo"] = "tg";
  CountryISO["Tokelau"] = "tk";
  CountryISO["Tonga"] = "to";
  CountryISO["TrinidadAndTobago"] = "tt";
  CountryISO["Tunisia"] = "tn";
  CountryISO["Turkey"] = "tr";
  CountryISO["Turkmenistan"] = "tm";
  CountryISO["TurksAndCaicosIslands"] = "tc";
  CountryISO["Tuvalu"] = "tv";
  CountryISO["USVirginIslands"] = "vi";
  CountryISO["Uganda"] = "ug";
  CountryISO["Ukraine"] = "ua";
  CountryISO["UnitedArabEmirates"] = "ae";
  CountryISO["UnitedKingdom"] = "gb";
  CountryISO["UnitedStates"] = "us";
  CountryISO["Uruguay"] = "uy";
  CountryISO["Uzbekistan"] = "uz";
  CountryISO["Vanuatu"] = "vu";
  CountryISO["VaticanCity"] = "va";
  CountryISO["Venezuela"] = "ve";
  CountryISO["Vietnam"] = "vn";
  CountryISO["WallisAndFutuna"] = "wf";
  CountryISO["WesternSahara"] = "eh";
  CountryISO["Yemen"] = "ye";
  CountryISO["Zambia"] = "zm";
  CountryISO["Zimbabwe"] = "zw";
  CountryISO["\u00C5landIslands"] = "ax";
})(CountryISO || (CountryISO = {}));

export const countriesWithAreaCodes = [
  [
    'Afghanistan (‫افغانستان‬‎)',
    CountryISO.Afghanistan,
    '93'
  ],
  [
    'Albania (Shqipëri)',
    CountryISO.Albania,
    '355'
  ],
  [
    'Algeria (‫الجزائر‬‎)',
    CountryISO.Algeria,
    '213'
  ],
  [
    'American Samoa',
    'as',
    '1',
    1,
    [
      '684',
    ]
  ],
  [
    'Andorra',
    CountryISO.Andorra,
    '376'
  ],
  [
    'Angola',
    CountryISO.Angola,
    '244'
  ],
  [
    'Anguilla',
    'ai',
    '1',
    1,
    [
      '264',
    ]
  ],
  [
    'Antigua and Barbuda',
    'ag',
    '1',
    1,
    [
      '268',
    ]
  ],
  [
    'Argentina',
    CountryISO.Argentina,
    '54'
  ],
  [
    'Armenia (Հայաստան)',
    CountryISO.Armenia,
    '374'
  ],
  [
    'Aruba',
    CountryISO.Aruba,
    '297'
  ],
  [
    'Australia',
    CountryISO.Australia,
    '61',
    0
  ],
  [
    'Austria (Österreich)',
    CountryISO.Austria,
    '43'
  ],
  [
    'Azerbaijan (Azərbaycan)',
    CountryISO.Azerbaijan,
    '994'
  ],
  [
    'Bahamas',
    'bs',
    '1',
    1,
    [
      '242',
    ]
  ],
  [
    'Bahrain (‫البحرين‬‎)',
    CountryISO.Bahrain,
    '973'
  ],
  [
    'Bangladesh (বাংলাদেশ)',
    CountryISO.Bangladesh,
    '880'
  ],
  [
    'Barbados',
    'bb',
    '1',
    1,
    [
      '246',
    ]
  ],
  [
    'Belarus (Беларусь)',
    CountryISO.Belarus,
    '375'
  ],
  [
    'Belgium (België)',
    CountryISO.Belgium,
    '32'
  ],
  [
    'Belize',
    CountryISO.Belize,
    '501'
  ],
  [
    'Benin (Bénin)',
    CountryISO.Benin,
    '229'
  ],
  [
    'Bermuda',
    'bm',
    '1',
    1,
    [
      '441',
    ]
  ],
  [
    'Bhutan (འབྲུག)',
    CountryISO.Bhutan,
    '975'
  ],
  [
    'Bolivia',
    CountryISO.Bolivia,
    '591'
  ],
  [
    'Bosnia and Herzegovina (Босна и Херцеговина)',
    CountryISO.BosniaAndHerzegovina,
    '387'
  ],
  [
    'Botswana',
    CountryISO.Botswana,
    '267'
  ],
  [
    'Brazil (Brasil)',
    CountryISO.Brazil,
    '55'
  ],
  [
    'British Indian Ocean Territory',
    CountryISO.BritishIndianOceanTerritory,
    '246'
  ],
  [
    'British Virgin Islands',
    'vg',
    '1',
    1,
    [
      '284',
    ]
  ],
  [
    'Brunei',
    CountryISO.Brunei,
    '673'
  ],
  [
    'Bulgaria (България)',
    CountryISO.Bulgaria,
    '359'
  ],
  [
    'Burkina Faso',
    CountryISO.BurkinaFaso,
    '226'
  ],
  [
    'Burundi (Uburundi)',
    CountryISO.Burundi,
    '257'
  ],
  [
    'Cambodia (កម្ពុជា)',
    CountryISO.Cambodia,
    '855'
  ],
  [
    'Cameroon (Cameroun)',
    CountryISO.Cameroon,
    '237'
  ],
  [
    'Canada',
    CountryISO.Canada,
    '1',
    1,
    [
      '204', '226', '236', '249', '250', '289', '306', '343', '365', '387', '403', '416',
      '418', '431', '437', '438', '450', '506', '514', '519', '548', '579', '581', '587',
      '604', '613', '639', '647', '672', '705', '709', '742', '778', '780', '782', '807',
      '819', '825', '867', '873', '902', '905'
    ]
  ],
  [
    'Cape Verde (Kabu Verdi)',
    CountryISO.CapeVerde,
    '238'
  ],
  [
    'Caribbean Netherlands',
    CountryISO.CaribbeanNetherlands,
    '599',
    1
  ],
  [
    'Cayman Islands',
    'ky',
    '1',
    1,
    [
      '345',
    ]
  ],
  [
    'Central African Republic (République centrafricaine)',
    CountryISO.CentralAfricanRepublic,
    '236'
  ],
  [
    'Chad (Tchad)',
    CountryISO.Chad,
    '235'
  ],
  [
    'Chile',
    CountryISO.Chile,
    '56'
  ],
  [
    'China (中国)',
    CountryISO.China,
    '86'
  ],
  [
    'Christmas Island',
    CountryISO.ChristmasIsland,
    '61',
    2
  ],
  [
    'Cocos (Keeling) Islands',
    CountryISO.Cocos,
    '61',
    1
  ],
  [
    'Colombia',
    CountryISO.Colombia,
    '57'
  ],
  [
    'Comoros (‫جزر القمر‬‎)',
    CountryISO.Comoros,
    '269'
  ],
  [
    'Congo (DRC) (Jamhuri ya Kidemokrasia ya Kongo)',
    CountryISO.CongoDRCJamhuriYaKidemokrasiaYaKongo,
    '243'
  ],
  [
    'Congo (Republic) (Congo-Brazzaville)',
    CountryISO.CongoRepublicCongoBrazzaville,
    '242'
  ],
  [
    'Cook Islands',
    CountryISO.CookIslands,
    '682'
  ],
  [
    'Costa Rica',
    CountryISO.CostaRica,
    '506'
  ],
  [
    'Côte d’Ivoire',
    CountryISO.CôteDIvoire,
    '225'
  ],
  [
    'Croatia (Hrvatska)',
    CountryISO.Croatia,
    '385'
  ],
  [
    'Cuba',
    CountryISO.Cuba,
    '53'
  ],
  [
    'Curaçao',
    CountryISO.Curaçao,
    '599',
    0
  ],
  [
    'Cyprus (Κύπρος)',
    CountryISO.Cyprus,
    '357'
  ],
  [
    'Czech Republic (Česká republika)',
    CountryISO.CzechRepublic,
    '420'
  ],
  [
    'Denmark (Danmark)',
    CountryISO.Denmark,
    '45'
  ],
  [
    'Djibouti',
    CountryISO.Djibouti,
    '253'
  ],
  [
    'Dominica',
    CountryISO.Dominica,
    '1767'
  ],
  [
    'Dominican Republic (República Dominicana)',
    CountryISO.DominicanRepublic,
    '1',
    2,
    ['809', '829', '849']
  ],
  [
    'Ecuador',
    CountryISO.Ecuador,
    '593'
  ],
  [
    'Egypt (‫مصر‬‎)',
    CountryISO.Egypt,
    '20'
  ],
  [
    'El Salvador',
    CountryISO.ElSalvador,
    '503'
  ],
  [
    'Equatorial Guinea (Guinea Ecuatorial)',
    CountryISO.EquatorialGuinea,
    '240'
  ],
  [
    'Eritrea',
    CountryISO.Eritrea,
    '291'
  ],
  [
    'Estonia (Eesti)',
    CountryISO.Estonia,
    '372'
  ],
  [
    'Ethiopia',
    CountryISO.Ethiopia,
    '251'
  ],
  [
    'Falkland Islands (Islas Malvinas)',
    CountryISO.FalklandIslands,
    '500'
  ],
  [
    'Faroe Islands (Føroyar)',
    CountryISO.FaroeIslands,
    '298'
  ],
  [
    'Fiji',
    CountryISO.Fiji,
    '679'
  ],
  [
    'Finland (Suomi)',
    CountryISO.Finland,
    '358',
    0
  ],
  [
    'France',
    CountryISO.France,
    '33'
  ],
  [
    'French Guiana (Guyane française)',
    CountryISO.FrenchGuiana,
    '594'
  ],
  [
    'French Polynesia (Polynésie française)',
    CountryISO.FrenchPolynesia,
    '689'
  ],
  [
    'Gabon',
    CountryISO.Gabon,
    '241'
  ],
  [
    'Gambia',
    CountryISO.Gambia,
    '220'
  ],
  [
    'Georgia (საქართველო)',
    CountryISO.Georgia,
    '995'
  ],
  [
    'Germany (Deutschland)',
    CountryISO.Germany,
    '49'
  ],
  [
    'Ghana (Gaana)',
    CountryISO.Ghana,
    '233'
  ],
  [
    'Gibraltar',
    CountryISO.Gibraltar,
    '350'
  ],
  [
    'Greece (Ελλάδα)',
    CountryISO.Greece,
    '30'
  ],
  [
    'Greenland (Kalaallit Nunaat)',
    CountryISO.Greenland,
    '299'
  ],
  [
    'Grenada',
    CountryISO.Grenada,
    '1473'
  ],
  [
    'Guadeloupe',
    CountryISO.Guadeloupe,
    '590',
    0
  ],
  [
    'Guam',
    'gu',
    '1',
    1,
    [
      '671',
    ]
  ],
  [
    'Guatemala',
    CountryISO.Guatemala,
    '502'
  ],
  [
    'Guernsey',
    CountryISO.Guernsey,
    '44',
    1,
    [1481]
  ],
  [
    'Guinea (Guinée)',
    CountryISO.Guinea,
    '224'
  ],
  [
    'Guinea-Bissau (Guiné Bissau)',
    CountryISO.GuineaBissau,
    '245'
  ],
  [
    'Guyana',
    CountryISO.Guyana,
    '592'
  ],
  [
    'Haiti',
    CountryISO.Haiti,
    '509'
  ],
  [
    'Honduras',
    CountryISO.Honduras,
    '504'
  ],
  [
    'Hong Kong (香港)',
    CountryISO.HongKong,
    '852'
  ],
  [
    'Hungary (Magyarország)',
    CountryISO.Hungary,
    '36'
  ],
  [
    'Iceland (Ísland)',
    CountryISO.Iceland,
    '354'
  ],
  [
    'India (भारत)',
    CountryISO.India,
    '91'
  ],
  [
    'Indonesia',
    CountryISO.Indonesia,
    '62'
  ],
  [
    'Iran (‫ایران‬‎)',
    CountryISO.Iran,
    '98'
  ],
  [
    'Iraq (‫العراق‬‎)',
    CountryISO.Iraq,
    '964'
  ],
  [
    'Ireland',
    CountryISO.Ireland,
    '353'
  ],
  [
    'Isle of Man',
    CountryISO.IsleOfMan,
    '44',
    2,
    [1624]
  ],
  [
    'Israel (‫ישראל‬‎)',
    CountryISO.Israel,
    '972'
  ],
  [
    'Italy (Italia)',
    CountryISO.Italy,
    '39',
    0
  ],
  [
    'Jamaica',
    'jm',
    '1',
    1,
    [
      '876',
    ]
  ],
  [
    'Japan (日本)',
    CountryISO.Japan,
    '81'
  ],
  [
    'Jersey',
    CountryISO.Jersey,
    '44',
    3,
    [1534]
  ],
  [
    'Jordan (‫الأردن‬‎)',
    CountryISO.Jordan,
    '962'
  ],
  [
    'Kazakhstan (Казахстан)',
    CountryISO.Kazakhstan,
    '7',
    1
  ],
  [
    'Kenya',
    CountryISO.Kenya,
    '254'
  ],
  [
    'Kiribati',
    CountryISO.Kiribati,
    '686'
  ],
  [
    'Kosovo',
    CountryISO.Kosovo,
    '383'
  ],
  [
    'Kuwait (‫الكويت‬‎)',
    CountryISO.Kuwait,
    '965'
  ],
  [
    'Kyrgyzstan (Кыргызстан)',
    CountryISO.Kyrgyzstan,
    '996'
  ],
  [
    'Laos (ລາວ)',
    CountryISO.Laos,
    '856'
  ],
  [
    'Latvia (Latvija)',
    CountryISO.Latvia,
    '371'
  ],
  [
    'Lebanon (‫لبنان‬‎)',
    CountryISO.Lebanon,
    '961'
  ],
  [
    'Lesotho',
    CountryISO.Lesotho,
    '266'
  ],
  [
    'Liberia',
    CountryISO.Liberia,
    '231'
  ],
  [
    'Libya (‫ليبيا‬‎)',
    CountryISO.Libya,
    '218'
  ],
  [
    'Liechtenstein',
    CountryISO.Liechtenstein,
    '423'
  ],
  [
    'Lithuania (Lietuva)',
    CountryISO.Lithuania,
    '370'
  ],
  [
    'Luxembourg',
    CountryISO.Luxembourg,
    '352'
  ],
  [
    'Macau (澳門)',
    CountryISO.Macau,
    '853'
  ],
  [
    'Macedonia (FYROM) (Македонија)',
    CountryISO.Macedonia,
    '389'
  ],
  [
    'Madagascar (Madagasikara)',
    CountryISO.Madagascar,
    '261'
  ],
  [
    'Malawi',
    CountryISO.Malawi,
    '265'
  ],
  [
    'Malaysia',
    CountryISO.Malaysia,
    '60'
  ],
  [
    'Maldives',
    CountryISO.Maldives,
    '960'
  ],
  [
    'Mali',
    CountryISO.Mali,
    '223'
  ],
  [
    'Malta',
    CountryISO.Malta,
    '356'
  ],
  [
    'Marshall Islands',
    CountryISO.MarshallIslands,
    '692'
  ],
  [
    'Martinique',
    CountryISO.Martinique,
    '596'
  ],
  [
    'Mauritania (‫موريتانيا‬‎)',
    CountryISO.Mauritania,
    '222'
  ],
  [
    'Mauritius (Moris)',
    CountryISO.Mauritius,
    '230'
  ],
  [
    'Mayotte',
    CountryISO.Mayotte,
    '262',
    1
  ],
  [
    'Mexico (México)',
    CountryISO.Mexico,
    '52'
  ],
  [
    'Micronesia',
    CountryISO.Micronesia,
    '691'
  ],
  [
    'Moldova (Republica Moldova)',
    CountryISO.Moldova,
    '373'
  ],
  [
    'Monaco',
    CountryISO.Monaco,
    '377'
  ],
  [
    'Mongolia (Монгол)',
    CountryISO.Mongolia,
    '976'
  ],
  [
    'Montenegro (Crna Gora)',
    CountryISO.Montenegro,
    '382'
  ],
  [
    'Montserrat',
    'ms',
    '1',
    1,
    [
      '664',
    ]
  ],
  [
    'Morocco (‫المغرب‬‎)',
    CountryISO.Morocco,
    '212',
    0
  ],
  [
    'Mozambique (Moçambique)',
    CountryISO.Mozambique,
    '258'
  ],
  [
    'Myanmar (Burma) (မြန်မာ)',
    CountryISO.Myanmar,
    '95'
  ],
  [
    'Namibia (Namibië)',
    CountryISO.Namibia,
    '264'
  ],
  [
    'Nauru',
    CountryISO.Nauru,
    '674'
  ],
  [
    'Nepal (नेपाल)',
    CountryISO.Nepal,
    '977'
  ],
  [
    'Netherlands (Nederland)',
    CountryISO.Netherlands,
    '31'
  ],
  [
    'New Caledonia (Nouvelle-Calédonie)',
    CountryISO.NewCaledonia,
    '687'
  ],
  [
    'New Zealand',
    CountryISO.NewZealand,
    '64'
  ],
  [
    'Nicaragua',
    CountryISO.Nicaragua,
    '505'
  ],
  [
    'Niger (Nijar)',
    CountryISO.Niger,
    '227'
  ],
  [
    'Nigeria',
    CountryISO.Nigeria,
    '234'
  ],
  [
    'Niue',
    CountryISO.Niue,
    '683'
  ],
  [
    'Norfolk Island',
    CountryISO.NorfolkIsland,
    '672'
  ],
  [
    'North Korea (조선 민주주의 인민 공화국)',
    CountryISO.NorthKorea,
    '850'
  ],
  [
    'Northern Mariana Islands',
    CountryISO.NorthernMarianaIslands,
    '1670'
  ],
  [
    'Norway (Norge)',
    CountryISO.Norway,
    '47',
    0
  ],
  [
    'Oman (‫عُمان‬‎)',
    CountryISO.Oman,
    '968'
  ],
  [
    'Pakistan (‫پاکستان‬‎)',
    CountryISO.Pakistan,
    '92'
  ],
  [
    'Palau',
    CountryISO.Palau,
    '680'
  ],
  [
    'Palestine (‫فلسطين‬‎)',
    CountryISO.Palestine,
    '970'
  ],
  [
    'Panama (Panamá)',
    CountryISO.Panama,
    '507'
  ],
  [
    'Papua New Guinea',
    CountryISO.PapuaNewGuinea,
    '675'
  ],
  [
    'Paraguay',
    CountryISO.Paraguay,
    '595'
  ],
  [
    'Peru (Perú)',
    CountryISO.Peru,
    '51'
  ],
  [
    'Philippines',
    CountryISO.Philippines,
    '63'
  ],
  [
    'Poland (Polska)',
    CountryISO.Poland,
    '48'
  ],
  [
    'Portugal',
    CountryISO.Portugal,
    '351'
  ],
  [
    'Puerto Rico',
    CountryISO.PuertoRico,
    '1',
    3,
    ['787', '939']
  ],
  [
    'Qatar (‫قطر‬‎)',
    CountryISO.Qatar,
    '974'
  ],
  [
    'Réunion (La Réunion)',
    CountryISO.Réunion,
    '262',
    0
  ],
  [
    'Romania (România)',
    CountryISO.Romania,
    '40'
  ],
  [
    'Russia (Россия)',
    CountryISO.Russia,
    '7',
    0
  ],
  [
    'Rwanda',
    CountryISO.Rwanda,
    '250'
  ],
  [
    'Saint Barthélemy (Saint-Barthélemy)',
    CountryISO.SaintBarthélemy,
    '590',
    1
  ],
  [
    'Saint Helena',
    CountryISO.SaintHelena,
    '290'
  ],
  [
    'Saint Kitts and Nevis',
    CountryISO.SaintKittsAndNevis,
    '1869'
  ],
  [
    'Saint Lucia',
    'lc',
    '1',
    1,
    [
      '758',
    ]
  ],
  [
    'Saint Martin (Saint-Martin (partie française))',
    CountryISO.SaintMartin,
    '590',
    2
  ],
  [
    'Saint Pierre and Miquelon (Saint-Pierre-et-Miquelon)',
    CountryISO.SaintPierreAndMiquelon,
    '508'
  ],
  [
    'Saint Vincent and the Grenadines',
    'vc',
    '1',
    1,
    [
      '784',
    ]
  ],
  [
    'Samoa',
    CountryISO.Samoa,
    '685'
  ],
  [
    'San Marino',
    CountryISO.SanMarino,
    '378'
  ],
  [
    'São Tomé and Príncipe (São Tomé e Príncipe)',
    CountryISO.SãoToméAndPríncipe,
    '239'
  ],
  [
    'Saudi Arabia (‫المملكة العربية السعودية‬‎)',
    CountryISO.SaudiArabia,
    '966'
  ],
  [
    'Senegal (Sénégal)',
    CountryISO.Senegal,
    '221'
  ],
  [
    'Serbia (Србија)',
    CountryISO.Serbia,
    '381'
  ],
  [
    'Seychelles',
    CountryISO.Seychelles,
    '248'
  ],
  [
    'Sierra Leone',
    CountryISO.SierraLeone,
    '232'
  ],
  [
    'Singapore',
    CountryISO.Singapore,
    '65'
  ],
  [
    'Sint Maarten',
    'sx',
    '1',
    1,
    [
      '721',
    ]
  ],
  [
    'Slovakia (Slovensko)',
    CountryISO.Slovakia,
    '421'
  ],
  [
    'Slovenia (Slovenija)',
    CountryISO.Slovenia,
    '386'
  ],
  [
    'Solomon Islands',
    CountryISO.SolomonIslands,
    '677'
  ],
  [
    'Somalia (Soomaaliya)',
    CountryISO.Somalia,
    '252'
  ],
  [
    'South Africa',
    CountryISO.SouthAfrica,
    '27'
  ],
  [
    'South Korea (대한민국)',
    CountryISO.SouthKorea,
    '82'
  ],
  [
    'South Sudan (‫جنوب السودان‬‎)',
    CountryISO.SouthSudan,
    '211'
  ],
  [
    'Spain (España)',
    CountryISO.Spain,
    '34'
  ],
  [
    'Sri Lanka (ශ්‍රී ලංකාව)',
    CountryISO.SriLanka,
    '94'
  ],
  [
    'Sudan (‫السودان‬‎)',
    CountryISO.Sudan,
    '249'
  ],
  [
    'Suriname',
    CountryISO.Suriname,
    '597'
  ],
  [
    'Svalbard and Jan Mayen',
    CountryISO.SvalbardAndJanMayen,
    '47',
    1
  ],
  [
    'Swaziland',
    CountryISO.Swaziland,
    '268'
  ],
  [
    'Sweden (Sverige)',
    CountryISO.Sweden,
    '46'
  ],
  [
    'Switzerland (Schweiz)',
    CountryISO.Switzerland,
    '41'
  ],
  [
    'Syria (‫سوريا‬‎)',
    CountryISO.Syria,
    '963'
  ],
  [
    'Taiwan (台灣)',
    CountryISO.Taiwan,
    '886'
  ],
  [
    'Tajikistan',
    CountryISO.Tajikistan,
    '992'
  ],
  [
    'Tanzania',
    CountryISO.Tanzania,
    '255'
  ],
  [
    'Thailand (ไทย)',
    CountryISO.Thailand,
    '66'
  ],
  [
    'Timor-Leste',
    CountryISO.TimorLeste,
    '670'
  ],
  [
    'Togo',
    CountryISO.Togo,
    '228'
  ],
  [
    'Tokelau',
    CountryISO.Tokelau,
    '690'
  ],
  [
    'Tonga',
    CountryISO.Tonga,
    '676'
  ],
  [
    'Trinidad and Tobago',
    'tt',
    '1',
    1,
    [
      '868',
    ]
  ],
  [
    'Tunisia (‫تونس‬‎)',
    CountryISO.Tunisia,
    '216'
  ],
  [
    'Turkey (Türkiye)',
    CountryISO.Turkey,
    '90'
  ],
  [
    'Turkmenistan',
    CountryISO.Turkmenistan,
    '993'
  ],
  [
    'Turks and Caicos Islands',
    CountryISO.TurksAndCaicosIslands,
    '1649'
  ],
  [
    'Tuvalu',
    CountryISO.Tuvalu,
    '688'
  ],
  [
    'U.S. Virgin Islands',
    'vi',
    '1',
    1,
    [
      '340',
    ]
  ],
  [
    'Uganda',
    CountryISO.Uganda,
    '256'
  ],
  [
    'Ukraine (Україна)',
    CountryISO.Ukraine,
    '380'
  ],
  [
    'United Arab Emirates (‫الإمارات العربية المتحدة‬‎)',
    CountryISO.UnitedArabEmirates,
    '971'
  ],
  [
    'United Kingdom',
    CountryISO.UnitedKingdom,
    '44',
    0
  ],
  [
    'United States',
    CountryISO.UnitedStates,
    '1',
    0
  ],
  [
    'Uruguay',
    CountryISO.Uruguay,
    '598'
  ],
  [
    'Uzbekistan (Oʻzbekiston)',
    CountryISO.Uzbekistan,
    '998'
  ],
  [
    'Vanuatu',
    CountryISO.Vanuatu,
    '678'
  ],
  [
    'Vatican City (Città del Vaticano)',
    CountryISO.VaticanCity,
    '39',
    1
  ],
  [
    'Venezuela',
    CountryISO.Venezuela,
    '58'
  ],
  [
    'Vietnam (Việt Nam)',
    CountryISO.Vietnam,
    '84'
  ],
  [
    'Wallis and Futuna',
    CountryISO.WallisAndFutuna,
    '681'
  ],
  [
    'Western Sahara (‫الصحراء الغربية‬‎)',
    CountryISO.WesternSahara,
    '212',
    1
  ],
  [
    'Yemen (‫اليمن‬‎)',
    CountryISO.Yemen,
    '967'
  ],
  [
    'Zambia',
    CountryISO.Zambia,
    '260'
  ],
  [
    'Zimbabwe',
    CountryISO.Zimbabwe,
    '263'
  ],
  [
    'Åland Islands',
    CountryISO.ÅlandIslands,
    '358',
    1
  ]
]
