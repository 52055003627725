import {createFeatureSelector, createSelector} from '@ngrx/store';
import {AuthState} from "./auth.reducer";

const getAuthState = createFeatureSelector<AuthState>('auth');

export const getLoggedInUser = createSelector(getAuthState, (state: AuthState) => state?.user);

export const getRegisterUser = createSelector(getAuthState, (state: AuthState) => state?.registerUser);

export const getSignInWithSocialUser = createSelector(getAuthState, (state: AuthState) => state?.signInUserSocial);

export const getToken = createSelector(getAuthState, (state: AuthState) => state?.user?.token);

export const getAuthError = createSelector(getAuthState, (state: AuthState) => state?.error);

export const getAuthSuccess = createSelector(getAuthState, (state: AuthState) => state?.success);
