export enum OrderStatus {
    PENDING,
    ACCEPTED,
    REJECTED,
    ONTHEWAY,
    COMPLETED,
    CANCELD,
    PAYMENT_PENDING,
    MEASUREMENTS,
    AWAITING_PAYMENT
}

export enum ReturnOrderStatus {
    PENDING,
    ACCEPTED,
    COMPLETED,
    CANCELD,
}

export const OrderStatusText = {
    [OrderStatus.PENDING]: 'Pending',
    [OrderStatus.ACCEPTED]: 'Accepted',
    [OrderStatus.REJECTED]: 'Canceled',
    [OrderStatus.ONTHEWAY]: 'Shipped',
    [OrderStatus.COMPLETED]: 'Delivered',
    [OrderStatus.CANCELD]: 'Canceled',
    [OrderStatus.PAYMENT_PENDING]: 'Payment Pending',
    [OrderStatus.MEASUREMENTS]: 'Pending measurement',
    [OrderStatus.AWAITING_PAYMENT]: 'Awaiting Payment Confirmation'
}

export const ReturnOrderStatusText = {
    [ReturnOrderStatus.PENDING]: 'Return request',
    [ReturnOrderStatus.ACCEPTED]: 'Return In Progress',
    [ReturnOrderStatus.COMPLETED]: 'Return Complete',
    [ReturnOrderStatus.CANCELD]: 'Return Rejected'
}
