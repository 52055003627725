export const environment = {
  production: false,
  apiUrl: 'https://yt40strhwi.execute-api.us-east-1.amazonaws.com/dev/v1',
  buyerUrl: 'https://buyer-dev.kwot.com',
  google_key: '341078272776-31vppl0h663ol2ecq0b0jlk608qqhl0j.apps.googleusercontent.com',
  google_recaptcha_site_key: '6LdZ6zkeAAAAADsF4kZjyjNhR7jnfiTGzrPEhlnG',
  google_recaptcha_secret: '6LdZ6zkeAAAAAJnO4XD-th0HYQFtYKTjx18yEP4d',
  type: 'vendor',
  ip_api :'https://api.ipgeolocation.io/ipgeo?apiKey=386b26cb610a499aa8e7ab71e07dbf71',
  gaTag: '',
  applicationType: 'b2b',
  s3Bucket: 'kwot.api',
  interSwitchUrl: 'https://isw-hosted-fields.k8.isw.la/sdk.js',
  interSwitchMerchantCode: 'MX82084',
  interSwitchPaymentId: 'Default_Payable_MX82084',
  interSwitchPaymentConfirmUrl: 'https://qa.interswitchng.com/collections/api/v1/gettransaction.json',
  stripe_publishable_key: 'pk_test_51KJyV0BMHzOCaZLFfOrrgwd2eWIwlqUAaGBaYNuddUrk9i9Al6OZF171qKUXefimQgwP7brLoKYhKXbPcSjkkNWv00o1YeI3PG',
  websocket: 'wss://6ljbqkqxd6.execute-api.us-east-1.amazonaws.com/dev',
  flutterwave_public_key: 'FLWPUBK_TEST-1c28017fc812e2a4fe375a7efd7117be-X',
};
