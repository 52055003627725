export enum ProfileStatus {
  STORE_SUBSCRIPTION,
  STORE_PREFERENCE,
  STORE_MEDIA,
  STORE_PRODUCT
}

export const ProfileStatusText = {
  [ProfileStatus.STORE_SUBSCRIPTION]: 'Store Subscription',

  [ProfileStatus.STORE_PREFERENCE]: 'Store Setup',
  // [ProfileStatus.STORE_NAME]: 'Store Name',
  [ProfileStatus.STORE_MEDIA]: 'Media',
  // [ProfileStatus.STORE_DOCUMENTATION]: 'Documentation',
  // [ProfileStatus.STORE_BILLING_DETAILS]: 'Billing Details',
  // [ProfileStatus.STORE_BANK_DETAILS]: 'Bank Details',
  [ProfileStatus.STORE_PRODUCT]: 'Product',
}
