import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StoreModule} from "@ngrx/store";
import {EffectsModule} from "@ngrx/effects";
import * as fromBuyer from './+state/buyer.reducer';
import {BuyerEffects} from './+state/buyer.effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('buyer', fromBuyer.reducer),
    EffectsModule.forFeature([BuyerEffects]),
  ],
})
export class BuyerStoreModule {
}
