import {Inject, Injectable} from '@angular/core';
import {createEffect, Actions, ofType} from '@ngrx/effects';
import {AuthService} from "../services/auth.service";
import {
  CheckAccountVerifyLink, CheckAccountVerifyLinkError, CheckAccountVerifyLinkSuccess,
  CheckResetPasswordLink, CheckResetPasswordLinkError, CheckResetPasswordLinkSuccess,
  ForgotPassword, ForgotPasswordError, ForgotPasswordSuccess,
  Login,
  LoginError, LoginSuccess, LoginWithToken,
  Register,
  RegisterError,
  RegisterSuccess, ResetPassword, ResetPasswordError, ResetPasswordSuccess, SendOtp, SendOtpError, SendOtpSuccess,
  SignInWithSocial,
  SignInWithSocialSuccess, ValidateOtp, ValidateOtpError, ValidateOtpSuccess,
  VerifyData, VerifyDataError, VerifyDataSuccess
} from "@kwot/auth-store";
import {catchError, map, of, switchMap} from "rxjs";
import {SocialAuthService} from "angularx-social-login";
import {SocialUser} from "@kwot/data-models";
import {APP_CONFIG, LoaderService, LocalstorageService} from "@kwot/app-config";
import {Router} from "@angular/router";

@Injectable()
export class AuthEffects {
  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private authSocialService: SocialAuthService,
    private loaderService: LoaderService,
    private localStorageService: LocalstorageService,
    private router: Router,
    @Inject(APP_CONFIG) public appConfig: any
  ) {
  }

  register$ = createEffect(() => this.actions$.pipe(
    ofType(Register),
    switchMap((action) => {
      this.loaderService.show();
      return this.authService.register(action.user).pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.status) {
            this.localStorageService.updateRegisterUser('remove');
            const user = {
              ...resp.data,
              id: resp.data._id
            };
            if (user.isDeleted) {
              return LoginSuccess({loginUser: user, message: resp.message});
            }
            return RegisterSuccess({registerUser: resp.data, message: resp.message});
          }
          return RegisterError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide();
          return of(RegisterError({error: this.loaderService.getErrorMessage(error)}));
        })
      )
    })
  ));

  signInWithSocial$ = createEffect(() => this.actions$.pipe(
    ofType(SignInWithSocial),
    switchMap((action) => {
      this.authSocialService.signIn(action.providerString).catch((error => console.log(error)));
      return this.authSocialService.authState.pipe(
        map((resp: SocialUser) => {
          this.loaderService.hide();
          if (resp) {
            return SignInWithSocialSuccess({signInUserSocial: resp});
          }
          if (action.actionType === 'register') {
            return RegisterError({error: this.loaderService.getErrorMessage(resp || {msg: 'Social Error'})});
          } else {
            return LoginError({error: this.loaderService.getErrorMessage(resp || {msg: 'Social Error'})});
          }
        }),
        catchError(error => {
          this.loaderService.hide();
          if (action.actionType === 'register') {
            return of(RegisterError({error: this.loaderService.getErrorMessage(error)}));
          } else {
            return of(LoginError({error: this.loaderService.getErrorMessage(error)}));
          }
        })
      )
    })
  ));

  login$ = createEffect(() => this.actions$.pipe(
    ofType(Login),
    switchMap((action) => {
      this.loaderService.show();
      return this.authService.login(action.user).pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.status) {
            if (this.appConfig.type === 'user') {
              if (resp.data.isGuest) {
                this.router.navigate(['/', 'user', 'register'], {state: {email: action.user.email}});
                return LoginSuccess({loginUser: null, message: resp.message});
              }
            }
            const user = {
              ...resp.data,
              id: resp.data._id
            };
            return LoginSuccess({loginUser: user, message: resp.message});
          }
          return LoginError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide();
          return of(LoginError({error: this.loaderService.getErrorMessage(error)}));
        })
      )
    })
  ));

  forgotPassword$ = createEffect(() => this.actions$.pipe(
    ofType(ForgotPassword),
    switchMap((action) => {
      this.loaderService.show();
      return this.authService.forgotPassword(action.user).pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.status) {
            return ForgotPasswordSuccess();
          }
          return ForgotPasswordError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide();
          return of(ForgotPasswordError({error: this.loaderService.getErrorMessage(error)}));
        })
      )
    })
  ));

  validateOtp$ = createEffect(() => this.actions$.pipe(
    ofType(ValidateOtp),
    switchMap((action) => {
      this.loaderService.show();
      return this.authService.validateOtp(action.otp, action.userType).pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.status) {
            if (!action.ignoreClear) {
              const user = this.localStorageService.updateRegisterUser('get');
              if (user) {
                user.isPhoneVerified = true;
                this.localStorageService.updateRegisterUser('store', user);
              }
              if (action.otp.phone) {
                this.localStorageService.updateRegisterUser('store', resp.data);
              }
              return ValidateOtpSuccess({user, ignoreClear: false});
            } else {
              return ValidateOtpSuccess({user: null, ignoreClear: true});
            }
          }
          return ValidateOtpError({error: this.loaderService.getErrorMessage(resp), ignoreClear: action.ignoreClear});
        }),
        catchError(error => {
          this.loaderService.hide();
          return of(ValidateOtpError({
            error: this.loaderService.getErrorMessage(error),
            ignoreClear: action.ignoreClear
          }));
        })
      )
    })
  ));

  sendOtp$ = createEffect(() => this.actions$.pipe(
    ofType(SendOtp),
    switchMap((action) => {
      this.loaderService.show();
      return this.authService.sendOtp(action.phone, action.countryCode).pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.status) {
            return SendOtpSuccess({success: ''}); // Otp Sent
          }
          return SendOtpError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide();
          return of(SendOtpError({error: this.loaderService.getErrorMessage(error)}));
        })
      )
    })
  ));

  resetPassword$ = createEffect(() => this.actions$.pipe(
    ofType(ResetPassword),
    switchMap((action) => {
      this.loaderService.show();
      return this.authService.resetPassword(action.passwordBody).pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.status) {
            return ResetPasswordSuccess(); // You have successfully changed your password.
          }
          return ResetPasswordError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide();
          return of(ResetPasswordError({error: this.loaderService.getErrorMessage(error)}));
        })
      )
    })
  ));

  checkResetPasswordLink$ = createEffect(() => this.actions$.pipe(
    ofType(CheckResetPasswordLink),
    switchMap((action) => {
      this.loaderService.show();
      return this.authService.checkResetPasswordLink(action.id).pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.status) {
            return CheckResetPasswordLinkSuccess();
          }
          return CheckResetPasswordLinkError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide();
          return of(CheckResetPasswordLinkError({error: this.loaderService.getErrorMessage(error)}));
        })
      )
    })
  ));

  checkAccountVerifyLink$ = createEffect(() => this.actions$.pipe(
    ofType(CheckAccountVerifyLink),
    switchMap((action) => {
      this.loaderService.show();
      return this.authService.checkAccountVerifyLink(action.id).pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.data) {
            this.localStorageService.updateUserKey('store', resp.data);
          }
          if (resp.status) {
            return CheckAccountVerifyLinkSuccess({user: resp.data});
          }
          return CheckAccountVerifyLinkError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide();
          return of(CheckAccountVerifyLinkError({error: this.loaderService.getErrorMessage(error)}));
        })
      )
    })
  ));

  verifyData$ = createEffect(() => this.actions$.pipe(
    ofType(VerifyData),
    switchMap((action) => {
      this.loaderService.show();
      return this.authService.verifyData(action.params).pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.status) {
            return VerifyDataSuccess({success: 'Verification link has been sent to you email.'});
          }
          return VerifyDataError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide();
          return of(VerifyDataError({error: this.loaderService.getErrorMessage(error)}));
        })
      )
    })
  ));
  
  loginWithToken$ = createEffect(() => this.actions$.pipe(
    ofType(LoginWithToken),
    switchMap((action) => {
      this.loaderService.show();
      return this.authService.loginWithToken(action.token).pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.status) {
            const user = {
              ...resp.data,
              id: resp.data._id
            };
            return LoginSuccess({loginUser: user, message: resp.message});
          }
          return LoginError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide();
          return of(LoginError({error: this.loaderService.getErrorMessage(error)}));
        })
      )
    })
  ));

}
