export const ProductsOf: any = [
  // {
  //   name: 'Individuals',
  //   image: 'individuals.png'
  // },
  // {
  //   name: 'Companies',
  //   image: 'coorperatives.png'
  // },
  // {
  //   name: 'Countries',
  //   image: 'countries.png'
  // },
  {
    name: 'States',
    displayName: 'African states',
    image: 'tribes.png'
  },
  {
    name: 'Regions',
    displayName: 'African Regions',
    image: 'region.png',
    regions: [
      {
        name: 'North Central',
        icon: 'https://s3.amazonaws.com/kwot.files/regions/nc.jpeg',
        states: ['BE', 'KO', 'KW', 'NA', 'NI', 'PL', 'FC'],
        slug: 'nc'
      },
      {
        name: 'North West',
        icon: 'https://s3.amazonaws.com/kwot.files/regions/nw.jpeg',
        states: ['JI', 'KD', 'KN', 'KT', 'KE', 'SO', 'ZA'],
        slug: 'nw'
      },
      {
        name: 'North East',
        icon: 'https://s3.amazonaws.com/kwot.files/regions/ne.jpeg',
        states: ['AD', 'BA', 'BO', 'GO', 'TA', 'YO'],
        slug: 'ne'
      },
      {
        name: 'South South', // (Niger Delta region)
        icon: 'https://s3.amazonaws.com/kwot.files/regions/ss.jpeg',
        states: ['AK', 'BY', 'CR', 'DE', 'ED', 'RS'],
        slug: 'ss'
      },
      {
        name: 'South East',
        icon: 'https://s3.amazonaws.com/kwot.files/regions/se.jpeg',
        states: ['AB', 'AN', 'EB', 'EN', 'IM'],
        slug: 'se'
      },
      {
        name: 'South West',
        icon: 'https://s3.amazonaws.com/kwot.files/regions/sw.jpeg',
        states: ['EK', 'LA', 'OG', 'ON', 'OS', 'OY'],
        slug: 'sw'
      }
    ]
  },
  {
    name: 'Vendors',
    displayName: 'African stores',
    image: 'families.png'
  }
]
