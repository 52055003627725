<div class="chatbot-button d-none" (click)="showChatList()">
  <i class="fa-solid fa-comment" *ngIf="!showRoomChats"></i>
  <i class="fa-solid close-icon fa-xmark " *ngIf="showRoomChats"></i>
  <span class="message-total-count" *ngIf="totalMessageCount > 0 && !showRoomChats">
    <h6>{{ totalMessageCount }}</h6>
  </span>
</div>

<!-- chat -->
<div class="chat-box" *ngIf="showRoomChats" [ngClass]="{'chat-bot-login': !currentUser}">
  <div class="chat-box-header d-flex align-items-center justify-content-between">
    <div class="d-flex align-items-center">
      <div class="back-btn cursor-pointer" *ngIf="isShowChatMessage">
        <img src="/assets/images/icons/back-white-icon.svg" alt="back-btn" (click)="redirectRoomChat()">
      </div>
      <h4 class="fs-24 fw-bold mb-0" *ngIf="!isShowChatMessage && currentUser">
        {{ 'your_chats' | translate }}
      </h4>
      <h4 class="fs-24 fw-bold mb-0" *ngIf="!currentUser">
        {{ 'provide_your_information' | translate }}
      </h4>
      <div class="user-details ms-2 d-flex align-items-center" *ngIf="isShowChatMessage">
        <div class="img-wrapper">
          <img class="img-fluid" *ngIf="!selectedRoomData.personId.logo" src="assets/images/logo-small-round.svg" alt="back-btn">
          <img class="img-fluid" *ngIf="selectedRoomData.personId.logo" [src]="selectedRoomData.personId.logo" alt="back-btn">
        </div>
        <div class="user-name" *ngIf="selectedRoomData?.userId?._id === currentUser?._id; else personIdData">
          <h6 class="m-0 fw-bold" *ngIf="!selectedRoomData?.personId?.name; else senderName;">
            {{selectedRoomData?.personId?.firstName + selectedRoomData?.personId?.lastName}}
          </h6>
          <ng-template #senderName>{{selectedRoomData?.personId?.name}}</ng-template>
        </div>
        <ng-template #personIdData>
          <div class="user-name">
            <h6 class="m-0 fw-bold" *ngIf="!selectedRoomData?.userId?.name; else senderName;">
              {{selectedRoomData?.userId?.firstName + selectedRoomData?.userId?.lastName}}
            </h6>
            <ng-template #senderName>{{selectedRoomData?.userId?.name}}</ng-template>
          </div>
        </ng-template>
      </div>
    </div>

    <!--    <div class="close-icon cursor-pointer"  (click)="showChatList()">-->
    <!--      <img class="img-fluid" src="/assets/images/icons/white-cross.svg" alt="back-btn">-->
    <!--    </div>-->
  </div>

  <!-- Room Chats List -->
  <div class="chat-room-message-list" *ngIf="!isShowChatMessage && currentUser">
    <div class="chat-room-list" *ngIf="filteredList.length > 0; else noRoomChat">
      <div class="chat-list-scroll" infiniteScroll [infiniteScrollDistance]="2"
           [infiniteScrollContainer]="'.chat-room-details'"
           [infiniteScrollUpDistance]="1.5" (scrolled)="onScrollRoomList()">
        <div class="chat-room-details" >
          <!--        <div >-->
          <ng-container *ngFor="let roomChat of filteredList">
            <div class="user-chat-list user-details" (click)="selectedRoomChat(roomChat)"
                 *ngIf="roomChat.userId._id === currentUser._id; else personIdData">
              <div class="img-wrapper" >
                <img class="img-fluid" *ngIf="!roomChat.personId.logo" src="assets/images/logo-small-round.svg" alt="back-btn">
                <img class="img-fluid" *ngIf="roomChat.personId.logo" [src]="roomChat.personId.logo" alt="back-btn">
              </div>
              <div class="details">
                <div class="top-details">
                  <div class="name">
                    <h2 class="fs-14 fw-bold mb-0">
                      {{roomChat.personId.name ? roomChat.personId.name : roomChat.personId.firstName + roomChat.personId.lastName}}
                    </h2>
                  </div>
                  <div class="time">
                    <p class="fs-12 mb-0">
                      {{checkDate(roomChat.date)}}
                    </p>
                  </div>
                </div>
                <div class="bottom-details">
                  <div class="message-details">
                    <p class="fs-12 mb-0">
                      {{roomChat.msg}}
                    </p>
                  </div>
                  <div class="message-count">
                    <h6 class="fs-12 mb-0" *ngIf="roomChat.totalMsg > 0">
                      {{roomChat.totalMsg}}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
            <ng-template #personIdData>
              <div class="user-chat-list user-details" (click)="selectedRoomChat(roomChat)">
                <div class="img-wrapper" >
                  <img class="img-fluid" *ngIf="!roomChat.userId.logo" src="assets/images/logo-small-round.svg" alt="back-btn">
                  <img class="img-fluid" *ngIf="roomChat.userId.logo" [src]="roomChat.userId.logo" alt="back-btn">
                </div>
                <div class="details">
                  <div class="top-details">
                    <div class="name">
                      <h2 class="fs-14 fw-bold mb-0">
                        {{roomChat.userId.name ? roomChat.userId.name : roomChat.userId.firstName + roomChat.userId.lastName}}
                      </h2>
                    </div>
                    <div class="time">
                      <p class="fs-12 mb-0">
                        {{checkDate(roomChat.date)}}
                      </p>
                    </div>
                  </div>
                  <div class="bottom-details">
                    <div class="message-details">
                      <p class="fs-12 mb-0">
                        {{roomChat.msg}}
                      </p>
                    </div>
                    <div class="message-count">
                      <h6 class="fs-12 mb-0" *ngIf="roomChat.totalMsg > 0">
                        {{roomChat.totalMsg}}
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </ng-container>
          <div class="text-center" *ngIf="hasMoreList">
            <img src="assets/images/loaders/chat_loader.gif">
          </div>
          <!--        </div>-->
        </div>
      </div>
    </div>
    <ng-template #noRoomChat>
      <!--      <span>{{'you_have_no_chats' | translate}}</span>-->
      <div class="text-center">
        <img src="assets/images/loaders/chat_loader.gif">
      </div>
    </ng-template>
  </div>


<!--  <div class="chat-room-list" >-->
<!--    <div *ngIf="filteredList.length > 0; else noRoomChat">-->
<!--      <ng-container *ngFor="let roomChat of filteredList">-->
<!--        <div class="user-chat-list user-details" (click)="selectedRoomChat(roomChat)"-->
<!--             *ngIf="roomChat.userId._id === currentUser._id; else personIdData">-->
<!--          <div class="img-wrapper" >-->
<!--            <img class="img-fluid" *ngIf="!roomChat.personId.logo" src="assets/images/logo-small-round.svg" alt="back-btn">-->
<!--            <img class="img-fluid" *ngIf="roomChat.personId.logo" [src]="roomChat.personId.logo" alt="back-btn">-->
<!--          </div>-->
<!--          <div class="details">-->
<!--            <div class="top-details">-->
<!--              <div class="name">-->
<!--                <h2 class="fs-14 fw-bold mb-0">-->
<!--                  {{roomChat.personId.name ? roomChat.personId.name : roomChat.personId.firstName + roomChat.personId.lastName}}-->
<!--                </h2>-->
<!--              </div>-->
<!--              <div class="time">-->
<!--                <p class="fs-12 mb-0">-->
<!--                  {{checkDate(roomChat.date)}}-->
<!--                </p>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="bottom-details">-->
<!--              <div class="message-details">-->
<!--                <p class="fs-12 mb-0">-->
<!--                  {{roomChat.msg}}-->
<!--                </p>-->
<!--              </div>-->
<!--              <div class="message-count">-->
<!--                <h6 class="fs-12 mb-0" *ngIf="roomChat.totalMsg > 0">-->
<!--                  {{roomChat.totalMsg}}-->
<!--                </h6>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <ng-template #personIdData>-->
<!--          <div class="user-chat-list user-details" (click)="selectedRoomChat(roomChat)">-->
<!--            <div class="img-wrapper" >-->
<!--              <img class="img-fluid" *ngIf="!roomChat.userId.logo" src="assets/images/logo-small-round.svg" alt="back-btn">-->
<!--              <img class="img-fluid" *ngIf="roomChat.userId.logo" [src]="roomChat.userId.logo" alt="back-btn">-->
<!--            </div>-->
<!--            <div class="details">-->
<!--              <div class="top-details">-->
<!--                <div class="name">-->
<!--                  <h2 class="fs-14 fw-bold mb-0">-->
<!--                    {{roomChat.userId.name ? roomChat.userId.name : roomChat.userId.firstName + roomChat.userId.lastName}}-->
<!--                  </h2>-->
<!--                </div>-->
<!--                <div class="time">-->
<!--                  <p class="fs-12 mb-0">-->
<!--                    {{checkDate(roomChat.date)}}-->
<!--                  </p>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="bottom-details">-->
<!--                <div class="message-details">-->
<!--                  <p class="fs-12 mb-0">-->
<!--                    {{roomChat.msg}}-->
<!--                  </p>-->
<!--                </div>-->
<!--                <div class="message-count">-->
<!--                  <h6 class="fs-12 mb-0" *ngIf="roomChat.totalMsg > 0">-->
<!--                    {{roomChat.totalMsg}}-->
<!--                  </h6>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </ng-template>-->
<!--      </ng-container>-->
<!--    </div>-->
<!--    <ng-template #noRoomChat>-->
<!--&lt;!&ndash;      <span>{{'you_have_no_chats' | translate}}</span>&ndash;&gt;-->
<!--      <div class="text-center">-->
<!--        <img src="assets/images/loaders/chat_loader.gif">-->
<!--      </div>-->
<!--    </ng-template>-->
<!--  </div>-->

  <!-- Guest user modal -->
  <div *ngIf="!currentUser">
    <div class="chat-guest-user-modal">
      <form class="login-form" (ngSubmit)="!form.invalid && submitGuestForm()" novalidate
            [formGroup]="guestUserForm">
        <div class="form-group text-left row">
          <div class="col-12">
            <div class="d-flex justify-content-between">
              <label class="neutral_60 font-weight-bold mb-0 ml-2 ff-dmsans fs-12 ps-2">
                {{ 'first_name' | translate }}
              </label>
              <ng-container *ngIf="(form.firstName.touched || form.firstName.dirty) && form.firstName.errors">
                <p *ngIf="form.firstName.errors.required"
                   class="error_color error-label font-weight-bold mb-0 ml-2 ff-dmsans fs-10 ps-2">
                  {{'errors.is_required' | translate: {field: ('first_name' | translate)} }}
                </p>
              </ng-container>
            </div>
            <input type="text" class="form-control fc-input bg-white"
                   placeholder="{{ 'type_your_name' | translate }}" inputRef formControlName="firstName"/>
          </div>
          <div class="col-12 mt-3">
            <div class="d-flex justify-content-between">
              <label class="neutral_60 font-weight-bold mb-0 ml-2 ff-dmsans fs-12 ps-2">
                {{ 'last_name' | translate }}
              </label>
              <ng-container *ngIf="(form.lastName.touched || form.lastName.dirty) && form.lastName.errors">
                <p *ngIf="form.lastName.errors.required"
                   class="error_color error-label font-weight-bold mb-0 ml-2 ff-dmsans fs-10 ps-2">
                  {{'errors.is_required' | translate: {field: ('last_name' | translate)} }}
                </p>
              </ng-container>
            </div>
            <input
              type="text" class="form-control fc-input bg-white"
              placeholder="{{ 'type_your_surname' | translate }}" inputRef formControlName="lastName"/>
          </div>
        </div>
        <div class="form-group text-left row">
          <div class="col-md-12">
            <div class="d-flex justify-content-between">
              <label class="neutral_60 font-weight-bold mb-0 ml-2 ff-dmsans fs-12 ps-2">
                {{ 'email' | translate }}
              </label>
              <ng-container *ngIf="(form.email.touched || form.email.dirty) && form.email.errors">
                <p *ngIf="form.email.errors.required"
                   class="error_color error-label font-weight-bold mb-0 ml-2 ff-dmsans fs-10 ps-2">
                  {{'errors.is_required' | translate: {field: ('email' | translate)} }}
                </p>
                <p *ngIf="form.email.errors.pattern"
                   class="error_color error-label font-weight-bold mb-0 ml-2 ff-dmsans fs-10 ps-2">
                  {{'errors.enter_valid' | translate: {field: ('email' | translate)} }}
                </p>
              </ng-container>
            </div>
            <input type="email" class="form-control fc-input bg-white" inputRef
                   placeholder="{{ 'type_your_email' | translate }}" formControlName="email"/>
          </div>
        </div>

        <div class="d-flex justify-items-center align-content-center">
          <button
            type="submit"
            class="my-3 btn w-100"
            [ngClass]="{
                        'btn-disabled': guestUserForm.invalid,
                        'btn-action': guestUserForm.valid
                      }"
          >
            {{ 'start_chat' | translate }}
          </button>
        </div>
      </form>
    </div>
  </div>
  <!--  Message List-->
  <div class="user-chat-box pe-0 message-box" *ngIf="isShowChatMessage">
    <div class="message-list">
      <div class="message-scroll" infiniteScroll [infiniteScrollDistance]="2"
           [infiniteScrollContainer]="'.message-details'"
           [infiniteScrollUpDistance]="1.5" (scrolledUp)="onScrollUp()" *ngIf="isMessages; else noChatMessage">
        <div class="message-details d-block w-100" #sectionSubscribeChat>
          <div class="details">
            <div class="mb-2" *ngFor="let dates of _object.keys(chatMessages)">
              <div class="sticky-date">
                <h6 class="fs-12 fw-bold m-0">
                  {{dates}}
                </h6>
              </div>
              <ng-container *ngFor="let chat of chatMessages[dates]">
                <div class="sent-message user-message" *ngIf="chat.id !== currentUser._id">
                  <div class="img-wrapper" *ngIf="!chat.data?.logo && !chat.data?.image">
                    <img class="img-fluid" src="assets/images/logo-small-round.svg" alt=" ">
                  </div>
                  <div class="img-wrapper" *ngIf="chat.data?.image || chat.data?.logo">
                    <img class="img-fluid" [src]="chat.data.image ? chat.data.image : chat.data.logo" alt=" ">
                  </div>
                  <div class="user-message-details">
                    <div class="name">
                      <h6 class="mb-0">
                        {{chat.data.name ? chat.data.name : chat.data.firstName + ' ' + chat.data.lastName}}
                      </h6>
                      <p class="fs-12 m-0">{{chat.data.createdAt | dateFormat: 'h:mm a'}}</p>
                    </div>
                    <div class="user-sent-message">
                      <h6 class="fs-16 m-0">
                        {{chat.message}}
                      </h6>
                    </div>
                  </div>
                </div>
                <div class="replay-message user-message"
                     *ngIf="chat?.id.toString() === currentUser?._id.toString()">
                  <div class="user-message-details">
                    <div class="name">
                      <h6 class="mb-0">
                        {{chat.data.name ? chat.data.name : chat.data.firstName + ' ' + chat.data.lastName}}
                      </h6>
                      <p class="fs-12 m-0">{{chat.data.createdAt | dateFormat: 'h:mm a'}}</p>
                    </div>
                    <div class="user-sent-message">
                      <h6 class="fs-16 m-0">
                        {{chat.message}}
                      </h6>
                    </div>
                  </div>
                  <div class="img-wrapper" *ngIf="!chat.data?.logo && !chat.data?.image">
                    <img class="img-fluid" src="assets/images/logo-small-round.svg" alt="back-btn">
                  </div>
                  <div class="img-wrapper" *ngIf="chat.data?.image || chat.data?.logo">
                    <img class="img-fluid" [src]="chat.data?.image ? chat.data?.image : chat.data?.logo"
                         alt="back-btn">
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>

      <div class="message-block" *ngIf="selectedRoomData?.isBlocked">
            <span
            > {{selectedRoomData?.blockedBy === currentUser?._id ? ('you_have_blocked' | translate) : ('you_are_blocked_by' | translate)}}
              {{selectedRoomData?.userId?._id === currentUser?._id ?
                (selectedRoomData?.personId?.name ? selectedRoomData?.personId?.name : selectedRoomData?.personId?.firstName + selectedRoomData?.personId?.lastName) :
                (selectedRoomData?.userId?.name ? selectedRoomData?.userId?.name : selectedRoomData?.userId?.firstName + selectedRoomData?.userId?.lastName)}}
              <button class="btn btn-action w-auto px-4 ms-2" *ngIf="selectedRoomData?.blockedBy === currentUser?._id"
                      (click)="updateBlockStatus(selectedRoomData?._id, false)">
                {{'unblock' | translate}}
              </button>
            </span>
      </div>

      <form class="chat-input form-group mb-0 " *ngIf="selectedRoomData">
        <div class="message-input p-0 form-group mb-0" *ngIf="!selectedRoomData?.isBlocked && checkData()">
          <div class="position-relative d-flex">
            <input class="form-control" type="text" #chatmessage placeholder="Type here your message"
                   [(ngModel)]="chatMessage" name="chatMessage" (keyup)="sendMessage($event, chatmessage)">
            <button class="btn-action px-4 w-auto ms-2" (click)="submit()" [ngClass]="{'btn-disabled': !chatMessage}">
              <img class="img-fluid" src="/assets/images/icons/send.png"/>
            </button>
          </div>
        </div>
      </form>
    </div>
    <ng-template #noChatMessage>
      <div class="no-chat-message">
       {{'lets_start_chat' | translate}}
      </div>
    </ng-template>
  </div>
</div>

<!--chat help message-->
<div class="bot-help-modal d-none" *ngIf="isChatModal">
  <div class="img-wrapper">
    <img class="img-fluid" src="assets/images/logo-small-round.svg" alt="back-btn">
  </div>
  <div class="bot-details text-center">
    <p>
      {{ 'how_can_we_help_you' | translate }}?
    </p>
  </div>
</div>
