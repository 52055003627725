import {Injectable} from '@angular/core';
import {HttpEventType} from "@angular/common/http";
import {CommonService} from "./common.service";
import {LoaderService} from "@kwot/app-config";

@Injectable({
  providedIn: 'root'
})
export class UploadImageService {

  constructor(
    private commonService: CommonService,
    private loaderService: LoaderService
  ) {
  }

  getSingedUrl(fileName: any, contentType: any, folderPath: any) {
    return new Promise((resolve, reject) => {
      return this.commonService.getAwsSignedUrl(fileName, contentType, folderPath).subscribe((resp: any) => {
        resolve(resp.data);
      }, error => reject(error))
    })
  }

  async uploadImageAndGetUrl({fileName, contentType, type, index, file, folderPath}: any) {
    let data: any = await this.getSingedUrl(fileName, contentType, folderPath);
    return await this.uploadImage(data, index, type, file);
  }

  uploadImage(data: any, index: any, type: string, file: any) {
    return new Promise((resolve, reject) => {
      return this.commonService.uploadFileToSignedUrl(data.url, file).subscribe(resp => {
        if (resp.type === HttpEventType.UploadProgress) {
          const percentDone = (Math.round(100 * resp.loaded / resp.total));
          this.loaderService.updateProgress(percentDone);
        }
        if (resp.type === HttpEventType.Response) {
          resolve(data.url.split("?")[0]);
        }
      }, error => reject(error))
    })
  }

  getResizeUrl({url, width, height, quality, folderPath, type}: any) {
    return new Promise((resolve, reject) => {
      return this.commonService.getResizeImage(url, width, height, quality, folderPath, type).subscribe((resp: any) => {
        resolve(resp.data);
      }, error => reject(error))
    })
  }

  getImages(fileName: any, type: any) {
    if (!type || !fileName) {
      return fileName;
    }
    let [width, height] = this.getSize(type);
    let prefix = '';
    if (fileName.includes('kwot-prod.api')) {
      prefix = 'p-'
      fileName = fileName.replace(`https://s3.eu-west-1.amazonaws.com/kwot-prod.api/`, '');
    } else {
      fileName = fileName.replace(`https://s3.amazonaws.com/kwot.api/`, '');
    }
    return `https://${prefix}image.kwot.com/image/${width}/${height}/${fileName}${prefix ? `?q=1` : ''}`;
  }

  getSize(type: any) {
    if (type === 'banner') {
      // return [1980, 720];
      return [1280, 720];
    }
    if (type === 'small') {
      return [128, 160]
    }
    if (type === 'medium') {
      return [315, 315]
    }
    if (type === 'home') {
      return [272, 272]
    }
    return [300, 300]
  }

}
