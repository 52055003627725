import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NgSelectModule} from "@ng-select/ng-select";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {CustomSelectComponent} from "./custom-select/custom-select.component";

@NgModule({
  imports: [
    CommonModule,
    NgSelectModule,
    FormsModule, ReactiveFormsModule
  ],
  declarations: [CustomSelectComponent],
  exports: [CustomSelectComponent],
})
export class SelectModule {
}
