import {Component, Input} from '@angular/core';

@Component({
  selector: 'kwot-currency',
  templateUrl: './currency.component.html',
  styleUrls: ['./currency.component.scss']
})
export class CurrencyComponent {
  @Input() price: any;
  @Input() tag: any;
  @Input() class: any;
  @Input() field: any;
  @Input() hasField: boolean = true;
  @Input() platformFee: boolean = false;
  @Input() type: any;
  @Input() item: any;
  @Input() isB2B: boolean = false;

  constructor() { }

}
