import {Injectable} from '@angular/core';
import {CommonService, LoaderService, LocalstorageService} from '@kwot/app-config';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {BuyerService} from '../services/buyer.service';
import {catchError, map, of, switchMap} from 'rxjs';
import {
  GetProductById,
  GetProductByIdError,
  GetProductByIdSuccess,
  GetCategories,
  GetCategoriesError,
  GetCategoriesSuccess,
  GetHomeProducts,
  GetHomeProductsSuccess,
  GetHomeProductsError,
  GetAddressById,
  GetAddressByIdSuccess,
  GetAddressByIdError,
  GetCategoryFilter,
  GetCategoryFilterSuccess,
  GetCategoryFilterError,
  GetVendorDetail,
  GetVendorDetailSuccess,
  CreateWishList,
  CreateWishListError,
  CreateWishListSuccess,
  GetVendorDetailError,
  GetWishList,
  GetWishListSuccess,
  GetWishListError,
  DeleteWishList,
  DeleteWishListSuccess,
  DeleteWishListError,
  AddProductWishList,
  AddProductWishListSuccess,
  AddProductWishListError,
  RemoveProductWishList,
  RemoveProductWishListSuccess,
  RemoveProductWishListError,
  EditWishList,
  EditWishListSuccess,
  EditWishListError,
  GetNotifications,
  GetNotificationsSuccess,
  GetNotificationsError,
  ReadNotification,
  ReadNotificationSuccess,
  ReadNotificationError,
  GetProductReviews,
  GetProductReviewsSuccess,
  GetProductReviewsError,
  PostProductReview,
  PostProductReviewSuccess,
  PostProductReviewError,
  GetProducts,
  GetProductsSuccess,
  GetProductsError,
  CheckGuestOrUpdate,
  CheckGuestOrUpdateSuccess,
  CheckGuestOrUpdateError,
  GetSellersList,
  GetSellersListSuccess,
  GetSellersListError,
  GuestBuyerInfo,
  GuestBuyerInfoError,
  GuestBuyerInfoSuccess,
  CancelOrder,
  CancelOrderError,
  CancelOrderSuccess,
  GetPolicyDetail,
  GetPolicyDetailSuccess,
  GetPolicyDetailError,
  AcceptPolicyDetail,
  AcceptPolicyDetailSuccess,
  AcceptPolicyDetailError,
  ContactNow,
  ContactNowSuccess,
  ContactNowError,
  GetBannerImages,
  GetBannerImagesSuccess,
  GetBannerImagesError,
  GetCategoriesResponseSuccess,
  GetCategoriesResponse,
  GetCategoriesResponseError,
  GetAllAddress,
  GetAllAddressSuccess,
  GetAllAddressError,
  AddOrderPaymentDetails,
  AddOrderPaymentDetailsSuccess,
  AddOrderPaymentDetailsError,
  AddAddres,
  AddAddresSuccess,
  AddAddresError,
  UpdateAddress,
  UpdateAddressSuccess,
  UpdateAddressError,
  DeleteAddress,
  DeleteAddressSuccess,
  DeleteAddressError,
  UpdateProfileInfo,
  UpdateProfileInfoError,
  UpdateProfileInfoSuccess,
  ProfileInfo,
  ProfileInfoError,
  ProfileInfoSuccess,
  GetCurrencyData,
  GetCurrencyDataError,
  GetCurrencyDataSuccess,
  GetWishlistProducts,
  GetWishlistProductsSuccess,
  GetWishlistProductsError,
  GetAllGiftCards,
  GetAllGiftCardsSuccess,
  GetAllGiftCardsError,
  CheckGiftCardValidity,
  CheckGiftCardValiditySuccess,
  CheckGiftCardValidityError,
  ValidateGiftPromoCode,
  ValidateGiftPromoCodeSuccess,
  ValidateGiftPromoCodeError,
  AddAffiliate,
  AddAffiliateSuccess,
  AddAffiliateError,
  CheckAssociateStoreIdAvailability,
  CheckAssociateStoreIdAvailabilitySuccess,
  CheckAssociateStoreIdAvailabilityError,
  GetAffiliatesSuccess,
  GetAffiliateError,
  GetAffiliate,
  getAffiliateDashboard,
  getAffiliateDashboardSuccess,
  getAffiliateDashboardError,
  AddAffiliateProduct,
  AddAffiliateProductError,
  AddAffiliateProductSuccess,
  GetAffiliateProducts,
  GetAffiliateProductsSuccess,
  GetAffiliateProductsError,
  EditAffiliate,
  EditAffiliateSuccess,
  EditAffiliateError,
  GetAffiliateWalletBalance,
  GetAffiliateWalletBalanceError,
  GetAffiliateWalletBalanceSuccess,
  GetAffiliateWalletTransaction,
  GetAffiliateWalletTransactionSuccess,
  GetAffiliateWalletTransactionError,
  AddPayoutData,
  AddPayoutDataError,
  AddPayoutDataSuccess,
  AddScheduleData,
  AddScheduleDataError,
  AddScheduleDataSuccess,
  GetPayoutDetail,
  GetPayoutDetailError,
  GetPayoutDetailSuccess,
  SearchAffiliateProducts,
  SearchAffiliateProductsSuccess,
  SearchAffiliateProductsError,
  CreateAdsTransaction,
  CreateAdsTransactionSuccess,
  CreateAdsTransactionError,
  GetSponsoredItems,
  GetSponsoredItemsSuccess,
  GetSponsoredItemsError,
  AddToCartItems,
  AddToCartItemsSuccess,
  AddToCartItemsError,
  GetCart,
  GetCartSuccess,
  GetCartError,
  ValidateOrder,
  ValidateOrderSuccess,
  ValidateOrderError,
  GetSearchProductsError,
  GetSearchProducts,
  GetSearchProductsSuccess,
  UpdateSellerFavorite,
  UpdateSellerFavoriteSuccess,
  UpdateSellerFavoriteError,
  StartNegotiation,
  StartNegotiationSuccess,
  StartNegotiationError,
  GetRfqDynamicKey,
  GetRfqDynamicKeySuccess,
  GetRfqDynamicKeyError,
  AddTempOrder,
  AddTempOrderSuccess,
  AddTempOrderError,
  GetTempOrder,
  GetTempOrderSuccess,
  GetTempOrderError,
  GetIntent,
  GetIntentSuccess,
  GetIntentError,
  CheckChatUser,
  CheckChatUserSuccess,
  CheckChatUserError,
  GetActivePromoCodes,
  GetActivePromoCodesSuccess, GetActivePromoCodesError, ContactToSeller, ContactToSellerError, ContactToSellerSuccess
} from './buyer.actions';
import {LoginSuccess} from "@kwot/auth-store";
import {currenciesWithSymbol} from "@kwot/data-models";

@Injectable()
export class BuyerEffects {
  constructor(
    private buyerService: BuyerService,
    private actions$: Actions,
    private loaderService: LoaderService,
    private localstorageService: LocalstorageService,
    private commonService: CommonService
  ) {
  }

  getCategories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetCategories),
      switchMap((action) => {
        return this.buyerService.getAllCategories(action.params).pipe(
          map((resp: any) => {
            if (resp.status) {
              return GetCategoriesSuccess({categories: resp.data});
            }
            return GetCategoriesError({
              error: this.loaderService.getErrorMessage({
                error: this.loaderService.getErrorMessage(resp),
              }),
            });
          }),
          catchError((error) => {
            return of(
              GetCategoriesError({
                error: this.loaderService.getErrorMessage({
                  error: this.loaderService.getErrorMessage(error),
                }),
              })
            );
          })
        );
      })
    )
  );

  GetBuyerProduct$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetProductById),
      switchMap((action) => {
        this.loaderService.show();
        return this.buyerService.getProductById(action.productName, action.associateStoreId).pipe(
          map((resp: any) => {
            this.loaderService.hide();
            if (resp.status) {
              return GetProductByIdSuccess({product: resp.data});
            }
            return GetProductByIdError({
              error: this.loaderService.getErrorMessage({
                error: this.loaderService.getErrorMessage(resp),
              }),
            });
          }),
          catchError((error) => {
            this.loaderService.hide();
            return of(
              GetProductByIdError({
                error: this.loaderService.getErrorMessage({
                  error: this.loaderService.getErrorMessage(error),
                }),
              })
            );
          })
        );
      })
    )
  );

  GetHomeProducts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetHomeProducts),
      switchMap(() => {
        this.loaderService.show();
        return this.buyerService.getAllHomePageProduct().pipe(
          map((resp: any) => {
            this.loaderService.hide();
            if (resp.status) {
              return GetHomeProductsSuccess({products: resp.data});
            }
            return GetHomeProductsError({
              error: this.loaderService.getErrorMessage({
                error: this.loaderService.getErrorMessage(resp),
              }),
            });
          }),
          catchError((error) => {
            this.loaderService.hide();
            return of(
              GetHomeProductsError({
                error: this.loaderService.getErrorMessage({
                  error: this.loaderService.getErrorMessage(error),
                }),
              })
            );
          })
        );
      })
    )
  );

  getHomeCategories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetCategoriesResponse),
      switchMap((action) => {
        return this.buyerService.getHomeCategoryResponse().pipe(
          map((resp: any) => {
            if (resp.status) {
              return GetCategoriesResponseSuccess({categoryResponse: resp.data});
            }
            return GetCategoriesResponseError({
              error: this.loaderService.getErrorMessage({
                error: this.loaderService.getErrorMessage(resp),
              }),
            });
          }),
          catchError((error) => {
            return of(
              GetCategoriesResponseError({
                error: this.loaderService.getErrorMessage({
                  error: this.loaderService.getErrorMessage(error),
                }),
              })
            );
          })
        );
      })
    )
  );

  getBannerImages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetBannerImages),
      switchMap((action) => {
        return this.buyerService.getBannerImages().pipe(
          map((resp: any) => {
            if (resp.status) {
              return GetBannerImagesSuccess({bannerImage: resp.data});
            }
            return GetBannerImagesError({
              error: this.loaderService.getErrorMessage({
                error: this.loaderService.getErrorMessage(resp),
              }),
            });
          }),
          catchError((error) => {
            return of(
              GetBannerImagesError({
                error: this.loaderService.getErrorMessage({
                  error: this.loaderService.getErrorMessage(error),
                }),
              })
            );
          })
        );
      })
    )
  );

  GetAddressById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetAddressById),
      switchMap((action) => {
        this.loaderService.show();
        return this.buyerService.getAddressById(action.id).pipe(
          map((resp: any) => {
            this.loaderService.hide();
            if (resp.status) {
              return GetAddressByIdSuccess({address: resp.data});
            }
            return GetAddressByIdError({
              error: this.loaderService.getErrorMessage({
                error: this.loaderService.getErrorMessage(resp),
              }),
            });
          }),
          catchError((error) => {
            this.loaderService.hide();
            return of(
              GetAddressByIdError({
                error: this.loaderService.getErrorMessage({
                  error: this.loaderService.getErrorMessage(error),
                }),
              })
            );
          })
        );
      })
    )
  );

  GetCategoryFilter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetCategoryFilter),
      switchMap((action) => {
        this.loaderService.show();
        return this.buyerService.getCategoryById(action.id).pipe(
          map((resp: any) => {
            this.loaderService.hide();
            if (resp.status) {
              return GetCategoryFilterSuccess({categoryFilter: resp.data});
            }
            return GetCategoryFilterError({
              error: this.loaderService.getErrorMessage({
                error: this.loaderService.getErrorMessage(resp),
              }),
            });
          }),
          catchError((error) => {
            this.loaderService.hide();
            return of(
              GetCategoryFilterError({
                error: this.loaderService.getErrorMessage({
                  error: this.loaderService.getErrorMessage(error),
                }),
              })
            );
          })
        );
      })
    )
  );

  GetVendorDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetVendorDetail),
      switchMap((action) => {
        this.loaderService.show();
        return this.buyerService.getVendorDetails(action.params).pipe(
          map((resp: any) => {
            this.loaderService.hide();
            if (resp.status) {
              return GetVendorDetailSuccess({vendorDetail: resp.data});
            }
            return GetVendorDetailError({
              error: this.loaderService.getErrorMessage({
                error: this.loaderService.getErrorMessage(resp),
              }),
            });
          }),
          catchError((error) => {
            this.loaderService.hide();
            return of(
              GetVendorDetailError({
                error: this.loaderService.getErrorMessage({
                  error: this.loaderService.getErrorMessage(error),
                }),
              })
            );
          })
        );
      })
    )
  );

  CreateWishList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CreateWishList),
      switchMap((action) => {
        this.loaderService.show();
        return this.buyerService.createWishList(action.wishListName).pipe(
          map((resp: any) => {
            this.loaderService.hide();
            if (resp.status) {
              return CreateWishListSuccess({wishList: resp.data});
            }
            return CreateWishListError({
              error: this.loaderService.getErrorMessage({
                error: this.loaderService.getErrorMessage(resp),
              }),
            });
          }),
          catchError((error) => {
            this.loaderService.hide();
            return of(
              CreateWishListError({
                error: this.loaderService.getErrorMessage({
                  error: this.loaderService.getErrorMessage(error),
                }),
              })
            );
          })
        );
      })
    )
  );

  GetWishList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetWishList),
      switchMap((action) => {
        this.loaderService.show();
        return this.buyerService.getWishList().pipe(
          map((resp: any) => {
            this.loaderService.hide();
            if (resp.status) {
              return GetWishListSuccess({wishList: resp.data});
            }
            return GetWishListError({
              error: this.loaderService.getErrorMessage({
                error: this.loaderService.getErrorMessage(resp),
              }),
            });
          }),
          catchError((error) => {
            this.loaderService.hide();
            return of(
              GetWishListError({
                error: this.loaderService.getErrorMessage({
                  error: this.loaderService.getErrorMessage(error),
                }),
              })
            );
          })
        );
      })
    )
  );

  AddProductWishList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AddProductWishList),
      switchMap((action) => {
        this.loaderService.show();
        return this.buyerService
          .addProductToWishlist(action.selectedProduct)
          .pipe(
            map((resp: any) => {
              this.loaderService.hide();
              if (resp.status) {
                return AddProductWishListSuccess({success: resp.data});
              }
              return AddProductWishListError({
                error: this.loaderService.getErrorMessage(resp),
              });
            }),
            catchError((error) => {
              this.loaderService.hide();
              return of(
                AddProductWishListError({
                  error: this.loaderService.getErrorMessage(error),
                })
              );
            })
          );
      })
    )
  );

  EditWishList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EditWishList),
      switchMap((action) => {
        this.loaderService.show();
        return this.buyerService
          .editWhistList(action.editWishListName)
          .pipe(
            map((resp: any) => {
              this.loaderService.hide();
              if (resp.status) {
                return EditWishListSuccess({success: resp.data});
              }
              return EditWishListError({
                error: this.loaderService.getErrorMessage(resp),
              });
            }),
            catchError((error) => {
              this.loaderService.hide();
              return of(
                EditWishListError({
                  error: this.loaderService.getErrorMessage(error),
                })
              );
            })
          );
      })
    )
  );

  RemoveProductWishList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RemoveProductWishList),
      switchMap((action) => {
        this.loaderService.show();
        return this.buyerService
          .removeProductFromWishlist(action.selectedProduct)
          .pipe(
            map((resp: any) => {
              this.loaderService.hide();
              if (resp.status) {
                return RemoveProductWishListSuccess({success: resp.data});
              }
              return RemoveProductWishListError({
                error: this.loaderService.getErrorMessage(resp),
              });
            }),
            catchError((error) => {
              this.loaderService.hide();
              return of(
                RemoveProductWishListError({
                  error: this.loaderService.getErrorMessage(error),
                })
              );
            })
          );
      })
    )
  );

  DeleteWishList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteWishList),
      switchMap((action) => {
        this.loaderService.show();
        return this.buyerService.deleteWishlist(action.id).pipe(
          map((resp: any) => {
            this.loaderService.hide();
            if (resp.status) {
              return DeleteWishListSuccess({success: resp.data});
            }
            return DeleteWishListError({
              error: this.loaderService.getErrorMessage({
                error: this.loaderService.getErrorMessage(resp),
              }),
            });
          }),
          catchError((error) => {
            this.loaderService.hide();
            return of(
              DeleteWishListError({
                error: this.loaderService.getErrorMessage({
                  error: this.loaderService.getErrorMessage(error),
                }),
              })
            );
          })
        );
      })
    )
  );

  NotificationsList$ = createEffect(() => this.actions$.pipe(
    ofType(GetNotifications),
    switchMap((action) => {
      this.loaderService.show();
      return this.buyerService.getNotifications(action.params).pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.status) {
            return GetNotificationsSuccess({notifications: resp.data});
          }
          return GetNotificationsError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError((error) => {
          this.loaderService.hide();
          return of(GetNotificationsError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  ReadNotifications$ = createEffect(() => this.actions$.pipe(
    ofType(ReadNotification),
    switchMap((action) => {
      this.loaderService.show();
      return this.buyerService.readNotification(action.notificationId).pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.status) {
            return ReadNotificationSuccess();
          }
          return ReadNotificationError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError((error) => {
          this.loaderService.hide();
          return of(ReadNotificationError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  productReviews$ = createEffect(() => this.actions$.pipe(
    ofType(GetProductReviews),
    switchMap((action) => {
      this.loaderService.show();
      return this.buyerService.getProductReviews(action.params).pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.status) {
            return GetProductReviewsSuccess({reviews: resp.data});
          }
          return GetProductReviewsError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError((error) => {
          this.loaderService.hide();
          return of(GetProductReviewsError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  postProductReviews$ = createEffect(() => this.actions$.pipe(
    ofType(PostProductReview),
    switchMap((action) => {
      this.loaderService.show();
      return this.buyerService.postProductReview(action.body).pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.status) {
            return PostProductReviewSuccess();
          }
          return PostProductReviewError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError((error) => {
          this.loaderService.hide();
          return of(PostProductReviewError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  getProducts$ = createEffect(() => this.actions$.pipe(
    ofType(GetProducts),
    switchMap((action) => {
      this.loaderService.show();
      return this.buyerService.getProducts(action.params).pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.status) {
            return GetProductsSuccess({paginatedProducts: resp.data});
          }
          return GetProductsError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError((error) => {
          this.loaderService.hide();
          return of(GetProductsError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  checkGuestUserCheck$ = createEffect(() => this.actions$.pipe(
    ofType(CheckGuestOrUpdate),
    switchMap((action) => {
      if (action.showLoader) {
        this.loaderService.show();
      }
      return this.buyerService.checkGuestAndUpdate(action.body).pipe(
        map((resp: any) => {
          if (action.showLoader) {
            this.loaderService.hide();
          }
          if (resp.status) {
            let user = {
              ...resp.data,
              id: resp.data._id
            }
            if (user.isCartAction) {
              this.localstorageService.updateGuestUserKey('store', user);
              return CheckGuestOrUpdateSuccess({guestUser: user});
            } else {
              if (user.role === 'GUEST') {
                this.localstorageService.updateGuestUserKey('store', user);
                return CheckGuestOrUpdateSuccess({guestUser: user});
              } else {
                if (action.showLoader) {
                  this.localstorageService.updateUserKey('store', user);
                }
                return LoginSuccess({loginUser: {...user, showPopup: !action.showLoader}, message: ''});
              }
            }
          }
          return CheckGuestOrUpdateError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError((error) => {
          if (action.showLoader) {
            this.loaderService.hide();
          }
          return of(CheckGuestOrUpdateError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  cancelOrder$ = createEffect(() => this.actions$.pipe(
    ofType(CancelOrder),
    switchMap((action) => {
      this.loaderService.show();
      return this.buyerService.cancelOrder(action.body).pipe(
        map((resp: any) => {

          this.loaderService.hide();
          if (resp.status) {
            return CancelOrderSuccess({cancelOrder: resp});
          }
          return CancelOrderError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide();
          return of(CancelOrderError({error: this.loaderService.getErrorMessage(error)}));
        })
      )
    })
  ))

  checkGuestUserInfo$ = createEffect(() => this.actions$.pipe(
    ofType(GuestBuyerInfo),
    switchMap((action) => {
      this.loaderService.show();
      return this.buyerService.getGuestUserDetail(action.body).pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.status) {
            return GuestBuyerInfoSuccess({guestInfo: resp.data});

          } else {
            return GuestBuyerInfoError({error: this.loaderService.getErrorMessage(resp)});
          }

        }),
        catchError((error) => {
          this.loaderService.hide();
          return of(GuestBuyerInfoError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  getVendorsList$ = createEffect(() => this.actions$.pipe(
    ofType(GetSellersList),
    switchMap((action) => {
      this.loaderService.show();
      return this.buyerService.getVendorsList(action.params).pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.status) {
            return GetSellersListSuccess({vendors: resp.data});
          }
          return GetSellersListError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError((error) => {
          this.loaderService.hide();
          return of(GetSellersListError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  getPolicyDetail$ = createEffect(() => this.actions$.pipe(
    ofType(GetPolicyDetail),
    switchMap((action) => {
      return this.buyerService.getPolicyData(action.params).pipe(
        map((resp: any) => {
          if (resp.status) {
            return GetPolicyDetailSuccess({policy: resp.data});
          }
          return GetPolicyDetailError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          return of(GetPolicyDetailError({error: this.loaderService.getErrorMessage(error)}))
        })
      )
    })
  ))

  acceptPolicyDetail$ = createEffect(() => this.actions$.pipe(
    ofType(AcceptPolicyDetail),
    switchMap((action) => {
      return this.buyerService.updatePolicyData(action.params).pipe(
        map((resp: any) => {
          if (resp.status) {
            return AcceptPolicyDetailSuccess();
          }
          return AcceptPolicyDetailError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          return of(AcceptPolicyDetailError({error: this.loaderService.getErrorMessage(error)}))
        })
      )
    })
  ))

  contactNow$ = createEffect(() => this.actions$.pipe(
    ofType(ContactNow),
    switchMap((action) => {
      this.loaderService.show();
      return this.buyerService.contactNow(action.params).pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.status) {
            return ContactNowSuccess();
          }
          return ContactNowError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError((error) => {
          this.loaderService.hide();
          return of(ContactNowError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  GetAllAddress$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetAllAddress),
      switchMap((action) => {
        this.loaderService.show();
        return this.buyerService.getAllAddress().pipe(
          map((resp: any) => {
            this.loaderService.hide();
            if (resp.status) {
              return GetAllAddressSuccess({address: resp.data});
            }
            return GetAllAddressError({
              error: this.loaderService.getErrorMessage({
                error: this.loaderService.getErrorMessage(resp),
              }),
            });
          }),
          catchError((error) => {
            this.loaderService.hide();
            return of(
              GetAllAddressError({
                error: this.loaderService.getErrorMessage({
                  error: this.loaderService.getErrorMessage(error),
                }),
              })
            );
          })
        );
      })
    )
  );

  profileInfo$ = createEffect(() => this.actions$.pipe(
    ofType(ProfileInfo),
    switchMap((action) => {
      this.loaderService.show();
      return this.buyerService.getProfileInfo().pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.status) {
            return ProfileInfoSuccess({userData: resp.data});
          }
          return ProfileInfoError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide();
          return of(ProfileInfoError({error: this.loaderService.getErrorMessage(error)}));
        })
      )
    })
  ))

  updateProfileInfo$ = createEffect(() => this.actions$.pipe(
    ofType(UpdateProfileInfo),
    switchMap((action) => {
      this.loaderService.show();
      return this.buyerService.updateProfileInfo(action.body).pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.status) {
            return UpdateProfileInfoSuccess({profile: resp.data});
          }
          return UpdateProfileInfoError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError((error) => {
          this.loaderService.hide();
          return of(UpdateProfileInfoError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  UpdateAddress$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpdateAddress),
      switchMap((action) => {
        this.loaderService.show();
        return this.buyerService.editAddress(action.address).pipe(
          map((resp: any) => {
            this.loaderService.hide();
            if (resp.status) {
              return UpdateAddressSuccess({address: resp.data});
            }
            return UpdateAddressError({error: this.loaderService.getErrorMessage(resp)});
          }),
          catchError((error) => {
            this.loaderService.hide();
            return of(
              UpdateAddressError({error: this.loaderService.getErrorMessage(error)})
            );
          })
        );
      })
    )
  );

  DeleteAddress$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteAddress),
      switchMap((action) => {
        this.loaderService.show();
        return this.buyerService.deleteAddress(action.id).pipe(
          map((resp: any) => {
            this.loaderService.hide();
            if (resp.status) {
              return DeleteAddressSuccess({id: action.id});
            }
            return DeleteAddressError({
              error: this.loaderService.getErrorMessage({
                error: this.loaderService.getErrorMessage(resp),
              }),
            });
          }),
          catchError((error) => {
            this.loaderService.hide();
            return of(
              DeleteAddressError({
                error: this.loaderService.getErrorMessage({
                  error: this.loaderService.getErrorMessage(error),
                }),
              })
            );
          })
        );
      })
    )
  );

  GetBuyerAddress$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AddAddres),
      switchMap((action) => {
        this.loaderService.show();
        return this.buyerService.addAddress(action.address).pipe(
          map((resp: any) => {
            this.loaderService.hide();
            if (resp.status) {
              return AddAddresSuccess({address: resp.data});
            }
            return AddAddresError({error: this.loaderService.getErrorMessage(resp)});
          }),
          catchError((error) => {
            this.loaderService.hide();
            return of(AddAddresError({error: this.loaderService.getErrorMessage(error)}));
          })
        );
      })
    )
  );

  GetPaymentSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AddOrderPaymentDetails),
      switchMap((action) => {
        this.loaderService.show();
        return this.buyerService.orderPayment(action.paymentDetails).pipe(
          map((resp: any) => {
            this.loaderService.hide();
            if (resp.status) {
              return AddOrderPaymentDetailsSuccess({
                paymentSuccess: resp.data,
              });
            }
            return AddOrderPaymentDetailsError({
              error: this.loaderService.getErrorMessage(resp),
            });
          }),
          catchError((error) => {
            this.loaderService.hide();
            return of(
              AddOrderPaymentDetailsError({
                error: this.loaderService.getErrorMessage(error),
              })
            );
          })
        );
      })
    )
  );

  getCurrencyData$ = createEffect(() => this.actions$.pipe(
    ofType(GetCurrencyData),
    switchMap((action) => {
      this.loaderService.show();
      return this.commonService.getConversationRates().pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.status) {
            const selectedCurrency = this.localstorageService.updateSelectedCurrency('get') ? this.localstorageService.updateSelectedCurrency('get').location.currencyCode : 'USD';
            const data = currenciesWithSymbol.find((item: any) => item.name === selectedCurrency)
            const currencyData = {
              location: {
                countryCode: data.localeCode,
                countryName: data.countryName,
                currencyCode: data.name,
                currencySymbol: data.symbol
              },
              rates: resp.data.rates
            }
            this.localstorageService.updateSelectedCurrency('store', currencyData);
            this.localstorageService.updateGeolocation('store', resp.data.location);
            return GetCurrencyDataSuccess({currencyData: currencyData});
          }
          return GetCurrencyDataError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide();
          return of(GetCurrencyDataError({error: this.loaderService.getErrorMessage(error)}));
        })
      )
    })
  ))

  getWishlistProducts$ = createEffect(() => this.actions$.pipe(
    ofType(GetWishlistProducts),
    switchMap((action) => {
      this.loaderService.show();
      return this.buyerService.updateWishlistProduct(action.items).pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.status) {
            return GetWishlistProductsSuccess({updatedProducts: resp.data});
          }
          return GetWishlistProductsError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide();
          return of(GetWishlistProductsError({error: this.loaderService.getErrorMessage(error)}));
        })
      )
    })
  ))

  getGiftCards$ = createEffect(() => this.actions$.pipe(
    ofType(GetAllGiftCards),
    switchMap((action) => {
      this.loaderService.show();
      return this.buyerService.getAllGiftCards().pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.status) {
            return GetAllGiftCardsSuccess({giftCards: resp.data});
          }
          return GetAllGiftCardsError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide();
          return of(GetAllGiftCardsError({error: this.loaderService.getErrorMessage(error)}));
        })
      )
    })
  ))

  checkGiftCardValidity$ = createEffect(() => this.actions$.pipe(
    ofType(CheckGiftCardValidity),
    switchMap((action) => {
      this.loaderService.show();
      return this.buyerService.checkGiftCardValidity(action.giftCode).pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.status) {
            return CheckGiftCardValiditySuccess({giftCardValidity: resp.data});
          }
          return CheckGiftCardValidityError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide();
          return of(CheckGiftCardValidityError({error: this.loaderService.getErrorMessage(error)}));
        })
      )
    })
  ))

  validateDiscountDetails$ = createEffect(() => this.actions$.pipe(
    ofType(ValidateGiftPromoCode),
    switchMap((action) => {
      this.loaderService.show();
      return this.buyerService.validateDiscountDetails(action.discountCode, action.discountType).pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.status) {
            return ValidateGiftPromoCodeSuccess({discountDetails: resp.data});
          }
          return ValidateGiftPromoCodeError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide();
          return of(ValidateGiftPromoCodeError({error: this.loaderService.getErrorMessage(error)}));
        })
      )
    })
  ))

  AddAffiliate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AddAffiliate),
      switchMap((action) => {
        this.loaderService.show();
        return this.buyerService
          .addAffiliate(action.body)
          .pipe(
            map((resp: any) => {
              this.loaderService.hide();
              if (resp.status) {
                return AddAffiliateSuccess({success: resp.data});
              }
              return AddAffiliateError({
                error: this.loaderService.getErrorMessage(resp),
              });
            }),
            catchError((error) => {
              this.loaderService.hide();
              return of(
                AddProductWishListError({
                  error: this.loaderService.getErrorMessage(error),
                })
              );
            })
          );
      })
    ))

  checkAssociateStoreId$ = createEffect(() => this.actions$.pipe(
    ofType(CheckAssociateStoreIdAvailability),
    switchMap((action) => {
      this.loaderService.show();
      return this.buyerService.checkAssociateStoreId(action.associateStoreId).pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.status) {
            return CheckAssociateStoreIdAvailabilitySuccess({isAssociateStoreIdAvailable: 'available'});
          }
          return CheckAssociateStoreIdAvailabilityError({
            error: this.loaderService.getErrorMessage(resp),
            isAssociateStoreIdAvailable: 'un-available'
          });
        }),
        catchError(error => {
          this.loaderService.hide();
          return of(CheckAssociateStoreIdAvailabilityError({
            error: this.loaderService.getErrorMessage(error),
            isAssociateStoreIdAvailable: 'un-available'
          }))
        })
      )
    })
  ))

  AffiliateList$ = createEffect(() => this.actions$.pipe(
    ofType(GetAffiliate),
    switchMap((action) => {
      this.loaderService.show();
      return this.buyerService.getAllAffiliate().pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.status) {
            return GetAffiliatesSuccess({affiliate: resp.data});
          }
          return GetAffiliateError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError((error) => {
          this.loaderService.hide();
          return of(GetAffiliateError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  AffiliateDashboardList$ = createEffect(() => this.actions$.pipe(
    ofType(getAffiliateDashboard),
    switchMap((action) => {
      this.loaderService.show();
      return this.buyerService.getAffiliateDashboard(action.params).pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.status) {
            return getAffiliateDashboardSuccess({affiliateDashboard: resp.data});
          }
          return getAffiliateDashboardError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError((error) => {
          this.loaderService.hide();
          return of(getAffiliateDashboardError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  AddAffiliateProducts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AddAffiliateProduct),
      switchMap((action) => {
        this.loaderService.show();
        return this.buyerService
          .addAffiliateProducts(action.body)
          .pipe(
            map((resp: any) => {
              this.loaderService.hide();
              if (resp.status) {
                return AddAffiliateProductSuccess({success: resp.data});
              }
              return AddAffiliateProductError({
                error: this.loaderService.getErrorMessage(resp),
              });
            }),
            catchError((error) => {
              this.loaderService.hide();
              return of(
                AddAffiliateProductError({
                  error: this.loaderService.getErrorMessage(error),
                })
              );
            })
          );
      })
    ));

  AffiliateProductList$ = createEffect(() => this.actions$.pipe(
    ofType(GetAffiliateProducts),
    switchMap((action) => {
      this.loaderService.show();
      return this.buyerService.getAffiliateProducts().pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.status) {
            return GetAffiliateProductsSuccess({affiliateProducts: resp.data});
          }
          return GetAffiliateProductsError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError((error) => {
          this.loaderService.hide();
          return of(GetAffiliateProductsError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  EditAffiliate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EditAffiliate),
      switchMap((action) => {
        this.loaderService.show();
        return this.buyerService
          .editAffiliate(action.body)
          .pipe(
            map((resp: any) => {
              this.loaderService.hide();
              if (resp.status) {
                return EditAffiliateSuccess({success: resp.data});
              }
              return EditAffiliateError({
                error: this.loaderService.getErrorMessage(resp),
              });
            }),
            catchError((error) => {
              this.loaderService.hide();
              return of(
                EditAffiliateError({
                  error: this.loaderService.getErrorMessage(error),
                })
              );
            })
          );
      })
    ));
  getWalletBalance$ = createEffect(() => this.actions$.pipe(
    ofType(GetAffiliateWalletBalance),
    switchMap((action) => {
      this.loaderService.show();
      return this.buyerService.getUserWalletBalance().pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.status) {
            return GetAffiliateWalletBalanceSuccess({wallet: resp.data || {data: [], count: {total: 0, page: 0}}});
          }
          // @ts-ignore
          return GetAffiliateWalletBalanceError({error: this.loaderService.getErrorMessage()});
        }),
        catchError(error => {
          this.loaderService.hide();
          return of(GetAffiliateWalletBalanceError({error: this.loaderService.getErrorMessage(error)}));
        })
      )
    })
  ));

  getWalletTransactionBalance$ = createEffect(() => this.actions$.pipe(
    ofType(GetAffiliateWalletTransaction),
    switchMap((action) => {
      this.loaderService.show();
      return this.buyerService.getWalletTransaction(action.params).pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.status) {
            return GetAffiliateWalletTransactionSuccess({
              walletTransaction: resp.data || {
                data: [],
                count: {total: 0, page: 0}
              }
            });
          }
          // @ts-ignore
          return GetAffiliateWalletTransactionError({error: this.loaderService.getErrorMessage()});
        }),
        catchError(error => {
          this.loaderService.hide();
          return of(GetAffiliateWalletTransactionError({error: this.loaderService.getErrorMessage(error)}));
        })
      )
    })
  ))

  addScheduleData$ = createEffect(() => this.actions$.pipe(
    ofType(AddScheduleData),
    switchMap((action) => {
      this.loaderService.show();
      return this.buyerService.addWalletAmount(action.body).pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.status) {
            return AddScheduleDataSuccess({wallet: resp.data});
          }
          return AddScheduleDataError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide();
          return of(AddScheduleDataError({error: this.loaderService.getErrorMessage(error)}))
        })
      )
    })
  ))

  addPayoutData$ = createEffect(() => this.actions$.pipe(
    ofType(AddPayoutData),
    switchMap((action) => {
      this.loaderService.show();
      return this.buyerService.addPayoutData(action.body).pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.status) {
            return AddPayoutDataSuccess({payout: resp.data});
          }
          return AddPayoutDataError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide();
          return of(AddPayoutDataError({error: this.loaderService.getErrorMessage(error)}))
        })
      )
    })
  ))

  getPayoutDetail$ = createEffect(() => this.actions$.pipe(
    ofType(GetPayoutDetail),
    switchMap((action) => {
      this.loaderService.show();
      return this.buyerService.getPayoutDetail().pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.status) {
            return GetPayoutDetailSuccess({payout: resp.data});
          }
          return GetPayoutDetailError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide();
          return of(GetPayoutDetailError({error: this.loaderService.getErrorMessage(error)}))
        })
      )
    })
  ))

  getSearchAffiliateProducts$ = createEffect(() => this.actions$.pipe(
    ofType(SearchAffiliateProducts),
    switchMap((action) => {
      this.loaderService.show();
      return this.buyerService.searchAffiliateProducts(action.params).pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.status) {
            return SearchAffiliateProductsSuccess({affiliateProducts: resp.data});
          }
          return SearchAffiliateProductsError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide();
          return of(SearchAffiliateProductsError({error: this.loaderService.getErrorMessage(error)}))
        })
      )
    })
  ))

  createAdsTransaction$ = createEffect(() => this.actions$.pipe(
    ofType(CreateAdsTransaction),
    switchMap((action) => {
      return this.buyerService.createAdsTransaction(action.body).pipe(
        map((resp: any) => {
          if (resp.status) {
            return CreateAdsTransactionSuccess();
          }
          return CreateAdsTransactionError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          return of(CreateAdsTransactionError({error: this.loaderService.getErrorMessage(error)}))
        })
      )
    })
  ))

  getSponsoredItems$ = createEffect(() => this.actions$.pipe(
    ofType(GetSponsoredItems),
    switchMap((action) => {
      return this.buyerService.getSponsoredItems(action.body).pipe(
        map((resp: any) => {
          if (resp.status) {
            return GetSponsoredItemsSuccess({sponsored: resp.data});
          }
          return GetSponsoredItemsError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          return of(GetSponsoredItemsError({error: this.loaderService.getErrorMessage(error)}))
        })
      )
    })
  ))

  addItemToCart$ = createEffect(() => this.actions$.pipe(
    ofType(AddToCartItems),
    switchMap((action) => {
      this.loaderService.show();
      return this.buyerService.addToCart(action.body).pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.status) {
            return AddToCartItemsSuccess({cart: resp.data});
          }
          return AddToCartItemsError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide();
          return of(AddToCartItemsError({error: this.loaderService.getErrorMessage(error)}))
        })
      )
    })
  ))

  getCart$ = createEffect(() => this.actions$.pipe(
    ofType(GetCart),
    switchMap((action) => {
      this.loaderService.show();
      return this.buyerService.getCart().pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.status) {
            return GetCartSuccess({cart: resp.data});
          }
          return GetCartError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide();
          return of(GetCartError({error: this.loaderService.getErrorMessage(error)}))
        })
      )
    })
  ))

  ValidateOrder$ = createEffect(() =>
    this.actions$.pipe(ofType(ValidateOrder),
      switchMap((action) => {
        this.loaderService.show();
        return this.buyerService.validateOrder(action.items).pipe(
          map((resp: any) => {
            this.loaderService.hide();
            if (resp.status) {
              return ValidateOrderSuccess({orderPlaced: resp.data});
            }
            return ValidateOrderError({
              error: this.loaderService.getErrorMessage({
                error: this.loaderService.getErrorMessage(resp),
              }),
            });
          }),
          catchError((error) => {
            this.loaderService.hide();
            return of(ValidateOrderError({
                error: this.loaderService.getErrorMessage({
                  error: this.loaderService.getErrorMessage(error),
                }),
              })
            );
          })
        );
      })
    )
  );

  GetSearchProducts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetSearchProducts),
      switchMap((action) => {
        this.loaderService.show();
        return this.buyerService.searchProduct(action.item).pipe(
          map((resp: any) => {
            this.loaderService.hide();
            if (resp.status) {
              return GetSearchProductsSuccess({searchData: resp});
            }
            return GetSearchProductsError({error: this.loaderService.getErrorMessage(resp)});
          }),
          catchError((error) => {
            this.loaderService.hide();
            return of(GetSearchProductsError({error: this.loaderService.getErrorMessage(error)}));
          }));
      })
    ));

  UpdateSellerToFavorite$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpdateSellerFavorite),
      switchMap((action) => {
        this.loaderService.show();
        return this.buyerService
          .updateSellerFavourite(action.selectedSeller)
          .pipe(
            map((resp: any) => {
              this.loaderService.hide();
              if (resp.status) {
                return UpdateSellerFavoriteSuccess({success: resp.data});
              }
              return UpdateSellerFavoriteError({
                error: this.loaderService.getErrorMessage(resp),
              });
            }),
            catchError((error) => {
              this.loaderService.hide();
              return of(
                UpdateSellerFavoriteError({
                  error: this.loaderService.getErrorMessage(error),
                })
              );
            })
          );
      })
    )
  );

  startNegotiation = createEffect(() => this.actions$.pipe(
    ofType(StartNegotiation),
    switchMap((action) => {
      this.loaderService.show();
      return this.buyerService.startNegotiation(action.body).pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.status) {
            return StartNegotiationSuccess({negotiation: resp.data});
          }
          return StartNegotiationError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide();
          return of(StartNegotiationError({error: this.loaderService.getErrorMessage(error)}))
        })
      )
    })
  ))


  GetRfqDynamicKeySuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetRfqDynamicKey),
      switchMap((action) => {
        this.loaderService.show();
        return this.buyerService.getRfqDynamicKey(action.item).pipe(
          map((resp: any) => {
            this.loaderService.hide();
            if (resp.status) {
              return GetRfqDynamicKeySuccess({
                rfqDynamicField: resp.data,
              });
            }
            return GetRfqDynamicKeyError({
              error: this.loaderService.getErrorMessage(resp),
            });
          }),
          catchError((error) => {
            this.loaderService.hide();
            return of(
              GetRfqDynamicKeyError({
                error: this.loaderService.getErrorMessage(error),
              })
            );
          })
        );
      })
    )
  );

  addTempOrder = createEffect(() => this.actions$.pipe(
    ofType(AddTempOrder),
    switchMap((action) => {
      this.loaderService.show();
      return this.buyerService.addTempOrder(action.body).pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.status) {
            return AddTempOrderSuccess({tempOrder: resp.data});
          }
          return AddTempOrderError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide();
          return of(AddTempOrderError({error: this.loaderService.getErrorMessage(error)}))
        })
      )
    })
  ))

  GetTempOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetTempOrder),
      switchMap((action) => {
        this.loaderService.show();
        return this.buyerService.getTempOrder(action.orderId).pipe(
          map((resp: any) => {
            this.loaderService.hide();
            if (resp.status) {
              return GetTempOrderSuccess({
                tempOrder: resp.data,
              });
            }
            return GetTempOrderError({
              error: this.loaderService.getErrorMessage(resp),
            });
          }),
          catchError((error) => {
            this.loaderService.hide();
            return of(
              GetTempOrderError({
                error: this.loaderService.getErrorMessage(error),
              })
            );
          })
        );
      })
    )
  );

  GetPaymentIntent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetIntent),
      switchMap((action) => {
        this.loaderService.show();
        return this.buyerService.getPaymentIntent(action.intentId).pipe(
          map((resp: any) => {
            this.loaderService.hide();
            if (resp.status) {
              return GetIntentSuccess({
                paymentIntent: resp.data,
              });
            }
            return GetIntentError({
              error: this.loaderService.getErrorMessage(resp),
            });
          }),
          catchError((error) => {
            this.loaderService.hide();
            return of(
              GetIntentError({
                error: this.loaderService.getErrorMessage(error),
              })
            );
          })
        );
      })
    )
  );

  // live-chat
  checkLiveChatUser$ = createEffect(() => this.actions$.pipe(
    ofType(CheckChatUser),
    switchMap((action) => {
      if (action.showLoader) {
        this.loaderService.show();
      }
      return this.buyerService.checkChatUser(action.body).pipe(
        map((resp: any) => {
          if (action.showLoader) {
            this.loaderService.hide();
          }
          if (resp.status) {
            let user = {
              ...resp.data,
              id: resp.data._id
            }
            if (user.role === 'GUEST') {
              this.localstorageService.updateGuestUserKey('store', user);
              return CheckChatUserSuccess({chatUser: user});
            } else {
              if (action.showLoader) {
                this.localstorageService.updateUserKey('store', user);
                this.localstorageService.updateChatUserKey('store', user);
              }
              return LoginSuccess({loginUser: {...user, showPopup: !action.showLoader}, message: ''});
            }
          }
          return CheckChatUserError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError((error) => {
          if (action.showLoader) {
            this.loaderService.hide();
          }
          return of(CheckChatUserError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

  getActivePromoCodes$ = createEffect(() => this.actions$.pipe(
    ofType(GetActivePromoCodes),
    switchMap((action) => {
      this.loaderService.show();
      return this.buyerService.getActivePromoCodes(action.params).pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.status) {
            return GetActivePromoCodesSuccess({promoCodes: resp.data});
          }
          return GetActivePromoCodesError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide();
          return of(GetActivePromoCodesError({error: this.loaderService.getErrorMessage(error)}))
        })
      )
    })
  ))

  contactToSeller$ = createEffect(() => this.actions$.pipe(
    ofType(ContactToSeller),
    switchMap((action) => {
      this.loaderService.show();
      return this.buyerService.contactToVendor(action.params).pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.status) {
            return ContactToSellerSuccess();
          }
          return ContactToSellerError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError((error) => {
          this.loaderService.hide();
          return of(ContactToSellerError({error: this.loaderService.getErrorMessage(error)}));
        })
      );
    })
  ));

}
