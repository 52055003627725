import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {currenciesWithSymbol} from "@kwot/data-models";

@Component({
  selector: 'kwot-product-price',
  templateUrl: './product-price.component.html',
  styleUrls: ['./product-price.component.scss']
})
export class ProductPriceComponent implements OnInit, OnChanges {
  @Input() price: any;
  @Input() tag: any;
  @Input() class: any;
  @Input() field: any;
  @Input() hasField: boolean = true;
  @Input() platformFee: boolean = false;
  @Input() type: any;
  @Input() item: any;
  @Input() isB2B: boolean = false;
  @Input() showDiscount: boolean = false;
  @Input() shoWishlist: boolean = false;
  @Input() isTotal: boolean = false;
  @Input() isB2BCheckout: boolean = false;
  @Output() updatePrice = new EventEmitter()
  discountPrice: any;
  currencySymbol: any;
  discount: any = 0;

  constructor() {
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.price) {
      let price = this.price || 0;
      if (this.hasField) {
        if (this.type === 'cart') {
          price = this.price.updatedPrice || this.price.price || 0;
        } else {
          price = this.price[this.field] || 0;
        }

      }

      if (this.isB2BCheckout) {
        price = this.price.price / this.price.quantity;
      }
      let item = this.hasField ? this.price : this.item
      if (item?.discountProduct) {
        if (item?.discountProduct?.discountType === 'percentage') {
          this.discountPrice = Number(price - Number((price * (item?.discountProduct?.discountValue || 0)) / 100))
        } else {
          this.discountPrice = Number(price - Number(item?.discountProduct?.discountValue || 0))
        }
      }
      if (item?.productDiscount) {
        if (item?.productDiscount?.discountType === 'percentage') {
          this.discount = Number((price * (item?.productDiscount?.discountValue || 0)) / 100);
          this.discountPrice = Number(price - this.discount)
        } else {
          this.discountPrice = Number(price - Number(item?.productDiscount?.discountValue || 0))
        }
      }
      this.currencySymbol = currenciesWithSymbol.find(t => t.name === item?.currency)?.symbol;
      this.updatePrice.emit(this.discountPrice || price);
    }
  }
}
