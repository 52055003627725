export const allowedCurrency = ['NGN', 'EUR', 'GBP', 'USD', 'ZAR', 'BWP', 'KES', 'RWF', 'XOF', 'MAD', 'EGP', 'CNY'];

export const currenciesWithSymbol = [
  {name: 'USD', symbol: '$', localeCode: 'US', countryName: 'United States'},
  {name: 'GBP', symbol: '£', localeCode: 'GB', countryName: 'United Kingdom'},
  {name: 'EUR', symbol: '€', localeCode: 'EU', countryName: 'Austria'},
  {name: 'NGN', symbol: '₦', localeCode: 'NG', countryName: 'Nigeria'},
  {name: 'ZAR', symbol: 'R', localeCode: 'ZA', countryName: 'South African Rand'},
  {name: 'BWP', symbol: 'P', localeCode: 'BW', countryName: 'Botswana Pula'},
  {name: 'KES', symbol: 'KSh', localeCode: 'KE', countryName: 'Kenya Shilling'},
  {name: 'RWF', symbol: 'R₣', localeCode: 'RW', countryName: 'Rwandan Franc'},
  {name: 'XOF', symbol: 'F.CFA', localeCode: 'CF', countryName: 'West African CFA'},
  {name: 'EGP', symbol: 'E£', localeCode: 'EG', countryName: 'Egyptian pounds'},
  {name: 'MAD', symbol: 'DH', localeCode: 'MA', countryName: 'Moroccan Dirham'},
  {name: 'CNY', symbol: 'Ұ', localeCode: 'ZH', countryName: 'Chinese Yuan'},
];
