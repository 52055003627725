import {Component, EventEmitter, OnInit} from '@angular/core';
import {BsModalRef} from "ngx-bootstrap/modal";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'kwot-custom-modal',
  templateUrl: './custom-modal.component.html',
  styleUrls: ['./custom-modal.component.scss']
})
export class CustomModalComponent implements OnInit {

  title: string;
  headerTitle: string;
  headerSubTitle: string;
  headerNote: string;
  showImage: boolean = true;
  image: string = '/assets/images/confirm-background.png';
  confirmText: string;
  closeText: string;
  customClass: string;
  emitOnClose: boolean = false;
  showButtons: boolean = true;
  vendorDetail: any;
  products: any;

  public closeEvent: EventEmitter<any> = new EventEmitter();

  constructor(
    public bsModalRef: BsModalRef,
    private translateService: TranslateService
  ) {
    this.title = this.translateService.instant('confirmation');
    this.confirmText = this.translateService.instant('confirm');
    this.closeText = this.translateService.instant('cancel');
  }

  ngOnInit(): void {
  }

  submitForm(event = true) {
    this.closeEvent.emit(event);
    this.bsModalRef.hide();
  }

}
