import {Pipe, PipeTransform} from '@angular/core';
import * as dayjs from "dayjs";
import * as customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);

@Pipe({
  name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {

  transform(input: string | any, format: string): string {
    if (!input) return '';
    return dayjs(input, 'YYYY-MM-DD[T]HH[:]mm[:]ss[GMT]Z').format(format);
  }
}
