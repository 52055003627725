import {Directive, HostListener, Self} from '@angular/core';
import {ControlContainer, UntypedFormArray, UntypedFormControl, UntypedFormGroup} from "@angular/forms";

@Directive({
  selector: 'form[formGroup]'
})
export class FormTouchedDirective {

  @HostListener('submit')
  public onSubmit(): void {
    this.markGroupDirty(this.container.control as UntypedFormGroup)
  }

  markGroupDirty(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(key => {
      formGroup.get(key).markAsDirty();
      formGroup.get(key).markAsTouched();

      switch (formGroup.get(key).constructor.name) {
        case "FormGroup":
          this.markGroupDirty(formGroup.get(key) as UntypedFormGroup);
          break;
        case "FormArray":
          this.markArrayDirty(formGroup.get(key) as UntypedFormArray);
          break;
        case "FormControl":
          this.markControlDirty(formGroup.get(key) as UntypedFormControl);
          break;
      }
    });
  }

  markArrayDirty(formArray: UntypedFormArray) {
    formArray.controls.forEach(control => {
      switch (control.constructor.name) {
        case "FormGroup":
          this.markGroupDirty(control as UntypedFormGroup);
          break;
        case "FormArray":
          this.markArrayDirty(control as UntypedFormArray);
          break;
        case "FormControl":
          this.markControlDirty(control as UntypedFormControl);
          break;
      }
    });
  }

  markControlDirty(formControl: UntypedFormControl) {
    formControl.markAsDirty();
  }

  constructor(
    @Self() private container: ControlContainer
  ) {
  }

}
