import {
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {APP_CONFIG, LocalstorageService, SocketService, WindowService} from "@kwot/app-config";
import {Subject, takeUntil} from "rxjs";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {select, Store} from "@ngrx/store";
import {AuthState, getLoggedInUser} from "@kwot/auth-store";
import {AutoReplayState} from "../../../../../messages/src/lib/+state/messages.reducer";
import * as dayjs from "dayjs";
import {AddAutoReplay} from "../../../../../messages/src/lib/+state/messages.actions";
import {BuyerState, CheckChatUser, getChatUserCheck} from "@kwot/buyer-store";

@Component({
  selector: 'kwot-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit, OnDestroy {
  currentUser: any;
  public socketSubscription: any;
  chatMessage: any = '';
  chatMessages: any = {};
  roomChatsList: any = [];
  filteredList: any = [];
  selectedRoomData: any;
  unsubscriber = new Subject();
  isMobile: boolean = false;
  isRoomView = true;
  searchValue: any = '';
  timer: any;
  chatPage = 0;
  listPage = 0;
  hasMore = false;
  hasMoreList = false;
  _object = Object
  modalRef?: BsModalRef | null;
  showRoomChats : boolean = false;
  isMessages: boolean = false;
  guestUserForm: UntypedFormGroup;
  guestUserTemplate: any;
  roomListLimit = 10;

  @ViewChild('sectionSubscribeChat', {static: false}) sectionSubscribeChatDiv: ElementRef;

  actions: any = [
    {
      name: 'New Message',
      id: 'NEW_MESSAGE'
    },
    {
      name: 'Block',
      id: 'BLOCK'
    },
    {
      name: 'Cart Action',
      id: 'CART_ACTION'
    }
  ];
  autoResponseForm: UntypedFormGroup;
  isShowChatMessage: boolean = false;
  chatArr: any[] = [];
  totalMessageCount = 0;
  isSubmitGuestForm: boolean = false;
  isChatModal: boolean = true;
  isScrolledList: any = false;

  constructor(
    private authStore: Store<AuthState>,
    private autoReplayStore: Store<AutoReplayState>,
    private readonly socketService: SocketService,
    private windowService: WindowService,
    @Inject(APP_CONFIG) public appConfig: any,
    private modelService: BsModalService,
    private formBuilder: UntypedFormBuilder,
    private buyerStore: Store<BuyerState>,
    private localStorageService: LocalstorageService
  ) {
    this.subscribeToStore();
    this.socketService.resetSocketData();
    this.windowService.onResize$.subscribe((data) => {
      this.isMobile = data.width <= 767;
    });
    this.autoResponseForm = this.formBuilder.group({
      action: [null, [Validators.required]],
      message: ['', [Validators.required]]
    })
    this.guestUserForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.pattern(RegExp(/^[a-zA-z0-9._%+-]+@[a-zA-z0-9.-]+\.[a-zA-z]{2,4}$/))]]
    })
  }

  subscribeToStore() {
    this.authStore
      .pipe(select(getLoggedInUser))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((currentUser) => {
        if (currentUser) {
          this.currentUser = currentUser;

          // Room list action call for register user
          this.callRoomListAction();
        }
      });

    if (this.appConfig.type !== 'vendor' && this.appConfig.type !== 'admin') {
      this.buyerStore.pipe(select(getChatUserCheck))
        .pipe(takeUntil(this.unsubscriber))
        .subscribe(guestUser => {
          if (guestUser) {
            this.currentUser = guestUser;
            if (this.isSubmitGuestForm && guestUser) {
              this.isSubmitGuestForm = false;
              if (this.currentUser.role === 'GUEST') {
                this.localStorageService.updateGuestUserKey('store', this.currentUser);
              } else {
                this.localStorageService.updateChatUserKey('store', this.currentUser);
              }

              this.showRoomChats = true;

              // Room list action call for guest user
              this.callRoomListAction();
            }
          }
        })
    }
  }

  callRoomListAction() {
      this.getRoomChats();

      setTimeout(() => {
        this.getRoomChats();
      }, 1000)
  }

  onSearch(event: any) {
    this.searchValue = event;
    if (this.timer) {
      clearInterval(this.timer)
    }
    this.timer = setTimeout(() => {
      this.filteredList = this.roomChatsList.filter((t: any) => {
        if (this.currentUser._id === t.userId._id) {
          let name = t.personId?.name || (t.personId?.firstName + ' ' + t.personId?.lastName);
          return name.toLowerCase().includes(this.searchValue.toLowerCase());
        }
        if (this.currentUser._id === t.personId._id) {
          let name = t.userId?.name || (t.userId?.firstName + ' ' + t.userId?.lastName);
          return name.toLowerCase().includes(this.searchValue.toLowerCase());
        }
      })
    }, 500);
  }

  subscribeToSocket() {
    this.socketSubscription = this.socketService.sourceData.subscribe(
      (message: any) => {
        if (message?.action === 'ROOM_LIST') {

          if ( this.listPage > 0 ){
            this.roomChatsList = [...this.roomChatsList, ...message.data];
          } else {
            this.roomChatsList = message.data;
          }

          let roomIds: string[] = [];
          let uniqueRoomList: any[] = [];

          this.roomChatsList.forEach((room: any) => {
            if (!roomIds.includes(room._id)) {
              uniqueRoomList.push(room);
              roomIds.push(room._id);
            } else {
              uniqueRoomList = uniqueRoomList.map((item: any) => {
                if (item._id === room._id) {
                  item.totalMsg = room.totalMsg;
                  item.date = room.date;
                  item.msg = room.msg;
                }
                return item;
              })
            }
          });

          this.roomChatsList = uniqueRoomList;

          this.filteredList = this.roomChatsList;

          this.hasMoreList = message.data.length >= 10;
          this.totalMessageCount = 0;
          this.filteredList.filter((item: any) => item.totalMsg > 0 ? this.totalMessageCount += item.totalMsg : 0);

          // Update list last message date wise
          this.filteredList = this.roomChatsList.sort((a: any, b: any) => {
            const dateA = dayjs(a.date);
            const dateB = dayjs(b.date);

            if (dateA.isSame(dateB)) {
              return 0;
            } else if (dateA.isAfter(dateB)) {
              return 1;
            } else {
              return -1;
            }
          }).reverse();

        }

        if (message?.action === 'BLOCK') {
          if (this.selectedRoomData) {
            if (this.selectedRoomData._id === message.data._id) {
              this.selectedRoomData = message.data;
            }
          } else {
            this.selectedRoomData = message.data;
          }
        }

        if (message?.action === 'CHAT_MESSAGES') {
          let messageList = []
          this.isMessages = message.data.length > 0;
          this.hasMore = message.data.length >= 20;
          if (message.data.length > 0) {
            messageList = message.data.map((item: any) => ({
              id: item.sender._id ? item.sender._id : item.receiver._id,
              data: item.sender ? item.sender : item.receiver,
              message: item.message,
              status: item.status,
              date: item.createdAt
            }))
            messageList?.forEach((item: any) => {
              let formattedDate;
              if (dayjs(item.date).format('DD/MM/YYYY') === dayjs().format('DD/MM/YYYY')) {
                formattedDate = 'Today';
              } else if (dayjs(item.date).format('DD/MM/YYYY') === (dayjs().subtract(1, 'day').format('DD/MM/YYYY'))) {
                formattedDate = 'Yesterday';
              } else if (dayjs().startOf('week').format('DD/MM/YYYY') <= dayjs(item.date).format('DD/MM/YYYY') && dayjs().format('DD/MM/YYYY') >= dayjs(item.date).format('DD/MM/YYYY')) {
                formattedDate = dayjs(item.date).format('dddd');
              } else {
                formattedDate = dayjs(item.date).format('DD/MM/YYYY');
              }
              if (!this.chatMessages[formattedDate]) {
                this.chatMessages[formattedDate] = [];
              }
              if (this.chatPage === 0) {
                this.chatMessages[formattedDate].push(item);
              } else {
                this.chatMessages[formattedDate].unshift(item);
              }
            })

            if (this.chatPage === 0) {
              setTimeout(() => {
                if (this.sectionSubscribeChatDiv) {
                  this.sectionSubscribeChatDiv.nativeElement.scrollTop = this.sectionSubscribeChatDiv.nativeElement.scrollHeight;
                }
              }, 500);
            }
            const updateMessage = {
              action: "MARK_AS_SEEN",
              payload: {
                room: this.selectedRoomData?._id,
                userId: this.currentUser._id,
                status: 'seen'
              }
            };
            let roomIndex = this.roomChatsList.findIndex((t: any) => t._id === this.selectedRoomData?._id);
            if (roomIndex !== -1) {
              this.roomChatsList[roomIndex] = {
                ...this.roomChatsList[roomIndex],
                totalMsg: 0
              }
            }
            this.socketService.sendMessage(updateMessage)
          }
        }

        if (message?.action === 'NEW_MESSAGE') {

          // Call room action from start
          const messageRoom = {
            action: "ROOM_LIST",
            payload: {
              userId: this.currentUser._id,
              pagination: {
                page: 0,
                limit: this.roomListLimit
              }
            }
          };
          this.socketService.sendMessage(messageRoom)

          if (message?.data?.room === this.selectedRoomData?._id) {
            if (message?.data?.message) {
              if (!this.chatMessages['Today']) {
                this.chatMessages['Today'] = [];
              }
              this.chatMessages['Today'].push({
                id: message.data.sender,
                data: (message.data.sender === this.selectedRoomData.userId._id) ? this.selectedRoomData.userId : this.selectedRoomData.personId,
                message: message.data.message,
                status: message.data.status
              });
            }
            const updateMessage = {
              action: "MARK_AS_SEEN",
              payload: {
                room: message?.data?.room,
                userId: this.currentUser._id,
                status: 'seen'
              }
            };
            this.socketService.sendMessage(updateMessage)
            setTimeout(() => {
              this.sectionSubscribeChatDiv.nativeElement.scrollTop = this.sectionSubscribeChatDiv.nativeElement.scrollHeight;
            }, 500);
          }
        }
      },
      (err) => console.error(err),
      () => console.warn('Completed!')
    );
  }

  sendMessage(event: any, input: any) {
    if (event.keyCode === 13) {
      this.submit();
    } else {
      input.style.height = `${input.scrollHeight}px`;
    }
  }

  ngOnInit(): void {
    // For check - When user start chat as guests and login after logout and start chat as guest user
    if (!this.localStorageService.updateUserKey('get') && !this.localStorageService.updateGuestUserKey('get') && !this.localStorageService.updateChatUserKey('get')) {
      this.currentUser = null;
    }

    this.subscribeToSocket();
    this.isMobile = this.windowService.getScreenSize() <= 767;
  }

  submitForm() {
    if (this.autoResponseForm.invalid) {
      return;
    }
    const body = {
      action: this.autoResponseForm.value.action,
      message: this.autoResponseForm.value.message
    }
    this.autoReplayStore.dispatch(AddAutoReplay({body}));
    this.autoResponseForm.reset();

    this.modalRef.hide();
  }

  submit() {
    if (!this.chatMessage.trim()) {
      return;
    }
    const message = {
      action: "NEW_MESSAGE",
      payload: {
        room: this.selectedRoomData._id,
        sender: (this.currentUser._id !== this.selectedRoomData.userId._id) ? this.selectedRoomData.personId._id : this.selectedRoomData.userId._id,
        receiver: (this.currentUser._id !== this.selectedRoomData.userId._id) ? this.selectedRoomData.userId._id : this.selectedRoomData.personId._id,
        message: this.chatMessage,
        status: 'sent'
      }
    };
    this.socketService.sendMessage(message);
    if (!this.chatMessages['Today']) {
      this.chatMessages['Today'] = [];
    }
    this.chatMessages['Today'].push({
      id: (this.currentUser._id !== this.selectedRoomData.userId._id) ? this.selectedRoomData.personId._id : this.selectedRoomData.userId._id,
      data: (this.currentUser._id !== this.selectedRoomData.userId._id) ? this.selectedRoomData.personId : this.selectedRoomData.userId,
      message: this.chatMessage,
      status: 'sent'
    });
    let index = this.filteredList.findIndex((t: any) => t._id === this.selectedRoomData._id);
    if (index !== -1) {
      this.filteredList[index].msg = this.chatMessage;
      this.filteredList[index].date = new Date();
    }
    index = this.roomChatsList.findIndex((t: any) => t._id === this.selectedRoomData._id);
    if (index !== -1) {
      this.roomChatsList[index].msg = this.chatMessage;
      this.roomChatsList[index].date = new Date();
    }
    setTimeout(() => {
      this.sectionSubscribeChatDiv.nativeElement.scrollTop = this.sectionSubscribeChatDiv.nativeElement.scrollHeight;
    }, 500);
    this.chatMessage = '';
  }

  getRoomChats(search = '') {
    if (this.currentUser) {
      const message = {
        action: "ROOM_LIST",
        payload: {
          userId: this.currentUser._id,
          search,
          pagination: {
            page: this.listPage,
            limit: this.roomListLimit
          }
        }
      };
      this.socketService.sendMessage(message)
    }
  }

  selectedRoomChat(roomChat: any) {
    this.isShowChatMessage = true;
    this.chatMessages = {};
    this.chatPage = 0;
    this.selectedRoomData = roomChat;

    const message = {
      action: "CHAT_MESSAGES",
      payload: {
        room: roomChat?._id,
        userId: this.currentUser._id,
        status: 'seen',
        pagination: {
          page: this.chatPage,
          limit: 20
        }
      },
    };
    this.socketService.sendMessage(message);
    this.isRoomView = false;
  }

  redirectRoomChat() {
    if (this.isShowChatMessage) {
      this.chatMessages = {};
      this.isShowChatMessage = false;
      this.selectedRoomData = {};
      // this.listPage = 0;
      this.getRoomChats();

      // Update list last message date wise
      this.filteredList = this.roomChatsList.sort((a: any, b: any) => {
        const dateA = dayjs(a.date);
        const dateB = dayjs(b.date);

        if (dateA.isSame(dateB)) {
          return 0;
        } else if (dateA.isAfter(dateB)) {
          return 1;
        } else {
          return -1;
        }
      }).reverse();
    }
  }

  ngOnDestroy() {
    this.unsubscriber.next(true);
    this.unsubscriber.complete();
    this.socketSubscription.unsubscribe();
  }

  checkDate(date: any) {
    if (dayjs(date).format('DD/MM/YYYY') === dayjs().format('DD/MM/YYYY')) {
      return dayjs(date).format('h:mm a');
    } else if (dayjs(date).format('DD/MM/YYYY') === (dayjs().subtract(1, 'day').format('DD/MM/YYYY'))) {
      return 'Yesterday';
    } else if (dayjs().startOf('week').format('DD/MM/YYYY') <= dayjs(date).format('DD/MM/YYYY') && dayjs().format('DD/MM/YYYY') >= dayjs(date).format('DD/MM/YYYY') && dayjs().startOf('month').format('MM/YYYY') <= dayjs(date).format('MM/YYYY')  ) {
      return dayjs(date).format('dddd');
    }
    return dayjs(date).format('DD/MM/YYYY');
  }

  onScrollUp() {
    setTimeout(() => {
      this.chatPage += 1;
      if (this.hasMore) {
        const message = {
          action: "CHAT_MESSAGES",
          payload: {
            room: this.selectedRoomData?._id,
            userId: this.currentUser._id,
            status: 'seen',
            pagination: {
              page: this.chatPage,
              limit: 20
            }
          },
        };
        this.socketService.sendMessage(message)
      }
    }, 1000)
  }

  onScrollRoomList() {
    setTimeout(() => {
      this.listPage += 1;
      this.isScrolledList = true;
      if (this.hasMoreList) {
        // this.getRoomChats()
        const message = {
          action: "ROOM_LIST",
          payload: {
            userId: this.currentUser._id,
            pagination: {
              page: this.listPage,
              limit: this.roomListLimit
            }
          }
        };
        this.socketService.sendMessage(message)
      }
    }, 1000)
  }

  updateBlockStatus(id: any, blockStatus: boolean) {
    const message = {
      action: "BLOCK",
      payload: {
        room: id,
        userId: this.currentUser._id,
        isBlocked: blockStatus
      },
    };
    this.selectedRoomData = '';
    this.socketService.sendMessage(message)
    // this.getRoomChats();
  }

  redirectToPrev() {
    history.back()
  }

  openModel(template: any, type: any) {
    this.modalRef = this.modelService.show(template, {
      keyboard: true,
      animated: true,
      ignoreBackdropClick: false,
      class: 'modal-md modal-dialog-centered custom-model shipping-cost-model'
    })
  }

  checkData() {
    if (this.appConfig.type === 'admin') {
      if (this.currentUser.role !== 'MANAGEMENT' && this.currentUser.role !== 'IT') {
        return this.currentUser.permissions.some((item: any) => item.menu === 'messages' && item.permissionType.some((per: any) => per === 'write'));
      } else {
        return this.currentUser.role === 'MANAGEMENT' || this.currentUser.role === 'IT';
      }
    } else {
      return true;
    }
  }

  showChatList() {
    this.isChatModal = false;
    this.showRoomChats = !this.showRoomChats;
  }

  get form() {
    return this.guestUserForm.controls;
  }

  submitGuestForm() {
    if (this.guestUserForm.invalid) {
      return;
    }
    this.checkEmail();
    this.isSubmitGuestForm = true;
  }

  hasError() {
    return !!(this.guestUserForm.controls['email'].errors || this.guestUserForm.controls['firstName'].errors || this.guestUserForm.controls['lastName'].errors);
  }

  checkEmail() {
    if (!this.currentUser) {
      if (!this.hasError() && this.guestUserForm.value.email && this.guestUserForm.value.firstName && this.guestUserForm.value.lastName) {
        this.buyerStore.dispatch(CheckChatUser({
            body: {
              email: this.guestUserForm.value.email,
              firstName: this.guestUserForm.value.firstName,
              lastName: this.guestUserForm.value.lastName
            },
            showLoader: true
          })
        );
      }
    }
  }
}
