import {createAction, props} from "@ngrx/store";
import {Messages} from "@kwot/data-models";

const MessagesActions = {

  ADD_REPLAY: '[AUTO_REPLAY] Set Auto Replay',
  ADD_REPLAY_SUCCESS: '[AUTO_REPLAY] Set Auto Replay Success',
  ADD_REPLAY_ERROR: '[AUTO_REPLAY] Set Auto Replay Error',

  GET_REPLAY: '[AUTO_REPLAY] Get Auto Replay',
  GET_REPLAY_SUCCESS: '[AUTO_REPLAY] Get Auto Replay Success',
  GET_REPLAY_ERROR: '[AUTO_REPLAY] Get Auto Replay Error',
  RESET_REPLAY_STATE: '[AUTO_REPLAY] Reset Set Auto Replay State'
}

export const AddAutoReplay = createAction(MessagesActions.ADD_REPLAY, props<{ body: any }>());
export const AddAutoReplaySuccess = createAction(MessagesActions.ADD_REPLAY_SUCCESS, props<{ messages: Messages }>());
export const AddAutoReplayError = createAction(MessagesActions.ADD_REPLAY_ERROR, props<{ error: string }>());

export const GetAutoReplay = createAction(MessagesActions.GET_REPLAY,(params: any = {}) => params);
export const GetAutoReplaySuccess = createAction(MessagesActions.GET_REPLAY_SUCCESS, props<{ messages: Messages }>());
export const GetAutoReplayError = createAction(MessagesActions.GET_REPLAY_ERROR, props<{ error: string }>());

export const ResetAddAutoReplayState = createAction(MessagesActions.RESET_REPLAY_STATE, (params: any = {}) => params)
