import {Injectable} from "@angular/core";
import {BehaviorSubject, Observable} from "rxjs";

@Injectable({providedIn: 'root'})
export class LoaderService {
  private loaderSubject: BehaviorSubject<boolean>;
  public loader$: Observable<boolean>;
  private uploaderSubject: BehaviorSubject<boolean>;
  public uploader$: Observable<boolean>;
  private uploaderProgress: BehaviorSubject<number>;
  public progress$: Observable<number>;

  constructor() {
    this.loaderSubject = new BehaviorSubject<boolean>(false);
    this.loader$ = this.loaderSubject.asObservable();
    this.uploaderSubject = new BehaviorSubject<boolean>(false);
    this.uploader$ = this.uploaderSubject.asObservable();
    this.uploaderProgress = new BehaviorSubject<number>(0);
    this.progress$ = this.uploaderProgress.asObservable();
  }

  show(isForUpload = false) {
    this.loaderSubject.next(true);
    if (isForUpload) {
      this.uploaderSubject.next(true);
    }
  }

  hide(isForUpload = false) {
    this.loaderSubject.next(false);
    if (isForUpload) {
      this.uploaderSubject.next(false);
      this.uploaderProgress.next(0);
    }
  }

  updateProgress(progress: number) {
    this.uploaderProgress.next(progress);
    // if (progress >= 100) {
    //   this.uploaderProgress.next(0);
    // }
  }

  getErrorMessage(error: any, def: string = 'Something went wrong') {
    if (error.error) {
      if (error.error.msg) {
        def = error.error.msg;
      } else if (error.error.message) {

        def = error.error.message;
      } else if (error.error.err) {
        if (error.error.err.message) {
          def = error.error.err.message;
        }
      }
    } else if (error.msg) {
      def = error.msg;
    } else if (error.message) {
      def = error.message;
    }
    if (def === 'jwt expired' || def === 'Social Error') {
      def = '';
    }
    return def;
  }
}
