import {Inject, Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {BehaviorSubject, Observable} from 'rxjs';
import {delay, retryWhen, tap} from 'rxjs/operators';
import {WebSocketSubject} from 'rxjs/webSocket';
import {AuthState, getToken} from "../../auth-store/src";
import {APP_CONFIG, LocalstorageService} from "../index";

@Injectable({
  providedIn: 'root'
})
export class SocketService {
  private socketDataSource = new BehaviorSubject<Object>(null);
  sourceData = this.socketDataSource.asObservable();
  public socket$: WebSocketSubject<any>;
  token: any = null;
  interval: any = null
  intervalTime = 1000 * 60 * 3;

  constructor(
    private authenticationStore: Store<AuthState>,
    private localStorageService: LocalstorageService,
    @Inject(APP_CONFIG) private appConfig: any
  ) {
    this.authenticationStore.pipe(select(getToken))
      .subscribe(newToken => {
        if (newToken) {
          this.token = newToken;
          this.connect();
        }
      });
  }

  resetSocketData() {
    clearTimeout(this.interval);
    this.socketDataSource.next(null);
  }

  createWebSocket(uri: string) {
    return new Observable(observer => {
      try {
        this.socket$ = new WebSocketSubject(uri);
        const subscription = this.socket$.asObservable()
          .subscribe(message => {
              observer.next(message);
              if (message?.data) {
                this.socketDataSource.next(message);
              }
            }, error => observer.error(error),
            () => observer.complete());

        return () => {
          if (!subscription.closed) {
            subscription.unsubscribe();
          }
        };
      } catch (error) {
        return observer.error(error);
      }
    });
  }

  connect() {
    if (!this.token && this.localStorageService.updateGuestUserKey('get')) {
      this.token = this.localStorageService.updateGuestUserKey('get')?.token;
    }
    this.createWebSocket(`${this.appConfig.websocket}?userType=${this.appConfig.type}&token=${this.token}`)
      .pipe(retryWhen(errors => errors.pipe(tap(), delay(5000))))
      .subscribe(data => {
        this.startHeartbeat();
      }, err => {
        console.error(err)
      });
  }

  startHeartbeat() {
    clearTimeout(this.interval);
    this.interval = setTimeout(() => {
      this.socket$.next({action: 'PING', payload: {}})
    }, this.intervalTime);
  }

  sendMessage(data: any) {
    if (!this.socket$ || this.socket$.closed) {
      this.connect();
    }
    this.socket$.next(data);
  }
}
