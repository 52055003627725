import {
  Categories,
  CurrencyData,
  GiftCard,
  PaginatedProducts,
  PaginatedSellers, PayoutDetails,
  Product,
  Store,
  User, WalletDetails
} from "@kwot/data-models";
import {createAction, props} from "@ngrx/store";

const BuyerActions = {
  GET_BUYER_DETAIL: '[BUYER] BUYER DETAIL',

  GET_CATEGORIES: '[BUYER] Get Categories',
  GET_CATEGORIES_SUCCESS: '[BUYER] Get Categories success',
  GET_CATEGORIES_ERROR: '[BUYER] Get Categories error',

  GET_PRODUCT_BY_ID: '[BUYER] Get product',
  GET_PRODUCT_BY_ID_SUCCESS: '[BUYER] Get product success',
  GET_PRODUCT_BY_ID_ERROR: '[BUYER] Get product error',

  GET_PRODUCT_BY_CATEGORIES: '[BUYER] Get buyer product',
  GET_PRODUCT_BY_CATEGORIES_SUCCESS: '[BUYER] Get buyer product success',
  GET_PRODUCT_BY_CATEGORIES_ERROR: '[BUYER] Get buyer product error',

  GET_HOME_PRODUCTS: '[BUYER] Get Home Page items',
  GET_HOME_PRODUCTS_SUCCESS: '[BUYER] Get Home Page items success',
  GET_HOME_PRODUCTS_ERROR: '[BUYER] Get Home Page items error',

  GET_ADDRESS_BY_ID: '[BUYER] Get address by id ',
  GET_ADDRESS_BY_ID_SUCCESS: '[BUYER] Get address by id success',
  GET_ADDRESS_BY_ID_ERROR: '[BUYER] Get address by id error',

  GET_CATEGORY_FILTER: '[BUYER] Get Category Filter',
  GET_CATEGORY_FILTER_SUCCESS: '[BUYER] Get Category Filter success',
  GET_CATEGORY_FILTER_ERROR: '[BUYER] Get Category Filter error',

  ADD_TO_FAVOURITE: '[BUYER] Add product to favourite',
  ADD_TO_FAVOURITE_SUCCESS: '[BUYER] Add product to favourite success',
  ADD_TO_FAVOURITE_ERROR: '[BUYER] Add product to favourite error',

  GET_FAVOURITE: '[BUYER] Get product to favourite',
  GET_FAVOURITE_SUCCESS: '[BUYER] Get product to favourite success',
  GET_FAVOURITE_ERROR: '[BUYER] Get product to favourite error',

  GET_ALL_PRODUCT: '[BUYER] Get all product',
  GET_ALL_PRODUCT_SUCCESS: '[BUYER] Get all product success',
  GET_ALL_PRODUCT_ERROR: '[BUYER] Get all product error',

  REMOVE_PRODUCT_FAVOURITE: '[BUYER] Remove product from favourite',
  REMOVE_PRODUCT_FAVOURITE_SUCCESS: '[BUYER] Remove product from favourite success',
  REMOVE_PRODUCT_FAVOURITE_ERROR: '[BUYER] Remove product from favourite error',

  RECENTLY_VIEW_PRODUCT: '[BUYER] Recently view products',
  RECENTLY_VIEW_PRODUCT_SUCCESS: '[BUYER] Recently view products success',
  RECENTLY_VIEW_PRODUCT_ERROR: '[BUYER] Recently view products error',

  GET_VENDOR_DETAIL: '[BUYER] Get vendor details',
  GET_VENDOR_DETAIL_SUCCESS: '[BUYER] Get vendor details success',
  GET_VENDOR_DETAIL_ERROR: '[BUYER] Get vendor details error',

  CREATE_WISHLIST: '[BUYER] Create wishlist',
  CREATE_WISHLIST_SUCCESS: '[BUYER] Get wishlist success',
  CREATE_WISHLIST_ERROR: '[BUYER] Get wishlist error',

  GET_WISHLIST: '[BUYER] Get wishlist details',
  GET_WISHLIST_SUCCESS: '[BUYER] Get wishlist details success',
  GET_WISHLIST_ERROR: '[BUYER] Get wishlist details error',

  GET_WISHLIST_BY_ID: '[BUYER] Get wishlist by id',
  GET_WISHLIST_BY_ID_SUCCESS: '[BUYER] Get wishlist by id success',
  GET_WISHLIST_BY_ID_ERROR: '[BUYER] Get wishlist by id error',

  EDIT_WISHLIST: '[BUYER] Edit wishlist',
  EDIT_WISHLIST_SUCCESS: '[BUYER] Edit wishlist success',
  EDIT_WISHLIST_ERROR: '[BUYER] Edit wishlist error',

  ADD_PRODUCT_TO_WISHLIST: '[BUYER] Add product to wishlist',
  ADD_PRODUCT_TO_WISHLIST_SUCCESS: '[BUYER] Add product to wishlist success',
  ADD_PRODUCT_TO_WISHLIST_ERROR: '[BUYER] Add product to wishlist error',

  REMOVE_PRODUCT_TO_WISHLIST: '[BUYER] Remove product to wishlist',
  REMOVE_PRODUCT_TO_WISHLIST_SUCCESS: '[BUYER] Remove product to wishlist success',
  REMOVE_PRODUCT_TO_WISHLIST_ERROR: '[BUYER] Remove product to wishlist error',

  DELETE_WISHLIST: '[BUYER] Delete wishlist',
  DELETE_WISHLIST_SUCCESS: '[BUYER] Delete wishlist success',
  DELETE_WISHLIST_ERROR: '[BUYER] Delete wishlist error',
  GET_NOTIFICATIONS: '[BUYER] Get Notifications',
  GET_NOTIFICATIONS_SUCCESS: '[BUYER] Get Notifications success',
  GET_NOTIFICATIONS_ERROR: '[BUYER] Get Notifications error',
  READ_NOTIFICATIONS: '[BUYER] Read Notifications',
  READ_NOTIFICATIONS_SUCCESS: '[BUYER] Read Notifications success',
  READ_NOTIFICATIONS_ERROR: '[BUYER] Read Notifications error',
  GET_PRODUCT_REVIEWS: '[BUYER] Get Product Reviews',
  GET_PRODUCT_REVIEWS_SUCCESS: '[BUYER] Get Product Reviews success',
  GET_PRODUCT_REVIEWS_ERROR: '[BUYER] Get Product Reviews error',
  POST_PRODUCT_REVIEW: '[BUYER] Post Product Review',
  POST_PRODUCT_REVIEW_SUCCESS: '[BUYER] Post Product Review success',
  POST_PRODUCT_REVIEW_ERROR: '[BUYER] Post Product Review error',
  GET_PRODUCTS: '[BUYER] Get Products',
  GET_PRODUCTS_SUCCESS: '[BUYER] Get Products success',
  GET_PRODUCTS_ERROR: '[BUYER] Get Products error',

  CHECK_GUEST_OR_UPDATE: '[BUYER] Check guest account or Create',
  CHECK_GUEST_OR_UPDATE_SUCCESS: '[BUYER] Check guest account or Create Success',
  CHECK_GUEST_OR_UPDATE_ERROR: '[BUYER] Check guest account or Create Error',

  GET_SELLERS_LIST: '[BUYER] Get Sellers List',
  GET_SELLERS_LIST_SUCCESS: '[BUYER] Get Sellers List Success',
  GET_SELLERS_LIST_ERROR: '[BUYER] Get Sellers List Error',

  GET_GUEST_BUYER_INFO: '[BUYER] Get Guest Buyer Info',
  GET_GUEST_BUYER_INFO_SUCCESS: '[BUYER] Get Guest Buyer Info Success',
  GET_GUEST_BUYER_INFO_ERROR: '[BUYER] Get Guest Buyer Info Error',

  CANCEL_ORDER: '[BUYER] Cancel Order',
  CANCEL_ORDER_SUCCESS: '[BUYER] Cancel Order success',
  CANCEL_ORDER_ERROR: '[BUYER] Cancel Order error',

  CONTACT_NOW: '[BUYER] Contact Now',
  CONTACT_NOW_SUCCESS: '[BUYER] Contact Now success',
  CONTACT_NOW_ERROR: '[BUYER] Contact Now error',

  GET_POLICY_DETAIL: "[VENDOR] Get Policy Detail",
  GET_POLICY_DETAIL_SUCCESS: "[VENDOR] Get Policy Detail Success",
  GET_POLICY_DETAIL_ERROR: "[VENDOR] Get Policy Detail Error",
  ACCEPT_POLICY_DETAIL: "[VENDOR] Accept Policy Detail",
  ACCEPT_POLICY_DETAIL_SUCCESS: "[VENDOR] Accept Policy Detail Success",
  ACCEPT_POLICY_DETAIL_ERROR: "[VENDOR] Accept Policy Detail Error",

  GET_CATEGORY_RESPONSE: "[BUYER] Get Category Response",
  GET_CATEGORY_RESPONSE_SUCCESS: "[BUYER] Get Category Response Success",
  GET_CATEGORY_RESPONSE_ERROR: "[BUYER] Get Category Response Error",

  GET_BANNER_IMAGE: "[BUYER] Get Banner Image",
  GET_BANNER_IMAGE_SUCCESS: "[BUYER] Get Banner Image Success",
  GET_BANNER_IMAGE_ERROR: "[BUYER] Get Banner Image Error",

  GET_ALL_ADDRESS: '[BUYER] Get all Address ',
  GET_ALL_ADDRESS_SUCCESS: '[BUYER] Get all address success',
  GET_ALL_ADDRESS_ERROR: '[BUYER] Get all address error',

  ADD_ORDER_PAYMENT_DETAILS: '[BUYER] Add Order Payment Details',
  ADD_ORDER_PAYMENT_DETAILS_SUCCESS: '[BUYER] Add Order Payment Details success',
  ADD_ORDER_PAYMENT_DETAILS_ERROR: '[BUYER] Add Order Payment Details error',

  // ORDER_PLACE: '[BUYER] Order place',
  // ORDER_PLACE_SUCCESS: '[BUYER] Order place success',
  // ORDER_PLACE_ERROR: '[BUYER] Order place error',

  GET_BUYER_ADDRESS: '[BUYER] Get buyer Address',
  GET_BUYER_ADDRESS_SUCCESS: '[BUYER] Get buyer address success',
  GET_BUYER_ADDRESS_ERROR: '[BUYER] Get buyer address error',

  DELETE_ADDRESS: '[BUYER] Delete address ',
  DELETE_ADDRESS_SUCCESS: '[BUYER] Delete address success',
  DELETE_ADDRESS_ERROR: '[BUYER] Delete address error',

  GET_PROFILE_INFO: '[BUYER] Get Profile Info',
  GET_PROFILE_INFO_SUCCESS: '[BUYER] Get Profile Info Success',
  GET_PROFILE_INFO_ERROR: '[BUYER] Get Profile Info Error',

  EDIT_ADDRESS: '[BUYER] Edit address ',
  EDIT_ADDRESS_SUCCESS: '[BUYER] Edit address success',
  EDIT_ADDRESS_ERROR: '[BUYER] Edit address error',

  UPDATE_PROFILE_INFO: '[BUYER] Update Profile Info',
  UPDATE_PROFILE_INFO_SUCCESS: '[BUYER] Update Profile Info Success',
  UPDATE_PROFILE_INFO_ERROR: '[BUYER] Update Profile Info Error',

  GET_CURRENCY_DATA: '[BUYER] Get Currency Data',
  GET_CURRENCY_DATA_SUCCESS: '[BUYER] Get Currency Data Success',
  GET_CURRENCY_DATA_ERROR: '[BUYER] Get Currency Data Error',

  GET_WISHLIST_PRODUCTS: '[BUYER] Get Wishlist Products',
  GET_WISHLIST_PRODUCTS_SUCCESS: '[BUYER] Get Wishlist Products Success',
  GET_WISHLIST_PRODUCTS_ERROR: '[BUYER] Get Wishlist Products Error',

  GET_ALL_GIFT_CARDS: '[BUYER] Get All Gift Cards',
  GET_ALL_GIFT_CARDS_SUCCESS: '[BUYER] Get All Gift Cards Success',
  GET_ALL_GIFT_CARDS_ERROR: '[BUYER] Get All Gift Cards Error',

  CHECK_GIFT_CARD_VALIDITY: '[BUYER] Check gift card validity',
  CHECK_GIFT_CARD_VALIDITY_SUCCESS: '[BUYER] Check gift card validity Success',
  CHECK_GIFT_CARD_VALIDITY_ERROR: '[BUYER] Check gift card validity Error',

  VALIDATE_GIFT_PROMO_CODE: '[BUYER] Validate gift or promo code',
  VALIDATE_GIFT_PROMO_CODE_SUCCESS: '[BUYER] Validate gift or promo code Success',
  VALIDATE_GIFT_PROMO_CODE_ERROR: '[BUYER] Validate gift or promo code Error',

  ADD_AFFILIATE: '[BUYER] Add affiliate',
  ADD_AFFILIATE_SUCCESS: '[BUYER] Add affiliate success',
  ADD_AFFILIATE_ERROR: '[BUYER] Add affiliate error',

  CHECK_ASSOCIATE_STORE_ID_AVAILABILITY: '[BUYER] Check associate store id availability',
  CHECK_ASSOCIATE_STORE_ID_AVAILABILITY_SUCCESS: '[BUYER] Check associate store id availability success',
  CHECK_ASSOCIATE_STORE_ID_AVAILABILITY_ERROR: '[BUYER] Check associate store id availability error',

  GET_AFFILIATE: '[BUYER] Get Affiliate',
  GET_AFFILIATE_SUCCESS: '[BUYER] Get Affiliate success',
  GET_AFFILIATE_ERROR: '[BUYER] Get Affiliate error',

  GET_AFFILIATE_DASHBOARD: '[BUYER] Get Affiliate Dashboard',
  GET_AFFILIATE_DASHBOARD_SUCCESS: '[BUYER] Get Affiliate Dashboard success',
  GET_AFFILIATE_DASHBOARD_ERROR: '[BUYER] Get Affiliate Dashboard error',

  ADD_AFFILIATE_PRODUCT: '[BUYER] Add affiliate product',
  ADD_AFFILIATE_PRODUCT_SUCCESS: '[BUYER] Add affiliate product success',
  ADD_AFFILIATE_PRODUCT_ERROR: '[BUYER] Add affiliate product error',

  GET_AFFILIATE_PRODUCT: '[BUYER] Get Affiliate product',
  GET_AFFILIATE_PRODUCT_SUCCESS: '[BUYER] Get Affiliate product success',
  GET_AFFILIATE_PRODUCT_ERROR: '[BUYER] Get Affiliate product error',

  EDIT_AFFILIATE: '[BUYER] Edit Affiliate',
  EDIT_AFFILIATE_SUCCESS: '[BUYER] Edit Affiliate success',
  EDIT_AFFILIATE_ERROR: '[BUYER] Edit Affiliate error',

  GET_WALLET_BALANCE: '[BUYER] Get Affiliate Wallet Balance',
  GET_WALLET_BALANCE_SUCCESS: '[BUYER] Get Affiliate Wallet Balance Success',
  GET_WALLET_BALANCE_ERROR: '[BUYER] Get Affiliate Wallet Balance Error',

  GET_WALLET_TRANSACTION_BALANCE: '[BUYER] Get Affiliate Wallet Transaction Balance',
  GET_WALLET_TRANSACTION_BALANCE_SUCCESS: '[BUYER] Get Affiliate Wallet Transaction Balance Success',
  GET_WALLET_TRANSACTION_BALANCE_ERROR: '[BUYER] Get Affiliate Wallet Transaction Balance Error',

  ADD_SCHEDULE_DATA: "[BUYER] Add Schedule Data",
  ADD_SCHEDULE_DATA_SUCCESS: "[BUYER] Add Schedule Data Success",
  ADD_SCHEDULE_DATA_ERROR: "[BUYER] Add Schedule Data Error",

  ADD_PAYOUT_DATA: "[BUYER] Add Payout Data",
  ADD_PAYOUT_DATA_SUCCESS: "[BUYER] Add Payout Data Success",
  ADD_PAYOUT_DATA_ERROR: "[BUYER] Add Payout Data Error",

  GET_PAYOUT_DETAIL: "[BUYER] Get Payout Detail",
  GET_PAYOUT_DETAIL_SUCCESS: "[BUYER] Get Payout Detail Success",
  GET_PAYOUT_DETAIL_ERROR: "[BUYER] Get Payout Detail Error",

  SEARCH_AFFILIATE_PRODUCTS: "[BUYER] Search Affiliate Products",
  SEARCH_AFFILIATE_PRODUCTS_SUCCESS: "[BUYER] Search Affiliate Products Success",
  SEARCH_AFFILIATE_PRODUCTS_ERROR: "[BUYER] Search Affiliate Products Error",

  CREATE_ADS_TRANSACTION: "[BUYER] Create Ads transaction",
  CREATE_ADS_TRANSACTION_SUCCESS: "[BUYER] Create Ads transaction Success",
  CREATE_ADS_TRANSACTION_ERROR: "[BUYER] Create Ads transaction Error",

  GET_SPONSORED_ITEMS: "[BUYER] Get Sponsored Items",
  GET_SPONSORED_ITEMS_SUCCESS: "[BUYER] Get Sponsored Items Success",
  GET_SPONSORED_ITEMS_ERROR: "[BUYER] Get Sponsored Items Error",

  ADD_ITEM_TO_CART: "[BUYER] Add Item To Cart",
  ADD_ITEM_TO_CART_SUCCESS: "[BUYER] Add Item To Cart Success",
  ADD_ITEM_TO_CART_ERROR: "[BUYER] Add Item To Cart Error",

  GET_CART: "[BUYER] Get Cart",
  GET_CART_SUCCESS: "[BUYER] Get Cart Success",
  GET_CART_ERROR: "[BUYER] Get Cart Error",

  VALIDATE_ORDER: "[BUYER] Validate Order",
  VALIDATE_ORDER_SUCCESS: "[BUYER] Validate Order Success",
  VALIDATE_ORDER_ERROR: "[BUYER] Validate Order Error",

  GET_SEARCH_PRODUCT: '[HOME] Get search product',
  GET_SEARCH_PRODUCT_SUCCESS: '[HOME] Get search product success',
  GET_SEARCH_PRODUCT_ERROR: '[HOME] Get search product error',

  UPDATE_SELLER_TO_FAVORITE: '[BUYER] Update seller to favourite',
  UPDATE_SELLER_TO_FAVORITE_SUCCESS: '[BUYER] Update seller to favourite success',
  UPDATE_SELLER_TO_FAVORITE_ERROR: '[BUYER] Update seller to favourite error',

  START_NEGOTIATION: '[BUYER] Start negotiation',
  START_NEGOTIATION_SUCCESS: '[BUYER] Start negotiation success',
  START_NEGOTIATION_ERROR: '[BUYER] Start negotiation error',

  GET_RFQ_FIELDS: "[BUYER] Get Rfq fields",
  GET_RFQ_FIELDS_SUCCESS: "[BUYER] Get Rfq fields Success",
  GET_RFQ_FIELDS_ERROR: "[BUYER] Get Rfq fields Error",

  ADD_TEMP_ORDER: "[BUYER] Add Temp Order",
  ADD_TEMP_ORDER_SUCCESS: "[BUYER] Add Temp Order Success",
  ADD_TEMP_ORDER_ERROR: "[BUYER] Add Temp Order Error",

  GET_TEMP_ORDER: "[BUYER] Get Temp Order",
  GET_TEMP_ORDER_SUCCESS: "[BUYER] Get Temp Order Success",
  GET_TEMP_ORDER_ERROR: "[BUYER] Get Temp Order Error",

  GET_PAYMENT_INTENT: "[BUYER] Get Payment Intent",
  GET_PAYMENT_INTENT_SUCCESS: "[BUYER] Get Payment Intent Success",
  GET_PAYMENT_INTENT_ERROR: "[BUYER] Get Payment Intent Error",

  CHECK_CHAT_USER: '[BUYER] Check chat user',
  CHECK_CHAT_USER_SUCCESS: '[BUYER] Check chat user Success',
  CHECK_CHAT_USER_ERROR: '[BUYER] Check chat user Error',

  GET_ACTIVE_PROMO_CODES: '[BUYER] Get active promo codes',
  GET_ACTIVE_PROMO_CODES_SUCCESS: '[BUYER] Get active promo codes success',
  GET_ACTIVE_PROMO_CODES_ERROR: '[BUYER] Get active promo codes error',

  CONTACT_TO_SELLER: '[BUYER] Contact to seller codes',
  CONTACT_TO_SELLER_SUCCESS: '[BUYER] Contact to seller success',
  CONTACT_TO_SELLER_ERROR: '[BUYER] Contact to seller error',

  RESET_PAYMENT_STATE: '[PAYMENT] Reset payment state',

  RESET_BUYER_STATE: '[BUYER] Reset buyer state'
}

export const GetAllAddress = createAction(BuyerActions.GET_ALL_ADDRESS, (items: any = {}) => items);
export const GetAllAddressSuccess = createAction(BuyerActions.GET_ALL_ADDRESS_SUCCESS, props<{ address: any }>());
export const GetAllAddressError = createAction(BuyerActions.GET_ALL_ADDRESS_ERROR, props<{ error: string }>());

export const GetBuyerDetails = createAction(BuyerActions.GET_BUYER_DETAIL, props<{ id: string }>())

export const GetCategories = createAction(BuyerActions.GET_CATEGORIES, (params: any = {}) => params)
export const GetCategoriesSuccess = createAction(BuyerActions.GET_CATEGORIES_SUCCESS, props<{ categories: Categories[] }>());
export const GetCategoriesError = createAction(BuyerActions.GET_CATEGORIES_ERROR, props<{ error: string }>());

export const GetProductById = createAction(BuyerActions.GET_PRODUCT_BY_ID, props<{ productName: string, associateStoreId?: string }>());
export const GetProductByIdSuccess = createAction(BuyerActions.GET_PRODUCT_BY_ID_SUCCESS, props<{ product: any }>());
export const GetProductByIdError = createAction(BuyerActions.GET_PRODUCT_BY_ID_ERROR, props<{ error: string }>());

export const GetHomeProducts = createAction(BuyerActions.GET_HOME_PRODUCTS);
export const GetHomeProductsSuccess = createAction(BuyerActions.GET_HOME_PRODUCTS_SUCCESS, props<{ products: Product }>());
export const GetHomeProductsError = createAction(BuyerActions.GET_HOME_PRODUCTS_ERROR, props<{ error: string }>());

export const GetAddressById = createAction(BuyerActions.GET_ADDRESS_BY_ID, (items: any = {}) => items);
export const GetAddressByIdSuccess = createAction(BuyerActions.GET_ADDRESS_BY_ID_SUCCESS, props<{ address: any }>());
export const GetAddressByIdError = createAction(BuyerActions.GET_ADDRESS_BY_ID_ERROR, props<{ error: string }>());

export const GetCategoryFilter = createAction(BuyerActions.GET_CATEGORY_FILTER, (items: any = {}) => items);
export const GetCategoryFilterSuccess = createAction(BuyerActions.GET_CATEGORY_FILTER_SUCCESS, props<{ categoryFilter: any }>());
export const GetCategoryFilterError = createAction(BuyerActions.GET_CATEGORY_FILTER_ERROR, props<{ error: string }>());

export const RemoveProductFavourite = createAction(BuyerActions.REMOVE_PRODUCT_FAVOURITE, (items: any = {}) => items);
export const RemoveProductFavouriteSuccess = createAction(BuyerActions.REMOVE_PRODUCT_FAVOURITE_SUCCESS, props<{ removeItem: any }>());
export const RemoveProductFavouriteError = createAction(BuyerActions.REMOVE_PRODUCT_FAVOURITE_ERROR, props<{ error: string }>());

export const GetVendorDetail = createAction(BuyerActions.GET_VENDOR_DETAIL, (items: any = {}) => items);
export const GetVendorDetailSuccess = createAction(BuyerActions.GET_VENDOR_DETAIL_SUCCESS, props<{ vendorDetail: any }>());
export const GetVendorDetailError = createAction(BuyerActions.GET_VENDOR_DETAIL_ERROR, props<{ error: string }>());

export const GetRecentlyViewProduct = createAction(BuyerActions.RECENTLY_VIEW_PRODUCT, props<{ product: any }>());

export const CreateWishList = createAction(BuyerActions.CREATE_WISHLIST, (items: any = {}) => items);
export const CreateWishListSuccess = createAction(BuyerActions.CREATE_WISHLIST_SUCCESS, props<{ wishList: any }>());
export const CreateWishListError = createAction(BuyerActions.CREATE_WISHLIST_ERROR, props<{ error: string }>());

export const GetWishList = createAction(BuyerActions.GET_WISHLIST, (items: any = {}) => items);
export const GetWishListSuccess = createAction(BuyerActions.GET_WISHLIST_SUCCESS, props<{ wishList: any }>());
export const GetWishListError = createAction(BuyerActions.GET_WISHLIST_ERROR, props<{ error: string }>());

export const AddProductWishList = createAction(BuyerActions.ADD_PRODUCT_TO_WISHLIST, (items: any = {}) => items);
export const AddProductWishListSuccess = createAction(BuyerActions.ADD_PRODUCT_TO_WISHLIST_SUCCESS, props<{ success: any }>());
export const AddProductWishListError = createAction(BuyerActions.ADD_PRODUCT_TO_WISHLIST_ERROR, props<{ error: string }>());

export const RemoveProductWishList = createAction(BuyerActions.REMOVE_PRODUCT_TO_WISHLIST, (items: any = {}) => items);
export const RemoveProductWishListSuccess = createAction(BuyerActions.REMOVE_PRODUCT_TO_WISHLIST_SUCCESS, props<{ success: any }>());
export const RemoveProductWishListError = createAction(BuyerActions.REMOVE_PRODUCT_TO_WISHLIST_ERROR, props<{ error: string }>());

export const GetWishListById = createAction(BuyerActions.GET_WISHLIST_BY_ID, (items: any = {}) => items);
export const GetWishListByIdSuccess = createAction(BuyerActions.GET_WISHLIST_BY_ID_SUCCESS, props<{ success: any }>());
export const GetWishListByIdError = createAction(BuyerActions.GET_WISHLIST_BY_ID_ERROR, props<{ error: string }>());

export const EditWishList = createAction(BuyerActions.EDIT_WISHLIST, (items: any = {}) => items);
export const EditWishListSuccess = createAction(BuyerActions.EDIT_WISHLIST_SUCCESS, props<{ success: any }>());
export const EditWishListError = createAction(BuyerActions.EDIT_WISHLIST_ERROR, props<{ error: string }>());

export const DeleteWishList = createAction(BuyerActions.DELETE_WISHLIST, (items: any = {}) => items);
export const DeleteWishListSuccess = createAction(BuyerActions.DELETE_WISHLIST_SUCCESS, props<{ success: any }>());
export const DeleteWishListError = createAction(BuyerActions.DELETE_WISHLIST_ERROR, props<{ error: string }>());

export const GetNotifications = createAction(BuyerActions.GET_NOTIFICATIONS, (params: any = {}) => params);
export const GetNotificationsSuccess = createAction(BuyerActions.GET_NOTIFICATIONS_SUCCESS, props<{ notifications: any }>());
export const GetNotificationsError = createAction(BuyerActions.GET_NOTIFICATIONS_ERROR, props<{ error: string }>());

export const ReadNotification = createAction(BuyerActions.READ_NOTIFICATIONS, props<{ notificationId: string }>());
export const ReadNotificationSuccess = createAction(BuyerActions.READ_NOTIFICATIONS_SUCCESS);
export const ReadNotificationError = createAction(BuyerActions.READ_NOTIFICATIONS_ERROR, props<{ error: string }>());

export const GetProductReviews = createAction(BuyerActions.GET_PRODUCT_REVIEWS, (params: any = {}) => params);
export const GetProductReviewsSuccess = createAction(BuyerActions.GET_PRODUCT_REVIEWS_SUCCESS, props<{ reviews: any }>());
export const GetProductReviewsError = createAction(BuyerActions.GET_PRODUCT_REVIEWS_ERROR, props<{ error: string }>());

export const PostProductReview = createAction(BuyerActions.POST_PRODUCT_REVIEW, props<{ body: any }>());
export const PostProductReviewSuccess = createAction(BuyerActions.POST_PRODUCT_REVIEW_SUCCESS);
export const PostProductReviewError = createAction(BuyerActions.POST_PRODUCT_REVIEW_ERROR, props<{ error: string }>());

export const GetProducts = createAction(BuyerActions.GET_PRODUCTS, (params: any = {}) => params);
export const GetProductsSuccess = createAction(BuyerActions.GET_PRODUCTS_SUCCESS, props<{ paginatedProducts: PaginatedProducts }>());
export const GetProductsError = createAction(BuyerActions.GET_PRODUCTS_ERROR, props<{ error: string }>());

export const CheckGuestOrUpdate = createAction(BuyerActions.CHECK_GUEST_OR_UPDATE, props<{ body: any, showLoader: boolean }>());
export const CheckGuestOrUpdateSuccess = createAction(BuyerActions.CHECK_GUEST_OR_UPDATE_SUCCESS, props<{ guestUser: User | Store }>());
export const CheckGuestOrUpdateError = createAction(BuyerActions.CHECK_GUEST_OR_UPDATE_ERROR, props<{ error: string }>());

export const GetSellersList = createAction(BuyerActions.GET_SELLERS_LIST, (params: any = {}) => params);
export const GetSellersListSuccess = createAction(BuyerActions.GET_SELLERS_LIST_SUCCESS, props<{ vendors: PaginatedSellers }>());
export const GetSellersListError = createAction(BuyerActions.GET_SELLERS_LIST_ERROR, props<{ error: string }>());

export const GuestBuyerInfo = createAction(BuyerActions.GET_GUEST_BUYER_INFO, props<{ body: any }>());
export const GuestBuyerInfoSuccess = createAction(BuyerActions.GET_GUEST_BUYER_INFO_SUCCESS, props<{ guestInfo: any; }>());
export const GuestBuyerInfoError = createAction(BuyerActions.GET_GUEST_BUYER_INFO_ERROR, props<{ error: string }>());

export const CancelOrder = createAction(BuyerActions.CANCEL_ORDER, props<{ body: any }>());
export const CancelOrderSuccess = createAction(BuyerActions.CANCEL_ORDER_SUCCESS, props<{ cancelOrder: any; }>());
export const CancelOrderError = createAction(BuyerActions.CANCEL_ORDER_ERROR, props<{ error: string }>());

export const GetPolicyDetail = createAction(BuyerActions.GET_POLICY_DETAIL, (params: any = {}) => params);
export const GetPolicyDetailSuccess = createAction(BuyerActions.GET_POLICY_DETAIL_SUCCESS, props<{ policy: any }>());
export const GetPolicyDetailError = createAction(BuyerActions.GET_POLICY_DETAIL_ERROR, props<{ error: string }>());

export const AcceptPolicyDetail = createAction(BuyerActions.ACCEPT_POLICY_DETAIL, (params: any = {}) => params);
export const AcceptPolicyDetailSuccess = createAction(BuyerActions.ACCEPT_POLICY_DETAIL_SUCCESS);
export const AcceptPolicyDetailError = createAction(BuyerActions.ACCEPT_POLICY_DETAIL_ERROR, props<{ error: string }>());

export const ContactNow = createAction(BuyerActions.CONTACT_NOW, (params: any = {}) => params);
export const ContactNowSuccess = createAction(BuyerActions.CONTACT_NOW_SUCCESS);
export const ContactNowError = createAction(BuyerActions.CONTACT_NOW_ERROR, props<{ error: string }>());

export const ResetBuyerState = createAction(BuyerActions.RESET_BUYER_STATE, props<{ params: any }>());

export const GetCategoriesResponse = createAction(BuyerActions.GET_CATEGORY_RESPONSE);
export const GetCategoriesResponseSuccess = createAction(BuyerActions.GET_CATEGORY_RESPONSE_SUCCESS, props<{ categoryResponse: any }>());
export const GetCategoriesResponseError = createAction(BuyerActions.GET_CATEGORY_RESPONSE_ERROR, props<{ error: string }>());

export const GetBannerImages = createAction(BuyerActions.GET_BANNER_IMAGE);
export const GetBannerImagesSuccess = createAction(BuyerActions.GET_BANNER_IMAGE_SUCCESS, props<{ bannerImage: any }>());
export const GetBannerImagesError = createAction(BuyerActions.GET_BANNER_IMAGE_ERROR, props<{ error: string }>());

export const UpdateProfileInfo = createAction(BuyerActions.UPDATE_PROFILE_INFO, props<{ body: any }>());
export const UpdateProfileInfoSuccess = createAction(BuyerActions.UPDATE_PROFILE_INFO_SUCCESS, props<{ profile: User | Store }>());
export const UpdateProfileInfoError = createAction(BuyerActions.UPDATE_PROFILE_INFO_ERROR, props<{ error: string }>());

export const UpdateAddress = createAction(BuyerActions.EDIT_ADDRESS, (items: any = {}) => items);
export const UpdateAddressSuccess = createAction(BuyerActions.EDIT_ADDRESS_SUCCESS, props<{ address: any }>());
export const UpdateAddressError = createAction(BuyerActions.EDIT_ADDRESS_ERROR, props<{ error: string }>());

export const ProfileInfo = createAction(BuyerActions.GET_PROFILE_INFO);
export const ProfileInfoSuccess = createAction(BuyerActions.GET_PROFILE_INFO_SUCCESS, props<{ userData: User | Store }>());
export const ProfileInfoError = createAction(BuyerActions.GET_PROFILE_INFO_ERROR, props<{ error: string }>());

export const DeleteAddress = createAction(BuyerActions.DELETE_ADDRESS, (items: any = {}) => items);
export const DeleteAddressSuccess = createAction(BuyerActions.DELETE_ADDRESS_SUCCESS, props<{ id: any }>());
export const DeleteAddressError = createAction(BuyerActions.DELETE_ADDRESS_ERROR, props<{ error: string }>());

export const AddAddres = createAction(BuyerActions.GET_BUYER_ADDRESS, (items: any = {}) => items);
export const AddAddresSuccess = createAction(BuyerActions.GET_BUYER_ADDRESS_SUCCESS, props<{ address: any }>());
export const AddAddresError = createAction(BuyerActions.GET_BUYER_ADDRESS_ERROR, props<{ error: string }>());

export const AddOrderPaymentDetails = createAction(BuyerActions.ADD_ORDER_PAYMENT_DETAILS, props<{ paymentDetails: any }>());
export const AddOrderPaymentDetailsSuccess = createAction(BuyerActions.ADD_ORDER_PAYMENT_DETAILS_SUCCESS, props<{ paymentSuccess: any }>());
export const AddOrderPaymentDetailsError = createAction(BuyerActions.ADD_ORDER_PAYMENT_DETAILS_ERROR, props<{ error: string }>());

// export const OrderPlace = createAction(BuyerActions.ORDER_PLACE, (items: any = {}) => items);
// export const OrderPlaceSuccess = createAction(BuyerActions.ORDER_PLACE_SUCCESS, props<{ orderPlaced: string }>());
// export const OrderPlaceError = createAction(BuyerActions.ORDER_PLACE_ERROR, props<{ error: string }>());

export const GetCurrencyData = createAction(BuyerActions.GET_CURRENCY_DATA);
export const GetCurrencyDataSuccess = createAction(BuyerActions.GET_CURRENCY_DATA_SUCCESS, props<{ currencyData: CurrencyData }>());
export const GetCurrencyDataError = createAction(BuyerActions.GET_CURRENCY_DATA_ERROR, props<{ error: string }>());

export const GetWishlistProducts = createAction(BuyerActions.GET_WISHLIST_PRODUCTS, (items: any = {}) => items);
export const GetWishlistProductsSuccess = createAction(BuyerActions.GET_WISHLIST_PRODUCTS_SUCCESS, props<{ updatedProducts: Product[] }>());
export const GetWishlistProductsError = createAction(BuyerActions.GET_WISHLIST_PRODUCTS_ERROR, props<{ error: string }>());

export const GetAllGiftCards = createAction(BuyerActions.GET_ALL_GIFT_CARDS);
export const GetAllGiftCardsSuccess = createAction(BuyerActions.GET_ALL_GIFT_CARDS_SUCCESS, props<{ giftCards: GiftCard[] }>());
export const GetAllGiftCardsError = createAction(BuyerActions.GET_ALL_GIFT_CARDS_ERROR, props<{ error: string }>());

export const CheckGiftCardValidity = createAction(BuyerActions.CHECK_GIFT_CARD_VALIDITY, props<{ giftCode: string }>());
export const CheckGiftCardValiditySuccess = createAction(BuyerActions.CHECK_GIFT_CARD_VALIDITY_SUCCESS, props<{ giftCardValidity: any }>());
export const CheckGiftCardValidityError = createAction(BuyerActions.CHECK_GIFT_CARD_VALIDITY_ERROR, props<{ error: string }>());

export const ValidateGiftPromoCode = createAction(BuyerActions.VALIDATE_GIFT_PROMO_CODE, props<{ discountCode: string, discountType: string }>());
export const ValidateGiftPromoCodeSuccess = createAction(BuyerActions.VALIDATE_GIFT_PROMO_CODE_SUCCESS, props<{ discountDetails: any }>());
export const ValidateGiftPromoCodeError = createAction(BuyerActions.VALIDATE_GIFT_PROMO_CODE_ERROR, props<{ error: string }>());

export const AddAffiliate = createAction(BuyerActions.ADD_AFFILIATE, (items: any = {}) => items);
export const AddAffiliateSuccess = createAction(BuyerActions.ADD_AFFILIATE_SUCCESS, props<{ success: any }>());
export const AddAffiliateError = createAction(BuyerActions.ADD_AFFILIATE_ERROR, props<{ error: string }>());

export const CheckAssociateStoreIdAvailability = createAction(BuyerActions.CHECK_ASSOCIATE_STORE_ID_AVAILABILITY, props<{ associateStoreId: string }>())
export const CheckAssociateStoreIdAvailabilitySuccess = createAction(BuyerActions.CHECK_ASSOCIATE_STORE_ID_AVAILABILITY_SUCCESS, props<{ isAssociateStoreIdAvailable: string }>());
export const CheckAssociateStoreIdAvailabilityError = createAction(BuyerActions.CHECK_ASSOCIATE_STORE_ID_AVAILABILITY_ERROR, props<{ error: string, isAssociateStoreIdAvailable: string }>());

export const GetAffiliate = createAction(BuyerActions.GET_AFFILIATE, (params: any = {}) => params);
export const GetAffiliatesSuccess = createAction(BuyerActions.GET_AFFILIATE_SUCCESS, props<{ affiliate: any }>());
export const GetAffiliateError = createAction(BuyerActions.GET_AFFILIATE_ERROR, props<{ error: string }>());

export const getAffiliateDashboard = createAction(BuyerActions.GET_AFFILIATE_DASHBOARD, (params: any = {}) => params);
export const getAffiliateDashboardSuccess = createAction(BuyerActions.GET_AFFILIATE_DASHBOARD_SUCCESS, props<{ affiliateDashboard: any }>());
export const getAffiliateDashboardError = createAction(BuyerActions.GET_AFFILIATE_DASHBOARD_ERROR, props<{ error: string }>());

export const AddAffiliateProduct = createAction(BuyerActions.ADD_AFFILIATE_PRODUCT, (items: any = {}) => items);
export const AddAffiliateProductSuccess = createAction(BuyerActions.ADD_AFFILIATE_PRODUCT_SUCCESS, props<{ success: any }>());
export const AddAffiliateProductError = createAction(BuyerActions.ADD_AFFILIATE_PRODUCT_ERROR, props<{ error: string }>());

export const GetAffiliateProducts = createAction(BuyerActions.GET_AFFILIATE_PRODUCT, (params: any = {}) => params);
export const GetAffiliateProductsSuccess = createAction(BuyerActions.GET_AFFILIATE_PRODUCT_SUCCESS, props<{ affiliateProducts: any }>());
export const GetAffiliateProductsError = createAction(BuyerActions.GET_AFFILIATE_PRODUCT_ERROR, props<{ error: string }>());

export const EditAffiliate = createAction(BuyerActions.EDIT_AFFILIATE, (items: any = {}) => items);
export const EditAffiliateSuccess = createAction(BuyerActions.EDIT_AFFILIATE_SUCCESS, props<{ success: any }>());
export const EditAffiliateError = createAction(BuyerActions.EDIT_AFFILIATE_ERROR, props<{ error: string }>());

export const GetAffiliateWalletBalance = createAction(BuyerActions.GET_WALLET_BALANCE, (params: any = {}) => params);
export const GetAffiliateWalletBalanceSuccess = createAction(BuyerActions.GET_WALLET_BALANCE_SUCCESS, props<{ wallet: WalletDetails }>());
export const GetAffiliateWalletBalanceError = createAction(BuyerActions.GET_WALLET_BALANCE_ERROR, props<{ error: string }>());

export const GetAffiliateWalletTransaction = createAction(BuyerActions.GET_WALLET_TRANSACTION_BALANCE, (params: any = {}) => params);
export const GetAffiliateWalletTransactionSuccess = createAction(BuyerActions.GET_WALLET_TRANSACTION_BALANCE_SUCCESS, props<{ walletTransaction: Store }>());
export const GetAffiliateWalletTransactionError = createAction(BuyerActions.GET_WALLET_TRANSACTION_BALANCE_ERROR, props<{ error: string }>());

export const AddScheduleData = createAction(BuyerActions.ADD_SCHEDULE_DATA, props<{ body: any }>());
export const AddScheduleDataSuccess = createAction(BuyerActions.ADD_SCHEDULE_DATA_SUCCESS, props<{ wallet: WalletDetails }>());
export const AddScheduleDataError = createAction(BuyerActions.ADD_SCHEDULE_DATA_ERROR, props<{ error: string }>());

export const AddPayoutData = createAction(BuyerActions.ADD_PAYOUT_DATA, props<{ body: any }>());
export const AddPayoutDataSuccess = createAction(BuyerActions.ADD_PAYOUT_DATA_SUCCESS, props<{ payout: PayoutDetails[] }>());
export const AddPayoutDataError = createAction(BuyerActions.ADD_PAYOUT_DATA_ERROR, props<{ error: string }>());

export const GetPayoutDetail = createAction(BuyerActions.GET_PAYOUT_DETAIL);
export const GetPayoutDetailSuccess = createAction(BuyerActions.GET_PAYOUT_DETAIL_SUCCESS, props<{ payout: PayoutDetails[] }>());
export const GetPayoutDetailError = createAction(BuyerActions.GET_PAYOUT_DETAIL_ERROR, props<{ error: string }>());

export const SearchAffiliateProducts = createAction(BuyerActions.SEARCH_AFFILIATE_PRODUCTS, (params: any = {}) => params);
export const SearchAffiliateProductsSuccess = createAction(BuyerActions.SEARCH_AFFILIATE_PRODUCTS_SUCCESS, props<{ affiliateProducts: any }>());
export const SearchAffiliateProductsError = createAction(BuyerActions.SEARCH_AFFILIATE_PRODUCTS_ERROR, props<{ error: string }>());

export const CreateAdsTransaction = createAction(BuyerActions.CREATE_ADS_TRANSACTION, props<{ body: any }>());
export const CreateAdsTransactionSuccess = createAction(BuyerActions.CREATE_ADS_TRANSACTION_SUCCESS);
export const CreateAdsTransactionError = createAction(BuyerActions.CREATE_ADS_TRANSACTION_ERROR, props<{ error: string }>());

export const GetSponsoredItems = createAction(BuyerActions.GET_SPONSORED_ITEMS, props<{ body: any }>());
export const GetSponsoredItemsSuccess = createAction(BuyerActions.GET_SPONSORED_ITEMS_SUCCESS, props<{ sponsored: any }>());
export const GetSponsoredItemsError = createAction(BuyerActions.GET_SPONSORED_ITEMS_ERROR, props<{ error: string }>());

export const AddToCartItems = createAction(BuyerActions.ADD_ITEM_TO_CART, props<{ body: any }>());
export const AddToCartItemsSuccess = createAction(BuyerActions.ADD_ITEM_TO_CART_SUCCESS, props<{ cart: any }>());
export const AddToCartItemsError = createAction(BuyerActions.ADD_ITEM_TO_CART_ERROR, props<{ error: string }>());

export const GetCart = createAction(BuyerActions.GET_CART);
export const GetCartSuccess = createAction(BuyerActions.GET_CART_SUCCESS, props<{ cart: any }>());
export const GetCartError = createAction(BuyerActions.GET_CART_ERROR, props<{ error: string }>());

export const ValidateOrder = createAction(BuyerActions.VALIDATE_ORDER, (items: any = {}) => items);
export const ValidateOrderSuccess = createAction(BuyerActions.VALIDATE_ORDER_SUCCESS, props<{ orderPlaced: any }>());
export const ValidateOrderError = createAction(BuyerActions.VALIDATE_ORDER_ERROR, props<{ error: string }>());

export const GetSearchProducts = createAction(BuyerActions.GET_SEARCH_PRODUCT, (items: any = {}) => items);
export const GetSearchProductsSuccess = createAction(BuyerActions.GET_SEARCH_PRODUCT_SUCCESS, props<{ searchData: any }>());
export const GetSearchProductsError = createAction(BuyerActions.GET_SEARCH_PRODUCT_ERROR, props<{ error: string }>());

export const UpdateSellerFavorite = createAction(BuyerActions.UPDATE_SELLER_TO_FAVORITE, (items: any = {}) => items);
export const UpdateSellerFavoriteSuccess = createAction(BuyerActions.UPDATE_SELLER_TO_FAVORITE_SUCCESS, props<{ success: any }>());
export const UpdateSellerFavoriteError = createAction(BuyerActions.UPDATE_SELLER_TO_FAVORITE_ERROR, props<{ error: string }>());

export const StartNegotiation = createAction(BuyerActions.START_NEGOTIATION, (items: any = {}) => items);
export const StartNegotiationSuccess = createAction(BuyerActions.START_NEGOTIATION_SUCCESS, props<{ negotiation: any }>());
export const StartNegotiationError = createAction(BuyerActions.START_NEGOTIATION_ERROR, props<{ error: string }>());

export const GetRfqDynamicKey = createAction(BuyerActions.GET_RFQ_FIELDS, (items: any = {}) => items);
export const GetRfqDynamicKeySuccess = createAction(BuyerActions.GET_RFQ_FIELDS_SUCCESS, props<{ rfqDynamicField: any }>());
export const GetRfqDynamicKeyError = createAction(BuyerActions.GET_RFQ_FIELDS_ERROR, props<{ error: string }>());

export const AddTempOrder = createAction(BuyerActions.ADD_TEMP_ORDER, props<{ body: any }>());
export const AddTempOrderSuccess = createAction(BuyerActions.ADD_TEMP_ORDER_SUCCESS, props<{ tempOrder: any }>());
export const AddTempOrderError = createAction(BuyerActions.ADD_TEMP_ORDER_ERROR, props<{ error: string }>());

export const GetTempOrder = createAction(BuyerActions.GET_TEMP_ORDER, (items: any = {}) => items);
export const GetTempOrderSuccess = createAction(BuyerActions.GET_TEMP_ORDER_SUCCESS, props<{ tempOrder: any }>());
export const GetTempOrderError = createAction(BuyerActions.GET_TEMP_ORDER_ERROR, props<{ error: string }>());

export const GetIntent = createAction(BuyerActions.GET_PAYMENT_INTENT, (items: any = {}) => items);
export const GetIntentSuccess = createAction(BuyerActions.GET_PAYMENT_INTENT_SUCCESS, props<{ paymentIntent: any }>());
export const GetIntentError = createAction(BuyerActions.GET_PAYMENT_INTENT_ERROR, props<{ error: string }>());

export const CheckChatUser = createAction(BuyerActions.CHECK_CHAT_USER, props<{ body: any, showLoader: boolean }>());
export const CheckChatUserSuccess = createAction(BuyerActions.CHECK_CHAT_USER_SUCCESS, props<{ chatUser: User | Store }>());
export const CheckChatUserError = createAction(BuyerActions.CHECK_CHAT_USER_ERROR, props<{ error: string }>());

export const GetActivePromoCodes = createAction(BuyerActions.GET_ACTIVE_PROMO_CODES, (params: any = {}) => params);
export const GetActivePromoCodesSuccess = createAction(BuyerActions.GET_ACTIVE_PROMO_CODES_SUCCESS, props<{ promoCodes: any }>());
export const GetActivePromoCodesError = createAction(BuyerActions.GET_ACTIVE_PROMO_CODES_ERROR, props<{ error: string }>());

export const ContactToSeller = createAction(BuyerActions.CONTACT_TO_SELLER, (params: any = {}) => params);
export const ContactToSellerSuccess = createAction(BuyerActions.CONTACT_TO_SELLER_SUCCESS);
export const ContactToSellerError = createAction(BuyerActions.CONTACT_TO_SELLER_ERROR, props<{ error: string }>());

export const ResetPaymentState = createAction(BuyerActions.RESET_PAYMENT_STATE, props<{ params: any }>());
