import {createFeatureSelector, createSelector} from "@ngrx/store";
import {BuyerState} from "./buyer.reducer";

const getBuyerState = createFeatureSelector<BuyerState>('buyer');

export const getCategories = createSelector(getBuyerState, (state: BuyerState) => state.categories);
export const getProduct = createSelector(getBuyerState, (state: BuyerState) => state.product);
export const GetCartItem = createSelector(getBuyerState, (state: BuyerState) => state.cart);
export const GetHomePageProducts = createSelector(getBuyerState, (state: BuyerState) => state.homePageProduct);
export const getCategoryResponse = createSelector(getBuyerState, (state: BuyerState) => state.categoryResponse);
export const getBannerImages = createSelector(getBuyerState, (state: BuyerState) => state.bannerImage);
export const GetAllBuyerAddress = createSelector(getBuyerState, (state: BuyerState) => state.address);
export const GetError = createSelector(getBuyerState, (state: BuyerState) => state.error);
export const getCategoryFilter = createSelector(getBuyerState, (state: BuyerState) => state.categoryFilter);
export const GetVendorProductsDetail = createSelector(getBuyerState, (state: BuyerState) => state.vendorDetail);
export const GetRecentViewProduct = createSelector(getBuyerState, (state: BuyerState) => state.recentlyView);
export const GetWishListDetials = createSelector(getBuyerState, (state: BuyerState) => state.wishList);
export const GetBuyerSuccess = createSelector(getBuyerState, (state: BuyerState) => state.success);
export const getNotifications = createSelector(getBuyerState, (state: BuyerState) => state.notifications);
export const getProductReviews = createSelector(getBuyerState, (state: BuyerState) => state.reviews);
export const getProducts = createSelector(getBuyerState, (state: BuyerState) => state.paginatedProducts);
export const getGuestUserCheck = createSelector(getBuyerState, (state: BuyerState) => state.guestUser);
export const getVendorsList = createSelector(getBuyerState, (state: BuyerState) => state.vendors);
export const checkGuestUserInfo = createSelector(getBuyerState, (state: BuyerState) => state.guestInfo);
export const cancelOrderInfo = createSelector(getBuyerState, (state: BuyerState)=> state.cancelOrder);
export const getPolicyData = createSelector(getBuyerState, (state: BuyerState) => state.policy);
export const GetOrderPlaceDetails = createSelector(getBuyerState, (state: BuyerState) => state.orderPlaced);
export const GetOrderPaymentDetails = createSelector(getBuyerState, (state: BuyerState) => state.paymentSuccess);
export const getProfileInfo = createSelector(getBuyerState, (state: BuyerState) => state.userData);
export const getCurrencyData = createSelector(getBuyerState, (state: BuyerState) => state.currencyData);
export const getWishlistProducts = createSelector(getBuyerState, (state: BuyerState) => state.updatedProducts);
export const getGiftCards = createSelector(getBuyerState, (state: BuyerState) => state.giftCards);
export const getGiftCardValidity = createSelector(getBuyerState, (state: BuyerState) => state.giftCardValidity);
export const getDiscountDetails = createSelector(getBuyerState, (state: BuyerState) => state.discountDetails);
export const getIsAssociateStoreIdAvailable = createSelector(getBuyerState, (state: BuyerState) => state.isAssociateStoreIdAvailable);
export const getAffiliatesData = createSelector(getBuyerState, (state: BuyerState) => state.affiliate);
export const getAffiliateDashboardData = createSelector(getBuyerState, (state: BuyerState) => state.affiliateDashboard);
export const getAffiliateProductsData = createSelector(getBuyerState, (state: BuyerState) => state.affiliateProducts);
export const getwallet = createSelector(getBuyerState, (state: BuyerState) => state.wallet);
export const getWalletTransaction = createSelector(getBuyerState, (state: BuyerState) => state.walletTransaction);
export const getPayoutData = createSelector(getBuyerState, (state: BuyerState) => state.payout);
export const getSearchAffiliateProducts = createSelector(getBuyerState, (state: BuyerState) => state.searchAffiliateProducts);
export const getSponsoredProducts = createSelector(getBuyerState, (state: BuyerState) => state.sponsored);
export const getSearchProductList = createSelector(getBuyerState, (state: BuyerState) => state?.searchData);
export const getSellerFavoriteDetail = createSelector(getBuyerState, (state: BuyerState) => state?.sellerFavourite);
export const getRfqDynamicKey = createSelector(getBuyerState, (state: BuyerState) => state.rfqDynamicField);
export const getTempOrder = createSelector(getBuyerState, (state: BuyerState) => state?.tempOrder);
export const getPaymentIntent = createSelector(getBuyerState, (state: BuyerState) => state.paymentIntent);
export const getChatUserCheck = createSelector(getBuyerState, (state: BuyerState) => state.chatUser);
export const getActivePromoCodes = createSelector(getBuyerState, (state: BuyerState) => state.promoCodes);
